
.breadcrumbs
  margin-bottom: 28px
  display: flex
  flex-wrap: wrap
  align-items: center
  +res-sm-max
    margin-bottom: 16px
  > *
    margin-bottom: 4px
    &:not(:last-child)
      margin-right: 4px
  &__item
    +typoSmallText
    color: $black
    a
      text-decoration: none
      cursor: pointer
      &:hover
        text-decoration: underline
    &--active
      color: $grey-80
  &__separator
    svg
      +style-icon(24px, $black)
