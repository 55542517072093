
.filtri-overlay
  position: fixed
  top: 0
  left: 0
  bottom: 0
  right: 0
  background: rgba($black, .6)
  z-index: 9999

.filtri__listing
  position: fixed
  top: 0px
  right: 0px
  bottom: 0px
  height: 100%
  padding: 64px 24px 0
  max-width: 445px
  width: 100%
  background-color: $white
  +translateX(100%)
  +transition(transform .5s)
  z-index: 9999 // al pari di header e toolbar - deve andar sopra
  +res-xs-max
    padding: 56px 20px 0
  &--open
    +translateX(0)
  &__close
    position: absolute
    top: 24px
    right: 24px
    cursor: pointer
    svg
      display: block
      width: 24px
      height: 24px
      +color-icon($black)
  &__title
    +typoH5
    margin-bottom: 32px
  &__content
    max-height: calc(100vh - 184px) // 120 di altezza del footer + 64 di padding top
    overflow-y: auto
    +scrollbar-style
    +res-sm-max
      max-height: calc(100vh - 176px) // 120 di altezza del footer + 56 di padding top
    &__options
      > *
        border-bottom: 1px solid $grey-20
        &:first-child
          border-top: 1px solid $grey-20
  &__value
    padding: 8px 0
  &__accordion-content
    &--color
      display: flex
      flex-wrap: wrap
      > .filtri__listing__value
        width: calc(25%)
        padding-right: 8px
  &__footer
    position: absolute
    left: 0
    width: 100%
    bottom: 0px
    padding: 16px 24px 24px
    box-shadow: 4px 2px 26px 0px rgba(24, 50, 115, 0.08)
    background: #fff
    +res-sm-max
      padding-left: 20px
      padding-right: 20px
    p
      +typoSmallText
      text-align: center
    &__actions
      margin-top: 12px
      display: flex
      justify-content: center
      align-items: center
      gap: 25px
      +res-sm-max
        gap: 30px

.filtro-colore
  cursor: pointer
  &__selection
    width: 60px
    height: 60px
    +border-radius(50%)
    border: 2px solid transparent
    margin: 0 auto
    display: block
    +res-sm-max
      width: 40px
      height: 40px
  &__image-box
    width: 56px
    height: 56px
    +border-radius(50%)
    overflow: hidden
    display: block
    border: 2px solid transparent
    +res-sm-max
      width: 36px
      height: 36px
  &__label
    display: block
    margin-top: 8px
    +typoSmallText
    text-align: center
    color: $black
    +res-sm-max
      +typoTinyText
  &--active
    .filtro-colore
      &__selection
        border-color: $black
  &--disabled
    cursor: default
    pointer-events: none
    opacity: .3
