.swiper-container 
  margin-left: auto
  margin-right: auto
  position: relative
  overflow: hidden
  list-style: none
  padding: 0
  // Fix of Webkit flickering 
  z-index: 1


.swiper-container-vertical > .swiper-wrapper 
  flex-direction: column

.swiper-wrapper 
  position: relative
  width: 100%
  height: 100%
  z-index: 1
  display: flex
  transition-property: transform
  box-sizing: content-box

.swiper-container-android .swiper-slide,
.swiper-wrapper 
  transform: translate3d(0px, 0, 0)

.swiper-container-multirow > .swiper-wrapper 
  flex-wrap: wrap

.swiper-container-multirow-column > .swiper-wrapper 
  flex-wrap: wrap
  flex-direction: column

.swiper-container-free-mode > .swiper-wrapper 
  transition-timing-function: ease-out
  margin: 0 auto

.swiper-slide 
  flex-shrink: 0
  width: 100%
  height: 100%
  position: relative
  transition-property: transform

.swiper-slide-invisible-blank 
  visibility: hidden

// Auto Height
.swiper-container-autoheight 
  &,
  .swiper-slide 
    height: auto
  

  .swiper-wrapper 
    align-items: flex-start
    transition-property: transform, height
  


// 3D Effects 
.swiper-container-3d 
  perspective: 1200px
  .swiper-wrapper,
  .swiper-slide,
  .swiper-slide-shadow-left,
  .swiper-slide-shadow-right,
  .swiper-slide-shadow-top,
  .swiper-slide-shadow-bottom,
  .swiper-cube-shadow 
    transform-style: preserve-3d
  
  .swiper-slide-shadow-left,
  .swiper-slide-shadow-right,
  .swiper-slide-shadow-top,
  .swiper-slide-shadow-bottom 
    position: absolute
    left: 0
    top: 0
    width: 100%
    height: 100%
    pointer-events: none
    z-index: 10
  
  .swiper-slide-shadow-left 
    background-image: linear-gradient(to left, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0))
  
  .swiper-slide-shadow-right 
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0))
  
  .swiper-slide-shadow-top 
    background-image: linear-gradient(to top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0))
  
  .swiper-slide-shadow-bottom 
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0))
  


// CSS Mode 
.swiper-container-css-mode 
  > .swiper-wrapper 
    overflow: auto
    scrollbar-width: none // For Firefox 
    -ms-overflow-style: none // For Internet Explorer and Edge 
    &::-webkit-scrollbar 
      display: none
    
  
  > .swiper-wrapper > .swiper-slide 
    scroll-snap-align: start start
  

.swiper-container-horizontal.swiper-container-css-mode 
  > .swiper-wrapper 
    scroll-snap-type: x mandatory
  

.swiper-container-vertical.swiper-container-css-mode 
  > .swiper-wrapper 
    scroll-snap-type: y mandatory
  

// Navigation

.swiper-button-prev,
.swiper-button-next 
  position: absolute
  top: 50%
  width: 40px
  height: 40px
  margin-top: -20px
  padding: 10px
  z-index: 10
  cursor: pointer
  +border-radius(50%)
  background: $accent
  outline: none
  &.swiper-button-disabled 
    opacity: 0
    cursor: auto
    pointer-events: none
  
  &:after 
    width: 20px
    height: 20px
  

.swiper-button-prev,
.swiper-container-rtl .swiper-button-next 
  &:after 
    content: $swiper-left-arrow-white
  
  left: 10px
  right: auto

.swiper-button-next,
.swiper-container-rtl .swiper-button-prev 
  &:after 
    content: $swiper-right-arrow-white
  
  right: 10px
  left: auto

.swiper-button-lock 
  display: none


// Pagination

.swiper-pagination 
  position: absolute
  text-align: center
  transition: 300ms opacity
  transform: translate3d(0, 0, 0)
  z-index: 10
  &.swiper-pagination-hidden 
    opacity: 0
  

// Common Styles 
.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-container-horizontal > .swiper-pagination-bullets 
  bottom: 0
  left: 0
  width: 100%

// Bullets 
.swiper-pagination-bullets-dynamic 
  overflow: hidden
  font-size: 0
  .swiper-pagination-bullet 
    transform: scale(0.33)
    position: relative
  
  .swiper-pagination-bullet-active 
    transform: scale(1)
  
  .swiper-pagination-bullet-active-main 
    transform: scale(1)
  
  .swiper-pagination-bullet-active-prev 
    transform: scale(0.66)
  
  .swiper-pagination-bullet-active-prev-prev 
    transform: scale(0.33)
  
  .swiper-pagination-bullet-active-next 
    transform: scale(0.66)
  
  .swiper-pagination-bullet-active-next-next 
    transform: scale(0.33)
  

.swiper-pagination-bullet 
  width: 8px
  height: 8px
  display: inline-block
  border-radius: 100%
  background: $grey-20
  opacity: 1
  @at-root button#{&} 
    border: none
    margin: 0
    padding: 0
    box-shadow: none
    appearance: none
  
  .swiper-pagination-clickable & 
    cursor: pointer
  

.swiper-pagination-bullet-active 
  opacity: 1
  background: $black


.swiper-container-vertical 
  > .swiper-pagination-bullets 
    right: 10px
    top: 50%
    transform: translate3d(0px, -50%, 0)
    .swiper-pagination-bullet 
      margin: 6px 0
      display: block
    
    &.swiper-pagination-bullets-dynamic 
      top: 50%
      transform: translateY(-50%)
      width: 8px
      .swiper-pagination-bullet 
        display: inline-block
        transition: 200ms transform, 200ms top
      
    
  

.swiper-container-horizontal 
  > .swiper-pagination-bullets 
    .swiper-pagination-bullet 
      margin: 0 4px
    
    &.swiper-pagination-bullets-dynamic 
      left: 50%
      transform: translateX(-50%)
      white-space: nowrap
      .swiper-pagination-bullet 
        transition: 200ms transform, 200ms left
      
    
  
  &.swiper-container-rtl > .swiper-pagination-bullets-dynamic .swiper-pagination-bullet 
    transition: 200ms transform, 200ms right
  

// Progress 
.swiper-pagination-progressbar 
  background: rgba(0, 0, 0, 0.25)
  position: absolute
  .swiper-pagination-progressbar-fill 
    background: $grey-100
    position: absolute
    left: 0
    top: 0
    width: 100%
    height: 100%
    transform: scale(0)
    transform-origin: left top
  
  .swiper-container-rtl & .swiper-pagination-progressbar-fill 
    transform-origin: right top
  
  .swiper-container-horizontal > &,
  .swiper-container-vertical > &.swiper-pagination-progressbar-opposite 
    width: 100%
    height: 4px
    left: 0
    top: 0
  
  .swiper-container-vertical > &,
  .swiper-container-horizontal > &.swiper-pagination-progressbar-opposite 
    width: 4px
    height: 100%
    left: 0
    top: 0  

.swiper-pagination-lock 
  display: none


// Varianti Custom
  
.swiper
  &--with-pagination:not(.swiper--main) // La paginazione va sotto, non dentro allo slider stesso - metto padding e sistemo la navigazione
    > .swiper-container 
      padding-bottom: 40px
      > .swiper-button-prev,
      > .swiper-button-next 
        margin-top: -40px // per metterlo centrato in altezza compenso il padding
  
  &--lateral-padding
    > .swiper-container
      padding-left: 48px
      padding-right: 48px

  &--main // Il carosello principale in home ha la paginazione dentro lo slider più grande e i colori diversi
    .swiper-pagination-fraction,
    .swiper-pagination-custom,
    .swiper-container-horizontal > .swiper-pagination-bullets 
      bottom: 24px
    .swiper-button-prev,
    .swiper-button-next 
      background: $white
      +res-sm-max
        width: 32px
        height: 32px
        padding: 6px
    .swiper-button-prev,
    .swiper-container-rtl .swiper-button-next 
      &:after 
        content: $swiper-left-arrow-black
    .swiper-button-next,
    .swiper-container-rtl .swiper-button-prev 
      &:after 
        content: $swiper-right-arrow-black
    .swiper-pagination-bullet 
      width: 12px
      height: 12px

  &--colors // navigazione piccola, con icone e colori diversi
    .swiper-button-prev,
    .swiper-button-next 
      width: 32px
      height: 32px
      margin-top: -16px
      padding: 6px
      +border-radius(0)
      background: $white
    .swiper-button-prev, .swiper-container-rtl .swiper-button-next 
      &:after 
        content: $swiper-left-chevron-black
    .swiper-button-next, .swiper-container-rtl .swiper-button-prev 
      &:after 
        content: $swiper-right-chevron-black
   
// Fine Varianti Custom