@import "../../../../../styles/sass/commons"

// TODO: non serve questo stile
.checkout__bozza-digitale
    
    &--servicegift
        +res-sm-min
            > *
                &:first-child
                    width: 50%

