
.wrapper__table__and__preventivo
  display: flex
  justify-content: space-between
  margin-top: 50px
  margin-bottom: 50px
  &__table
    flex: 1
  &__sidebar
    width: 255px
    margin-left: 32px
    +res-sm-max
      display: none

.nonhaitrovato__wrapper
  display: grid
  width: 100%
  background-color: $grey-10
  align-content: center
  justify-items: center
  grid-gap: 8px
  min-height: 256px
  font-size: 16px
  @media screen and ( max-width: 450px )
    text-align: center
    font-size: 14px
  strong
    font-size: 20px
    @media screen and ( max-width: 450px )
      font-size: 16px
  &__button
    display: flex
    justify-content: center
    align-items: center
    padding: 12px 32px
    border: solid 2px black
    color: black
    font-size: 16px
    font-weight: bold
    @media screen and ( max-width: 450px )
      font-size: 14px
      width: 100%
  &__buttons
    margin-top: 16px
    display: flex
    gap: 12px
    @media screen and ( max-width: 450px )
      display: grid
