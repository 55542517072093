@import '../../../styles/sass/commons'
  
.vantaggi-card   
  padding: 32px 28px
  background-color: $grey-10
  overflow-wrap: break-word
  +typoSmallText
  &__title
    +typoRegularText
    font-weight: bold
    margin-bottom: 16px

  &__list
    margin-bottom: 24px
    padding-bottom: 8px
    border-bottom: solid 1px $grey-50

    ul
      margin: 16px 0
      > li
        padding-left: 32px
        margin-bottom: 5px
        position: relative
        &::before
          content: ""
          position: absolute
          top: 8px
          left: 16px
          background: currentColor
          width: 4px
          height: 4px
          +border-radius(50%)