
.formtextarea
  +border-radius(4px)
  min-height: 80px
  resize: vertical

.formfield
  &:not(.formfield--lg)
    .formtextarea
      min-height: 80px
  &.formfield--lg
    .formtextarea
      min-height: 88px
