
.contatti_template
    font-family: 'Open sans',sans-serif
    font-size: 16px
    line-height: 1.5

    &__header
        margin-top: 25px

    &__team
        display: flex
        flex-direction: column
        margin: 15px 0
        &__container
            display: flex
            flex-wrap: wrap
            margin-top: 30px
            > *
                width: calc(33% - 16.66px)
                margin-right: 25px
                margin-bottom: 30px
                &:nth-child(3n)
                    margin-right: 0
            @media screen and ( max-width: 568px )
                flex-direction: column
                > *
                    width: 100%
                    margin-bottom: 30px

    &__persone
        font-size: 14px
        margin-top: 20px
        .item
            border-radius: 10px
            border: 1px solid #899eb0
            margin-right: 25px
            margin-bottom: 25px
            &__name
                padding: 8px
            &__email
                padding: 8px
    &__buttons
        margin-left: 10px
        &__container
            margin-top: 20px
            display: flex
            flex-direction: row
            button
                margin-right: 20px

.contatti_template__customer
    font-family: 'Open sans',sans-serif
    font-size: 16px
    line-height: 1.5
    background: $grey-20
    display: flex
    flex-direction: row
    justify-content: space-evenly
    padding: 20px
    margin-top: 20px
    @media screen and ( max-width: 568px )
        flex-direction: column

    &__leftSide
        width: 500px
        @media screen and ( max-width: 568px )
            width: 100%
        ul
            li
                list-style: inside
    &__rightSide
        display: flex
        flex-direction: column
        width: 500px
        height: 300px
        position: relative
        background: #F5F9FC
        padding: 15px 55px
        @media screen and ( max-width: 568px )
            margin-top: 20px
            width: 100%
            height: 375px
