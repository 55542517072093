
.color
    position: relative
    width: 22px
    height: 22px
    +border-radius(50%)
    overflow: hidden
    padding: 1px
    border: 1px solid transparent
    &--selectable
        cursor: pointer

    &--selectable:hover, &--active
        border-color: $black

    &--lg
        width: 32px
        height: 32px

    &--mobile-lg // Md nella desktop Lg nella mobile (MainProdotto)
        +res-sm-max
            width: 32px
            height: 32px

    &__image-box
        position: relative
        width: 100%
        height: 100%
        overflow: hidden
        +border-radius(50%)
