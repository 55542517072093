
.formselect
  &__control
    border: 2px solid $grey-50!important
    background: $white
    color: $black
    width: 100%
    outline: none!important
    box-shadow: none!important
    min-height: 0!important
    +border-radius(0!important)
    &--is-focused
      border-color: $black!important
  &--is-disabled
    .formselect
      &__control
        background-color: $white!important
        border-color: $grey-20!important
      &__single-value
        color: $grey-20!important
      &__dropdown-indicator
        color: $grey-20!important
        svg
          fill: $grey-20!important

  &__value-container
    padding: 0!important
  &__single-value
  &__indicators
  &__indicator-separator
    display: none!important
  &__indicator
  &__dropdown-indicator
    color: $black
    display: flex
  &__menu
    margin: 0!important
    +border-radius(0!important)
    box-shadow: 0 16px 32px 0 rgba(#183273, 0.08)
  &__menu-list
    padding: 0!important
  &__option
    padding: 14px 16px
    color: $black!important
    cursor: pointer!important
    &:hover
      background: $fill-accent-0!important
    &--is-selected
      background: $fill-accent-10!important
      &:hover
        background: $fill-accent-10!important
    &--is-focused
      outline: none!important
      background: $white!important

.formfield
  &--error
    .formselect
      &__control
        border-color: $error!important

  &:not(.formfield--lg)
    .formselect
      +typoSmallText
      &__value-container
        padding: 4px 10px!important
      &__dropdown-indicator
        padding: 8px 10px
        svg
          width: 20px
          height: 20px
      ~ .formfield
        &__error-icon, &__success-icon
          top: 10px
          right: 38px
    &.formfield--left-icon
      .formselect__value-container
        padding-left: 38px!important
    &.formfield--right-icon
      .formselect
        ~ .formfield
          &__icon
            display: none!important

    &.formfield--error, &.formfield--success
      .formselect
        &__value-container
          margin-right: 28px!important // 20 (width icon) + 8 (gap)

  &.formfield--lg
    .formselect
      +typoRegularText
      &__value-container
        padding: 10px 14px!important
      &__dropdown-indicator
        padding: 10px 14px
        svg
          width: 24px
          height: 24px
      ~ .formfield
        &__error-icon, &__success-icon
          top: 12px
          right: 50px
    &.formfield--left-icon
      .formselect__value-container
        padding-left: 50px!important
    &.formfield--right-icon
      .formselect
        &~ .formfield
          &__icon
            display: none!important
    &.formfield--error, &.formfield--success
      .formselect
        &__value-container
          margin-right: 36px!important // 24 (width icon) + 12 (gap)
