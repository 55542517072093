
.bolla
  &__title
    padding-bottom: 1.5rem
  &__dati
    font-size: 1.2rem
    line-height: 1.5
    &__label
      font-size: 1.2rem
      line-height: 1.5
      opacity: .6
  &__container
    padding-top: 1.5rem
  &__button-tracking
    margin-left: auto
    +res-sm-max
      margin-top: 1.5rem
      margin-bottom: 1.5rem

.lineSeparator
  padding-bottom: 1.0rem
  padding-top: 1.0rem
  border-bottom: solid 1px $grey-20
