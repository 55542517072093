
.nonhaitrovato
  background-color: $grey-10
  margin: 64px 0 56px
  padding: 32px 0 40px
  text-align: center
  +res-sm-max
    padding-bottom: 32px
  &__title
    +typoH5
    margin-bottom: 8px
    +res-sm-max
      +typoRegularText
      font-weight: bold
  &__description
    +typoRegularText
    margin-bottom: 32px
    +res-sm-max
      +typoSmallText
      margin-bottom: 24px
  &__buttons
    +res-md-min
      display: flex
      justify-content: center
      align-items: center
      > *
        &:not(:last-child)
          margin-right: 24px
    +res-sm-max
      > *
        width: 260px
        margin-left: auto
        margin-right: auto
        &:not(:last-child)
          margin-bottom: 16px
