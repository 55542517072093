
.dettagli-prodotto
  &__header
    margin-bottom: 24px
  &__title
    +typoH5
    margin-bottom: 8px
  &__subtitle
    +typoRegularText
    color: $grey-80
  &__recap
    margin-bottom: 20px
    +res-sm-min
      display: flex
      > *
        &:not(:last-child)
          margin-right: 64px
    +res-xs-max
      > *
        &:not(:last-child)
          margin-bottom: 8px
    &__item
      display: flex
      +typoCaption
      > *
        &:not(:last-child)
          margin-right: 8px
    &__label
      color: $grey-80
    &__arrow
      cursor: pointer
      svg
        display: block
        width: 20px
        height: 20px
        +color-icon($black)
  &__data-list
  &__data
    +typoRegularText
    padding: 8px 0
    +res-xs-max
      &__label
        margin-bottom: 4px
    +res-sm-min
      display: flex
      > *
        flex: 1
        &:first-child
          flex: 0 0 25%
        &:not(:last-child)
          margin-right: 16px
    &__label
      color: $grey-80
    &__value a
      color: $accent
      text-decoration: underline

  &__accordions
    > *
      &:not(:last-child)
        border-bottom: none
  &__accordion-content
    padding: 8px 24px 16px

  // Dati custom
  &__capacita
    display: flex
    align-items: center
    &__tag
      width: 38px
      height: 38px
      padding: 9px 0 0
      margin-right: 16px
      background-color: #547ebf
      +border-radius(50%)
      +typoSmallText
      font-weight: 700
      text-align: center
      color: white

  &__imballo
    display: flex
    align-items: center
    &__icon
      margin-right: 12px
      svg
        display: block
        width: 24px
        height: 24px
        +color-icon($black)

  &__dimensioni
    display: flex
    align-items: center
    &__tag
      width: 38px
      height: 38px
      padding: 9px 0 0
      margin-right: 16px
      background-color: #f8b133
      +border-radius(50%)
      font-weight: $font-primary
      font-size: 9px
      line-height: 10px
      font-weight: 700
      text-align: center
      color: white

  &__misure
    display: flex
    align-items: center
    > *
      &:not(:last-child)
        margin-right: 16px
    &__item
      display: flex
      align-items: center
      > *
        &:not(:last-child)
          margin-right: 8px
    &__icon
      svg
        display: block
        width: 24px
        height: 24px
        +color-icon($black)
      &--altezza
        svg
          +rotate(90deg)

  &__colori
    &__row
      display: flex
      align-items: center
      margin-bottom: 24px
      > *
        &:not(:last-child)
          margin-right: 12px
    &__icon
      display: flex
      padding: 3px 0
      svg
        display: block
        width: 16px
        height: 17px
        +color-icon($accent)
        &:not(:first-child)
          margin-left: -8px
    &__show-prezzi
      color: $accent
      text-decoration: underline
      cursor: pointer

  &__documentazione
    &__buttons
      +res-md-min
        display: flex
        align-items: center
        flex-wrap: wrap
        +custom-row(16px)
        > *
          width: 50%
      > *
        margin-bottom: 16px
        .button__label
          font-weight: 400

  &__disclaimer
    +typoRegularText
    margin-top: 5px

  &__ean
    display: flex
    flex-wrap: wrap
    width: 70%
    +res-sm-max
      width: 100% 
    +typoSmallText
    &__column
      flex: 1
      margin-right: 10px
    &__header
      font-weight: bold
      color: $grey-80
    &__header, &__element
      padding: 8px
    &__element
      flex: 1
