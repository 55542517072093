
.datatable-row-offerta
  &__icon
    cursor: pointer
    svg
      width: 24px
      height: 24px
      fill: $black
    &:hover
      svg
        fill: $accent
