.anteprima-offerta
  display: flex
  flex-direction: column
  &__edit-button
    display: flex
    flex-direction: row
    justify-content: space-between
    align-items: center
    h1
      font-size: 24px
      font-weight: bold
   
  &__totale
    margin: 24px 0px
    width: 100%
    display: flex
    flex-direction: column
    align-items: flex-end
    &__resoconto
      &__item
        display: flex
        justify-content: space-between
        margin-bottom: 5px
        &__label
          min-width: 200px
          color: #8891a1
      margin-bottom: 12px
      font-size: 14px
      p 
        margin-bottom: 5px
    &__buttons
      width: 100% 
      display: flex
      justify-content: flex-end
 
      button
        margin-left: 20px
         
  &__heading
    font-size: 14px
    font-weight: bold
    margin: 16px 0px
  &__miei-dati
    margin-bottom: 20px
    p
      font-size: 14px
    &__label
      min-width: 100px
      color: #8891a1
    &__wrapper
      margin-bottom: 5px
      display: flex
      flex-direction: row
    
.pdf
  .anteprima-offerta
    display: block
    +res-sm-max
      .datatable
        &__head
          display: flex !important
      .datatable-row
        &__cells
          flex-wrap: nowrap
      .datatable-cell
        flex-grow: 0!important
        &__heading
            display: none
        &--mobile-cols-7
          flex-basis: 58.33%
          width: 100px!important
        &--mobile-cols-12
          width: 170px!important
      .datatable-row-personalizzazione
        .datatable-cell
          &__heading
            display: none
          &:nth-child(1)
            flex-grow: 1
          &:nth-child(2)
            display: none
          &:nth-child(4)
            width: 170px !important
      &__totale
        margin-bottom: 0px
      
        