
.chip
  display: flex
  height: 40px
  width: fit-content
  background-color: $grey-10
  border-radius: 24px
  align-items: center
  cursor: pointer
  &:hover
    background-color: $grey-20
  &__placeholder
    padding: 0 4px
  &__icon
    svg
      display: block
      +color-icon(currentColor)

  // size
  &--md
    padding: 8px 12px
    > *:not(:last-child)
      margin-right: 8px
    .chip
      &__placeholder
        +typoRegularText
      &__icon
        svg
          width: 24px
          height: 24px

  &--sm
    padding: 10px 8px 8px
    > *:not(:last-child)
      margin-right: 4px
    .chip
      &__placeholder
        +typoSmallText
      &__icon
        svg
          width: 20px
          height: 20px

.chip-dropdown
  position: relative
  display: inline-block
  &__options
    position: absolute
    left: 0
    top: 100%
    width: 100%
    min-width: 200px
    max-height: 300px
    overflow-y: auto
    +scrollbar-style
    padding: 8px 0
    margin: 4px 0
    +border-radius(4px)
    box-shadow: 0 16px 32px 0 rgba(24, 50, 115, 0.08)
    background: $grey-10
  &__option
    cursor: pointer
    &:hover, &--selected
      background-color: $grey-20

  // size
  &--md
    .chip-dropdown
      &__option
        +typoSmallText
        padding: 10px 16px
  &--sm
    .chip-dropdown
      &__option
        +typoSmallText
        padding: 8px

.chip
  &--close-filter
    background-color: $grey-20
