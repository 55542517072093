
.search-field
  position: relative
  &:not(.search-field--lg)
    max-width: 248px
  &__input
    width: 100%
    z-index: 0
    height: 40px
    border-radius: 24px
    border: 2px solid transparent
    padding: 6px 40px 6px 14px
    background-color: $grey-10
    -webkit-appearance: none
    &:not(:disabled)
      &:focus, &:hover
        outline: none
        border-color: $grey-50
    &:disabled
      color: $grey-50
      ~ .search-field__icon
        svg
          fill: $grey-50
    @media screen and ( max-width: 768px )
      &::placeholder
        text-overflow: ellipsis
        overflow: hidden
        opacity: 0.4
        text-white: nowrap

  &__icon
    cursor: pointer
    position: absolute
    z-index: 99
    top: calc(50% - 12px)
    right: 16px
    width: 24px
    height: 24px
    svg
      display: block
      width: 24px
      height: 24px
      +color-icon($black)
