
.search-bar
  position: relative
  z-index: 19999
  &:not(.search-bar--lg)
    max-width: 248px
  &__input
    width: 100%
    z-index: 0
    height: 36px
    border-radius: 24px
    border: 2px solid transparent
    padding: 6px 40px 6px 14px
    background-color: $grey-10
    -webkit-appearance: none
    &:not(:disabled)
      &:focus, &:hover
        outline: none
        border-color: $grey-50
    &:disabled
      color: $grey-50
      ~ .search-bar__icon
        svg
          fill: $grey-50
    @media screen and ( max-width: 768px )
      &::placeholder
        text-overflow: ellipsis
        overflow: hidden
        opacity: 0.4
        text-white: nowrap

  &__icon
    cursor: pointer
    position: absolute
    z-index: 99
    top: calc(50% - 12px)
    right: 16px
    width: 24px
    height: 24px
    svg
      display: block
      width: 24px
      height: 24px
      +color-icon($black)

.search-results
  position: absolute
  left: 0
  right: 0
  top: 100%
  max-height: 80vh
  overflow-y: auto
  +scrollbar-style
  background: $white
  padding: 44px 16px 88px
  box-shadow: 0 8px 16px 0 rgba(24, 50, 115, 0.08)
  border-radius: 8px
  +res-md-max
    position: fixed
    top: $header-full-height-tablet
    bottom: 0
    left: 0
    right: 0
    max-height: none
    padding: 24px
    border-radius: 0
  +res-sm-max
    top: $header-full-height-mobile // altezza header a questa res

  &__suggestion
    +typoRegularText
    margin-bottom: 16px
    a
      cursor: pointer
      text-decoration: underline
      &:focus, &:hover
        text-decoration: underline

  &__cols
    +res-md-min
      display: flex
      > *
        width: calc(50% - 16px)
        &:not(:last-child)
          margin-right: 32px
    +res-sm-max
      > *
        &:not(:last-child)
          padding-bottom: 32px
          margin-bottom: 32px
          border-bottom: 1px solid $grey-20

  &__block
    &:not(:last-child)
      margin-bottom: 40px

  &__title
    +typoCaption
    color: $grey-80
    text-transform: uppercase
    margin-bottom: 20px

  &__list
    +typoRegularText

  &__item
    display: block
    &:not(:last-child)
      margin-bottom: 8px
    &:hover
      text-decoration: underline
      color: $accent
      .search-results__light
        color: $accent

  &__light
    color: $grey-80

  &__view-all
    margin-top: 34px

  &__cronologia
    margin-bottom: 18px
    +res-md-min
      display: flex
      justify-content: space-between
      align-items: center
      > *
        &:not(:last-child)
          margin-right: 16px
    +res-sm-max
      > *
        margin-bottom: 12px

  &__reset-cronologia
    +typoRegularText
    text-decoration: underline
    color: $accent

.toolbar
  ~ .header
    &--full
      .search-results
        +res-md-max
          top: #{$header-full-height-tablet + $toolbar-height}
        +res-sm-max
          top: #{$header-full-height-mobile + $toolbar-height}
