.checkout__pagamento

  &--servicegift
    margin-top: 16px

    .checkout__pagamento__option
      padding: 22px 16px
      border-radius: 4px
      border: 1px solid $grey-80

      &:not(:last-child)
        margin-bottom: 16px

    .formradio__label
      font-weight: 700 !important

