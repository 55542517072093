
.listing-options
  &__row
    &--settings, &--filters
      .listing-options
        &__row-content
          +res-lg-min
            display: flex
            align-items: center
    &--filters
      +res-md-max
        display: none
    &--settings
      margin-bottom: 16px

// Riga 1 --filters
.listing-location
  display: none
  width: 25%
  margin-right: 24px
  padding: 4px 0 16px
  +typoRegularText
  color: $grey-80
  align-self: flex-start
  strong
    color: $black

.listing-filters
  &__title
    +typoCaption
    color: $grey-80
    margin-bottom: 12px

.listing-filters__filters, .listing-selection  // Riga 2 --selection
  display: flex
  flex-wrap: wrap
  > *
    white-space: nowrap
    margin-bottom: 16px
    &:not(:last-child)
      margin-right: 24px

// Riga 3 --settings
.listing-ordinamento
  min-width: 114px
  +res-md-max
    .dropdown
      display: inline-block
  +res-lg-min
    margin-left: auto
    margin-right: 24px

.listing-num
  +typoSmallText
  +res-lg-min
    order: -1
  +res-md-max
    margin-top: 16px

.listing-vista
  display: flex
  align-items: center
  > *
    &:not(:last-child)
      margin-right: 24px
  &__icon
    display: block
    width: 24px
    height: 24px
    +color-icon($grey-20)
    cursor: pointer
    &--active
      +color-icon($black)
      cursor: default
  +res-md-max
    display: none

// Comportamento sticky - solo desktop
.listing-options
  position: relative
  z-index: 2 // Altrimenti va sotto le card del listing
  &--sticky
    +res-lg-min
      padding-top: 24px
      .listing-options
        &__row
          &--settings
            background-color: $fill-neutrals-grey-0
            margin-bottom: 0
        &__row-content
          +wcontainer
      .listing-location
        display: block
      .listing-filters
        &__title
          display: none

.listing__options__container
  @media screen and ( max-width: 548px )
    display: none
