
.cell__ordine
  font-family: 'Open sans',sans-serif
  background-color: $fill-neutrals-grey-0
  padding: 16px 8px
  display: grid
  grid-gap: 12px
  font-size: 14px
  border-bottom: solid 2px $grey-20
  @media screen and ( max-width: 545px )
    grid-gap: 16px
  &__content
    display: flex
    justify-content: space-between
    align-items: flex-start
    @media screen and ( max-width: 545px )
      display: grid
      grid-gap: 26px
      justify-content: normal
      grid-template-columns: repeat(2,auto)
      align-content: start
    &__main
      width: 100%
      justify-content: flex-end
      display: flex
      align-items: center
      gap: 24px
      @media screen and ( max-width: 545px )
        flex-wrap: wrap
        justify-content: flex-start
        align-items: flex-start
        margin-left: 0px

.cell__ordine__info
  display: flex
  &__image
    width: 86px
    height: 86px
  &__main
    font-size: 14px
    display: grid
    margin-left: 16px
    grid-gap: 8px
    &__descrizione
      max-width: 180px
      white-space: nowrap
      overflow: hidden
      text-overflow: ellipsis
    &__placeholder
      color: $grey-80
      text-transform: uppercase
      font-size: 12px
      line-height: 1.67
      margin-bottom: 10px
      letter-spacing: 0.51px
      font-weight: bold

.element__not__in__responsive
  display: block
  @media screen and ( max-width: 545px )
    display: none
.element__in__responsive
  display: none
  @media screen and ( max-width: 545px )
    display: block

.cell__ordine__wrapper
  display: flex
  flex-direction: column
  justify-items: flex-start
  align-content: flex-start
  text-align: center
  width: 100%
  @media screen and ( max-width: 545px )
    text-align: start
  a
    text-decoration: underline

.desc__dots
  max-width: 180px
  white-space: nowrap
  overflow: hidden
  text-overflow: ellipsis

.cell__ordine__open
  display: flex
  justify-content: space-between
  @media screen and ( max-width: 545px )
    display: grid
    grid-gap: 16px
  &__note
    color: $grey-80
    font-size: 12px
    line-height: 1.33
    word-wrap: break-word
    width: 50%
    @media screen and ( max-width: 545px )
      order: 2
      width: 100%
      padding-bottom: 24px
  &__info
    display: grid
    grid-gap: 8px
    font-size: 12px
    width: 30%
    @media screen and ( max-width: 545px )
      width: 100%
    &__row
      padding-right: 20px
      display: flex
      justify-content: space-between
      @media screen and ( max-width: 545px )
        padding-right: 0px
        justify-content: space-between
