
.info-label
  display: flex
  &__label
    padding-right: 16px
  &__icon-wrap
    svg
      display: block
      +color-icon($grey-80)
  &--spaced
    justify-content: space-between

  &--md
    .info-label__icon-wrap
      svg
        width: 20px
        height: 20px
  &--lg
    .info-label__icon-wrap
      svg
        width: 24px
        height: 24px
  &--sm
    .info-label__icon-wrap
      svg
        width: 16px
        height: 16px
