
.datatable-row-personalizzazione
  background: $fill-others-dark-orange-0!important
  .datatable-row__cells
    +res-md-min
      align-items: center
  &__delete-button
    font-weight: normal!important
    min-width: 0!important
    .button
      &__icon
        margin-right: 12px!important
        +res-md-min
          margin-right: 0!important
      &__label
        +res-md-min
          display: none

  &__edit-button
    min-width: 0!important
    .button
      &__icon
        margin-right: 12px!important

.personalizzazione-info
  display: flex
  align-items: center
  &__icon
    svg
      width: 20px
      height: 20px
      fill: $fill-others-dark-orange-50
  &__content
    margin-left: 16px
    +typoSmallText
  &__title
    font-weight: bold
    margin-bottom: 4px
