
.materiale-promozionale-header
    display: flex
    flex-direction: column
    align-content: center
    justify-content: center
    padding: 7em 10em 0

    h2
        line-height: 3

.materiale-promozionale-container
    display: flex
    flex-direction: row
    align-content: center
    flex-wrap: wrap

    .materiale-promozionale-card
        display: flex
        flex-direction: column
        line-height: 2
        width: 20%
        flex-grow: 2

        &__img
            width: 200px
            height: 200px
            background-color: $grey-10
