
.banner
  position: fixed
  left: 0
  right: 0
  // height gestita in layout.sass
  background-color: $fill-accent-80
  color: $white
  padding: 10px 0
  display: flex
  flex-direction: column
  justify-content: center
  +res-sm-max
    padding: 20px 8px
  &__content
    width: 100%
    +wcontainer
    display: flex
    justify-content: space-between
    align-items: center
    +typoSmallText
  &__title
    max-height: 20px
    overflow: hidden
    display: -webkit-box
    -webkit-line-clamp: 1
    -webkit-box-orient: vertical
    +res-md-max
      max-height: 40px
      -webkit-line-clamp: 2
    +res-sm-max
      max-height: 80px
      -webkit-line-clamp: 4
    a
      color: currentColor
      text-decoration: underline
      cursor: pointer
      +res-sm-max
        display: block
  &__alert
    font-weight: 700
  &__close
    flex: 0 0 20px
    margin-left: auto
    width: 20px
    height: 20px
    +color-icon(currentColor)
