
.cell__custom
  font-family: 'Open sans',sans-serif
  background-color: $fill-others-dark-orange-0
  padding: 16px 8px
  display: grid
  font-size: 14px
  &__info
    &__wrapper
      display: flex
      justify-content: flex-start
      &__desc
        margin-left: 24px
        display: grid
        strong
          margin-bottom: 4px
  &__placeholder
    color: $grey-80
    text-transform: uppercase
    font-size: 12px
    line-height: 1.67
    letter-spacing: 0.51px
    font-weight: bold
  &__content
    display: flex
    justify-content: space-between
    align-items: center
    @media screen and ( max-width: 545px )
      display: grid
      grid-gap: 26px
      justify-content: normal
    &__main
      width: 100%
      justify-content: flex-end
      display: flex
      align-items: center
      gap: 24px
      @media screen and ( max-width: 545px )
        flex-wrap: wrap
        justify-content: flex-start
        align-items: flex-start
        margin-left: 0px

.cell__custom__wrapper
  display: grid
  grid-gap: 10px

.element__not__in__responsive
  display: block
  @media screen and ( max-width: 545px )
    display: none
.element__in__responsive
  display: none
  @media screen and ( max-width: 545px )
    display: block
