@import "../../../../../styles/sass/commons"

.configurazione__stampa
  display: flex
  flex-direction: column
  grid-gap: 12px
  &__label
    color: $grey-80
    font-size: 16px
    line-height: 1.5
    margin-right: 16px
  &__placeholder
    color: $grey-80
    font-size: 12px
    font-weight: bold
    line-height: 1.43
    letter-spacing: 0.6px
    margin-bottom: 4px
    text-transform: uppercase
  &__item
    background-color: $fill-neutrals-grey-0
    border: 2px solid $grey-10
    padding: 24px
    border-radius: 8px
    grid-gap: 24px
    font-size: 16px
    display: flex
    flex-direction: column
    width: 100%
    &__select
      min-width: 35%
      max-width: 35%
      @media screen and ( max-width: 768px )
        max-width: 100%
  &__item-form
    +res-md-min
      display: flex
      flex-wrap: wrap
      +custom-row(40px)
  &__field
    margin: 12px 0
    +res-md-min
      width: 40%
      +res-md-max
        width: 50%
      &--fullrow
        width: 100%
      &--break
        margin-right: 60%
        +res-md-max
          margin-right: 50%
      &--wide
        width: 60%
        +res-md-max
          width: 100%
  &__wrapper
    &__misure
      +res-md-min
        display: flex
        align-items: center
        > *
          &:not(:last-child)
            margin-right: 24px
      +res-sm-max
        > *
          &:not(:last-child)
            margin-bottom: 8px
      &__input
        display: flex
        align-items: center
        > *
          flex: 1
          max-width: 120px
          &:not(:last-child)
            margin-right: 16px
    &__colori
      display: flex
      width: 100%
      &__inputs
        min-width: 50%
        display: flex
        flex-wrap: wrap

  &__dettagli__posizioni
    .button
      &__label
        text-align: left
        font-weight: 400
        text-decoration: none
    .formtextarea
      min-height: 40px!important
      margin-top: 4px
