.markup-template
  &__submit
    margin-top: 20px
    display: flex
    width: 100%
    justify-content: flex-end
  &__heading
    margin: 20px 0px
    h1
      font-size: 24px
      font-weigth: bold
    p
      margin-top: 10px
      font-size: 16px
  &__background
    margin-top: 20px
    background-color: #f1f2f4
    padding: 16px
    border-radius: 6px
  &__wrapper-input
    margin: 15px 0px
    display: flex
    flex-direction: row
    align-items: center
  &__totale
    display: flex
    flex-direction: row
    justify-content: space-between
    align-items: center
    margin: 24px 0px
    &__label
      display: flex
      flex-direction: row  
      font-size: 16px
      font-weight: bold
      p
        margin-right: 15px
  &__input
    display: flex
    flex-direction: row
    min-width: 100px
    width: 33%
    max-width: 300px
    align-items: center 
    margin-right: 15px
    &:last-child
      margin-right: 0px
    &__type
      display: flex
      flex-direction: row
      align-items: center
      margin-left: 10px
      padding-top: 15px
      justify-content: center
      text-align: center
      font-size: 14px 
    &__text
      width: 60%
      margin-right: 10px
  &__altri-costi
    padding-top: 18px
    margin-bottom: 14px
    &__heading
      font-weight: bold
      font-size: 14px
      padding: 14px 0px
    &__item
      background-color: #f1f2f4
      padding: 12px
      border-radius: 4px
      display: flex
      flex-direction: row
      margin-bottom: 16px
      justify-content: space-between
      align-items: center
    &__label
          font-size: 12px
          font-weight: bold
  &__radio
    &__item
      display: flex
      flex-direction: row
      margin-bottom: 12px
      justify-content: space-between
      align-items: center
    &__container
      display: flex
      flex-direction: column
      margin: 24px 0px
