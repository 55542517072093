
.myaccount-block
  margin-bottom: 40px
  &__head
    display: flex
    justify-content: space-between
  &__title
    +typoRegularText
    font-weight: 700
    margin-bottom: 16px
  &__text
    +typoRegularText
    p
      margin-bottom: 16px
      a
        color: $color-primary
        text-decoration: underline
