
.indirizzo-card-checkout
  
  &__content
    +typoRegularText
    padding: 22px 16px
    border-radius: 4px
    border: 1px solid $grey-80

    &:not(:last-child)
      margin-bottom: 16px
