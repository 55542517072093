
.cataloghi-listing
  margin-top: 56px
  +res-sm-max
    margin-top: 40px
  &__title
    +typoH5
    margin-bottom: 40px
    +res-sm-max
      margin-bottom: 24px

  &__list
    display: flex
    flex-wrap: wrap
    +custom-row(30px)
    > *
      width: 25%
      margin-bottom: 32px
      +res-md-max
        width: 50%
      +res-sm-max
        width: 100%

.cataloghi-card
  max-width: 300px
  margin-left: auto
  margin-right: auto

  &__image-box
    margin-bottom: 16px
    img
      object-fit: cover

  &__title
    +typoRegularText
    font-weight: bold
    margin-bottom: 8px

  &__description
    +typoRegularText

  &__buttons
    margin-top: 16px
    display: flex
    flex-direction: row
    justify-content: flex-start
    > *
      &:not(:last-child)
        margin-right: 24px
      .button__icon--left
        margin-right: 8px

.link-utili-catalogo
  +typoSmallText
  margin-top: 32px
  +res-sm-max
    margin-top: 24px
    padding-top: 4px
    padding-bottom: 4px
  &__title
    position: relative
    +typoCaption
    color: $grey-80
    +res-sm-max
      padding-top: 6px
      padding-bottom: 6px
  &__mobile-arrow
    position: absolute
    right: 0
    top: 6px
    svg
      +style-icon(20px, $black)
  &__content
    padding: 8px 0
  &__item
    display: block
    text-align: left
    position: relative
    padding: 6px 0 6px 32px
    cursor: pointer
  &__icon
    position: absolute
    top: 6px
    left: 0
    width: 20px
    height: 20px
    svg
      +style-icon(20px, $black)

  &--mobile-open
    .link-utili-catalogo
      &__mobile-arrow
        +rotate(180deg)
      &__content
        display: block
