
.catalogo-card
  background-color: white
  max-width: 255px
  +res-sm-max
    max-width: none
  &__image
    display: block
    max-height: 264px
    margin: 0 auto
  &__content
    padding: 16px 16px 12px
  &__title
    +typoRegularText
    font-weight: 700
    margin-bottom: 8px
  &__description
    +typoSmallText
    color: $grey-80
    min-height: 40px
    margin-bottom: 12px
  &__buttons
    display: flex
    justify-content: flex-start
    gap: 12px
  &__button
    display: flex
    align-items: center
    +typoSmallText
    cursor: pointer
    padding-right: 30px
    &-icon
      padding-right: 12px
      svg
        display: block
        width: 20px
        height: 20px
        +color-icon(currentColor)
    &:hover
      .catalogo-card
        &__button
          &-label
            text-decoration: underline
