.miei-loghi
  &__item 
    padding: 14px
    border-radius: 6px
    background-color: #f1f2f4
    display: flex
    flex-direction: row
    justify-content: space-between
    min-width: 220px
    min-height: 80px
    align-items: center
    flex-wrap: wrap
    p
      margin-right: 14px
    img
      height: 80px
      margin-right: 14px
  &__no-logo-wrapper
    display: flex
    flex-direction: row
    margin-top: 20px
    button
      margin-left: 15px
  &__heading
    margin: 20px 0px
    h1
      font-size: 24px
      font-weigth: bold
    p
      margin-top: 10px
      font-size: 16px
  &__wrapper
    margin-top: 20px
    display: flex
    flex-direction: row
    align-items: center
    flex-wrap: wrap
    div
      margin-top: 10px
      margin-right: 20px
    p
      font-size: 14px