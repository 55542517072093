.richiesta-attivazione
  &__description
    +typoRegularText
    margin-bottom: 40px

  &__form
    max-width: 750px
    margin-left: auto
    margin-right: auto

  &__fields
    +res-md-min
      display: flex
      flex-wrap: wrap
      > *
        width: calc(50% - 15px)
        &:nth-child(2n+1)
          margin-right: 30px

  &__field
    margin-bottom: 24px

  &__submit
    margin-top: 24px
    +res-sm-max
      margin-left: auto
      margin-right: auto