@import ../../../styles/sass/commons
  
.search-menu
  &__block
    margin-bottom: 32px
  &__title
    +typoCaption
    color: $grey-80
  &__list
  &__item
    +typoSmallText
    display: block
    margin-top: 12px
  &__num-prod
    color: $grey-80