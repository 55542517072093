
.richiesta-catalogo-container
  &__description
    +typoRegularText
    margin-bottom: 40px

  &__form
    max-width: 750px
    margin-left: auto
    margin-right: auto

  &__fields
    +res-md-min
      display: flex
      flex-wrap: wrap
      +custom-row(30px)
      > *
        width: 50%

  &__field
    margin-bottom: 24px
    &--full
      +res-md-min
        width: 100%

  &__submit
    margin-top: 24px
    +res-sm-max
      margin-left: auto
      margin-right: auto
