
.checkout
  font-family: 'Open sans',sans-serif
  font-size: 14px
  display: flex
  margin-top: 20px
  margin-bottom: 100px
  flex-direction: column
  justify-items: center
  align-content: center
  &__link
    margin-bottom: 20px
    cursor: pointer
    &:hover
      text-decoration: underline
  &__content
    +res-lg-min
      display: flex
      justify-content: space-between

.checkout
  &__section
    margin-bottom: 32px
  &__section-title
    +typoRegularText
    font-weight: 700
    margin-bottom: 16px
  &__section-subtitle
    +typoRegularText
    margin-bottom: 16px
  &__formfield
    margin-bottom: 16px
  &__option
    padding: 16px 24px
    +res-sm-max
      padding: 8px 16px
    &--indirizzo
      padding-top: 8px
      padding-bottom: 8px
    .formradio__styledradio
      top: calc(50% - 10px)
    &--selected
      background: $fill-neutrals-grey-0
  &__main
    +res-lg-min
      flex: 1
      margin-right: 3.2rem
  &__sidebar
    +res-md-max
      margin-top: 32px
    +res-lg-min
      width: 255px
      position: relative
      .riepilogo-card, .riepilogo-card-carrello
        -webkit-position: sticky
        position: sticky
        // top gestito la layout.sass
