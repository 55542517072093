
.faq_container
    display: flex
    flex-direction: row

    @media screen and ( max-width: 568px )
        flex-direction: column
        padding-left: 0

    .faq_container_sidepanel
        display: flex
        flex-direction: column
        font-family: 'Open Sans'
        font-size: 16px
        line-height: 1.5
        padding: 10px
        min-width: 25%

        &__titolo
            cursor: pointer
            line-height: 1.5
            background-color: #F5F9FC
            padding: 10px
            margin: 3px 0

    .faq_container_items
        display: flex
        flex-direction: column
        line-height: 1.5
        font-size: 12px
        width: 80%

        @media screen and ( max-width: 568px )
            width: 100%
            padding: 10px

        &__titolo
            font-size: 16px
            line-height: 5
            font-weight: bold

    .faq_container_item
        border-bottom: 1px solid $grey-20
        .accordion__placeholder
            font-weight: 400
        &__risposta
            padding: 16px 0
