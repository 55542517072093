
.dettagli-prodotto
  &__listino
    +typoRegularText
    &__block
      &:not(:last-child)
        margin-bottom: 24px
    &__block-title
      margin-bottom: 16px

    &__cell
      &--title
        color: $grey-80
      &--quantity
        color: $grey-80

    +res-md-min
      &__table
        display: flex
        +custom-row(16px)
        > *
          flex: 1
          max-width: 100px
          &:first-child
            max-width: 210px
      &__cell
        &:not(:last-child)
          margin-bottom: 16px

    +res-sm-max
      &__col
        display: flex
        align-items: flex-start
        justify-content: space-between
        margin-bottom: 8px
        > *
          max-width: 50%
          &:last-child
            text-align: right
