
.alert
  padding: 12px
  max-width: 496px
  display: flex
  align-items: flex-start
  justify-content: flex-start
  border-radius: 4px
  background-color: $fill-semantics-error-10
  color: $error
  font-size: 16px
  line-height: 1.5
  &__icon, &__close
    svg
      display: block
      width: 24px
      height: 24px
      +color-icon(currentColor)
  &__icon
    margin-right: 12px
  &__close
    flex: 0 0 32px
    margin-left: auto
    svg
      cursor: pointer

  &--info
    background-color: $fill-semantics-info-10
    color: $info

  &--full
    max-width: 100%

  &--clickable
    cursor: pointer
