@import "../../../../styles/sass/commons"

.news_detail_main_container 
    font-family: 'Open Sans'

    .news_detail_page_header 
        max-width: 100%
        height: 230px
        position: relative
    
    .news_detail_content 
        max-width: 800px
        padding: 0 16px
        margin: 30px auto 0

        &__data
            color: $grey-80
            font-size: 14px
            font-weight: bold
            line-height: 1.43
            letter-spacing: 0.6px
            margin-bottom: 30px

        &__titolo
            +typoH4
            color: $black
            margin-bottom: 30px

        &__description 
            +typoRegularText
            font-size: 14px
            margin-bottom: 40px
            > div
                margin-bottom: 10px
        &__slider
            .swiper-slide
                width: 100%!important
        
        &__slide
            text-align: center
            position: relative
            height: 320px

        &__footer 
            display: flex
            flex-direction: row
            justify-content: space-between
            border-top: 1px solid $grey-20
            margin-top: 64px
        
        &__prev
            margin-right: auto
            margin-left: 0
        &__next
            margin-left: auto
            margin-right: 0



