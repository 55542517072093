// Gestione elementi in posizione FIXED

.header
  top: 0
  +transition(top .5s)
  &--full
    height: $header-full-height-desktop
    +res-md-max
      height: $header-full-height-tablet
    +res-sm-max
      height: $header-full-height-mobile
  &--semplificato
    height: $header-semplificato-height-desktop
    +res-sm-max
      height: $header-semplificato-height-mobile

.toolbar
  top: 0
  height: $toolbar-height

body:not(.w-scrolled)
  .banner
    top: 0
    height: $banner-height-desktop
    +res-md-max
      height: $banner-height-tablet
    +res-sm-max
      height: $banner-height-mobile
    ~ .header
      top: $banner-height-desktop
      +res-md-max
        top: $banner-height-tablet
      +res-sm-max
        top: $banner-height-mobile


.toolbar 
  ~ .banner
    top: $toolbar-height
  ~ .header
    top: $toolbar-height
    .nav-mobile, .subnav-mobile
      top: $toolbar-height

body:not(.w-scrolled)
  .toolbar 
    ~ .banner
      top: $toolbar-height
      ~ .header
        top: #{$toolbar-height + $banner-height-desktop}
        +res-md-max
          top: #{$toolbar-height + $banner-height-tablet}
        +res-sm-max
          top: #{$toolbar-height + $banner-height-mobile}


.header
  &--full
    ~ .page-body
      padding-top: $header-full-height-desktop
      +res-md-max
        padding-top: $header-full-height-tablet
      +res-sm-max
        padding-top: $header-full-height-mobile
  &--semplificato
    ~ .page-body
      padding-top: $header-semplificato-height-desktop
      +res-sm-max
        padding-top: $header-semplificato-height-mobile

.toolbar 
  ~ .header
    &--full
      ~ .page-body
        padding-top: #{$toolbar-height + $header-full-height-desktop}
        +res-md-max
          padding-top: #{$toolbar-height + $header-full-height-tablet}
        +res-sm-max
          padding-top: #{$toolbar-height + $header-full-height-mobile}
    &--semplificato
      ~ .page-body
        padding-top: #{$toolbar-height + $header-semplificato-height-desktop}
        +res-sm-max
          padding-top: #{$toolbar-height + $header-semplificato-height-mobile}


// Il banner lascia il padding anche se si scrolla
.banner 
  ~ .header
    &--full
      ~ .page-body
        padding-top: #{$banner-height-desktop + $header-full-height-desktop}
        +res-md-max
          padding-top: #{$banner-height-tablet + $header-full-height-tablet}
        +res-sm-max
          padding-top: #{$banner-height-mobile + $header-full-height-mobile}
    &--semplificato
      ~ .page-body
        padding-top: #{$banner-height-desktop + $header-semplificato-height-desktop}
        +res-md-max
          padding-top: #{$banner-height-tablet + $header-semplificato-height-desktop}
        +res-sm-max
          padding-top: #{$toolbar-height + $header-semplificato-height-mobile}

.toolbar 
  ~ .banner 
    ~ .header
      &--full
        ~ .page-body
          padding-top: #{$toolbar-height + $banner-height-desktop + $header-full-height-desktop}
          +res-md-max
            padding-top: #{$toolbar-height + $banner-height-tablet + $header-full-height-tablet}
          +res-sm-max
            padding-top: #{$toolbar-height + $banner-height-mobile + $header-full-height-mobile}
      &--semplificato
        ~ .page-body
          padding-top: #{$toolbar-height + $banner-height-desktop + $header-semplificato-height-desktop}
          +res-md-max
            padding-top: #{$toolbar-height + $banner-height-tablet + $header-semplificato-height-desktop}
          +res-sm-max
            padding-top: #{$toolbar-height + $banner-height-mobile + $header-semplificato-height-mobile}

// Sticky che si attacca all'header solo da lg+ 
// non considero il banner perchè lo sticky si attiva sullo scroll e il banner sparisce
.sticky
  &--active
    +res-lg-min
      top: 0px

.header
  &--full
    ~ .page-body
      .sticky
        &--active
          +res-lg-min
            top: $header-full-height-desktop
  
  &--semplificato
    ~ .page-body
      .sticky
        &--active
          +res-lg-min
            top: $header-semplificato-height-desktop

.toolbar 
  ~ .header
    &--full
      ~ .page-body
        .sticky
          &--active
            +res-lg-min
              top: #{$toolbar-height + $header-full-height-desktop}
    &--semplificato
      ~ .page-body
        .sticky
          &--active
            +res-lg-min
              top: #{$toolbar-height + $header-semplificato-height-desktop}


// Riepilogo card in scheda prodotto
// non considero il banner perchè lo sticky si attiva sullo scroll e il banner sparisce
.prodotto-template__sidebar, .card__preventivo__responseive, .checkout__sidebar
  .riepilogo-card, .riepilogo-card-carrello
    +res-lg-min
      top: 50px // margine dall'header

.header--full
  ~ .page-body
    .prodotto-template__sidebar, .card__preventivo__responseive, .checkout__sidebar
      .riepilogo-card, .riepilogo-card-carrello
        +res-lg-min
          top: #{$header-full-height-desktop + 50px}

.toolbar 
  ~ .header
    &--full
      ~ .page-body
        .prodotto-template__sidebar, .card__preventivo__responseive, .checkout__sidebar
          .riepilogo-card, .riepilogo-card-carrello
            +res-lg-min
              top: #{$toolbar-height + $header-full-height-desktop + 50px}

    &--semplificato
       ~ .page-body
        .prodotto-template__sidebar, .card__preventivo__responseive, .checkout__sidebar
          .riepilogo-card, .riepilogo-card-carrello
            +res-lg-min
              top: #{$toolbar-height + $header-semplificato-height-desktop + 50px}

.toolbar 
  ~ .Toastify
    .Toastify__toast-container--top
      &-center, &-left, &-right
        top: #{$toolbar-height + 10px}
        +res-xs-max
          top: $toolbar-height