
.prodotto-side-cart
  display: flex
  justify-content: space-between
  position: relative
  > *
    &:not(:last-child)
      margin-right: 16px
  &__image-box
    position: relative
    width: 86px
    height: 86px
  &__etichetta
    position: absolute
    left: -15px
    bottom: 10px
    font-size: 12px
    color: $accent
    padding: 5px 8px
    background-color: $fill-accent-10
    +res-sm-max
      display: none
  &__main
    flex: 1
    +typoSmallText
  &__nome
    +typoSmallText
    font-weight: bold
  &__descrizione
    +typoSmallText
  &__data
    +typoCaptionSmall
    &--color
      margin-bottom: 8px
  &__data-label
    color: $grey-80
  &__data-value
    color: $black
  &__size
    font-weight: bold
    text-transform: uppercase

  &__bottom-row
    display: flex
    justify-content: space-between
  &__price
    +typoCaptionSmall
    font-weigth: bold
