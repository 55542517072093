
.strumenti-card
  padding: 35px 0
  +res-md-min
    display: flex
    align-items: center
    > *
      width: 50%

  &__content
    position: relative
    padding-left: 60px
    padding-top: 16px
    +res-sm-max
      margin-bottom: 32px

  &__index
    position: absolute
    top: 0
    left: 20px
    font-size: 40px
    font-weight: 400
    line-height: 40px

  &__title
    +typoH5
    margin-bottom: 16px

  &__description
    +typoRegularText

  &__cta
    display: inline-block
    margin-top: 32px

  &__img-box
    position: relative
    padding: 0 0 60px 30px

  &__img
    display: block
    margin: 0 auto

  &__caption
    +typoRegularText
    background: $fill-neutrals-grey-0
    position: absolute
    padding: 35px 30px
    bottom: 0
    left: 0
    max-width: 80%
    min-width: 60%

  &--reversed
    .strumenti-card
      &__img-box
        padding-left: 0
        padding-right: 30px
        +res-md-min
          order: -1

      &__caption
        left: auto
        right: 0
