
.strumenti_container
  +typoRegularText

  &__header
    padding: 20px 0

    &__titolo
      text-align: center
      font-weight: bold

    &__servizi-container
      display: flex
      justify-content: center
      flex-wrap: wrap

      +res-md-min
        flex-wrap: nowrap

      &__servizi
        display: flex
        flex-direction: column
        justify-items: center
        align-items: center
        text-align: center
        flex-basis: 50%

        +res-md-min
          flex-basis: 100%

        &__image-box
          position: relative
          width: 50px
          height: 50px
          border-radius: 50%
          overflow: hidden
          background-color: $grey-20

          img
            position: absolute
            top: 0
            height: 50px
            max-width: none
            left: -9999px
            right: -9999px
            margin: 0 auto
            display: block

        &__description
          width: 80%
          text-align: center
          margin-top: 10px

        &__link
          button
            margin-left: auto
            margin-right: auto
