
.richiesta-attivazione-success
  display: flex
  flex-direction: column
  flex-flow: column wrap
  align-items: center
  justify-content: center
  color: rgb(48,70,89)
  padding: 5% 10%
  width: 100%
  font-family: Open Sans,sans-serif
  font-size: 16px
  line-height: 5

  @media screen and ( max-width: 568px )
    line-height: 2

    .button
      margin-top: 10px

  h1
    line-height: 2
