
.condizioni-servizio
  text-align: left
  &__description
    margin-bottom: 16px
  &__download
    text-align: center
  &__fields
    margin-top: 24px
    > *
      &:not(:last-child)
        margin-bottom: 16px
