
.p__personalizzazione__card
  min-width: 262px
  max-width: fit-content
  height: 112px
  display: flex
  justify-content: center
  align-items: center
  padding: 8px 0px
  background-color: white
  &__image
    height: 96px
    width: 96px
    margin-right: 12px
  &__content
    display: grid
    grid-gap: 8px
    font-size: 12px
    font-weight: bold
    text-transform: uppercase
    &__title
      font-size: 14px
      line-height: 1.43
      letter-spacing: 0.6px
      font-weight: bold
    &__placeholder
      font-size: 12px
      color: $grey-80
      line-height: 1.67
      letter-spacing: 0.51
      font-weight: bold
    &__counter
      display: flex

.personalizzazione__card__error
  font-size: 12px
  color: red
  text-align: center
