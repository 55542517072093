
.autocomplete
  position: relative
  width: 100%
  &__items
    position: absolute
    top: 100%
    left: 0
    right: 0
    +border-radius(4px)
    z-index: 10
    background: $white
    +box-shadow(0 16px 32px 0 rgba(24, 50, 115, 0.08))
    max-height: 200px
    overflow-y: auto
    +scrollbar-style
    > *
      padding: 6px 16px
      +typoSmallText
      &:hover
        background: $fill-accent-0!important
