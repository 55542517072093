
.ordine-veloce__articolo
  display: flex
  align-items: flex-end
  margin-bottom: 24px
  +res-xs-max
    flex-wrap: wrap
  > *
    &:not(:last-child)
      margin-right: 24px
  &__codice
    flex: 1
    max-width: 400px
    +res-xs-max
      max-width: none
      flex: 0 0 100%
      margin-right: 0
      margin-bottom: 8px

  &__quantita
    width: 125px
    .formfield__input-wrapper
      padding-top: 6px
      padding-bottom: 6px
  &__remove
    padding: 8px
    cursor: pointer
    svg
      +style-icon(24px, currentColor)
    +res-xs-max
      margin-left: auto
