.emissione-fatture
  padding-top: 1.5rem
  padding-bottom: 2rem
  &__docs
    display: flex
    flex-wrap: wrap
    padding-top: 1.5rem
    gap: 20px
  &__disclaimer
    +typoTinyText
