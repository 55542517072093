// Colors 

// from Zeplin
$grey-50: #bdc2cb
$white: #ffffff
$grey-20: #e0e2e6
$grey-100: #5e6777
$black: #131518
$grey-80: #8891a1
$success: #56c128
$info: #7188fc
$grey-10: #f1f2f4
$warning: #ffca2d
$accent: #04acef
$error: #ff632b
$fill-accent-80: #038abf
$dark-indigo: #0a1f44
$fill-accent-20: #82dafd
$fill-accent-10: #d5f3fe
$fill-semantics-success-10: #e4f8dc
$fill-semantics-success-80: #3c871c
$fill-semantics-warning-10: #fff4d4
$fill-semantics-warning-80: #d49f00
$fill-semantics-error-80: #d43800
$fill-semantics-error-10: #ffe0d4
$fill-semantics-info-80: #5a6dca
$fill-semantics-info-10: #d5dcfe
$fill-neutrals-grey-0: #f8f9fa
$fill-others-dark-orange-50: #ed5900
$fill-others-green: #31AC58
$fill-others-blu-green: #16b19a
$fill-others-blue: #1150df
$fill-others-light-blue: #60afe1
$fill-others-yellow: #fad125
$fill-others-yellow-green: #b8d431
$fill-accent-100: #025678
$fill-accent-90: #02678f
$fill-accent-0: #ebf9ff
$fill-others-orange: #f2b31b
$fill-others-dark-orange-0: #fff7f0
// END from Zeplin

$color-primary: $accent

// Fonts
$font-primary: 'Open Sans', sans-serif

// Resolutions 

$res-xs-max: 575px
$res-sm-min: 576px
$res-sm-max: 767px
$res-md-min: 768px
$res-md-max: 991px
$res-lg-min: 992px
$res-lg-max: 1199px
$res-xl-min: 1200px

$wcont-width: 1280px
$wcont-inner-width: 1220px
$wcont-small-width: 1000px

$border-radius-big: 9rem
$border-radius-medium: 4.8rem
$border-radius-small: 2.7rem

$transition-duration: 1s

// Icons
$swiper-left-arrow-black:  url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCI+CiAgICA8ZGVmcz4KICAgICAgICA8cGF0aCBpZD0iNGVoNmc4MzZ5YSIgZD0iTTE1LjgzMyA5LjE2N0g1Ljk0NmwzLjAyNy0zLjYzNGMuMjk1LS4zNTMuMjQ3LS44NzktLjEwNi0xLjE3My0uMzU1LS4yOTUtLjg4LS4yNDctMS4xNzQuMTA3bC00LjE2NiA1Yy0uMDMzLjAzOS0uMDUuMDg1LS4wNzQuMTI4LS4wMi4wMzUtLjA0NC4wNjUtLjA1OS4xMDMtLjAzNy4wOTYtLjA2LjE5Ny0uMDYuMjk5di4wMDZjMCAuMTAyLjAyMy4yMDMuMDYuMjk5LjAxNS4wMzguMDQuMDY4LjA2LjEwMy4wMjMuMDQzLjA0LjA5LjA3My4xMjhsNC4xNjYgNWMuMTY1LjE5OC40MDIuMy42NC4zLjE4OSAwIC4zNzgtLjA2My41MzQtLjE5My4zNTMtLjI5NC40MDEtLjgyLjEwNi0xLjE3M2wtMy4wMjctMy42MzRoOS44ODdjLjQ2IDAgLjgzNC0uMzczLjgzNC0uODMzIDAtLjQ2LS4zNzQtLjgzMy0uODM0LS44MzMiLz4KICAgIDwvZGVmcz4KICAgIDxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGc+CiAgICAgICAgICAgIDxnPgogICAgICAgICAgICAgICAgPGc+CiAgICAgICAgICAgICAgICAgICAgPGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTI2IC00MjYpIHRyYW5zbGF0ZSgwIDIwOCkgdHJhbnNsYXRlKDE2IDIwOCkgdHJhbnNsYXRlKDEwIDEwKSI+CiAgICAgICAgICAgICAgICAgICAgICAgIDx1c2UgZmlsbD0iIzEzMTUxOCIgeGxpbms6aHJlZj0iIzRlaDZnODM2eWEiLz4KICAgICAgICAgICAgICAgICAgICA8L2c+CiAgICAgICAgICAgICAgICA8L2c+CiAgICAgICAgICAgIDwvZz4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPgo=')
$swiper-left-arrow-white:  url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCI+CiAgICA8ZGVmcz4KICAgICAgICA8cGF0aCBpZD0iNGVoNmc4MzZ5YSIgZD0iTTE1LjgzMyA5LjE2N0g1Ljk0NmwzLjAyNy0zLjYzNGMuMjk1LS4zNTMuMjQ3LS44NzktLjEwNi0xLjE3My0uMzU1LS4yOTUtLjg4LS4yNDctMS4xNzQuMTA3bC00LjE2NiA1Yy0uMDMzLjAzOS0uMDUuMDg1LS4wNzQuMTI4LS4wMi4wMzUtLjA0NC4wNjUtLjA1OS4xMDMtLjAzNy4wOTYtLjA2LjE5Ny0uMDYuMjk5di4wMDZjMCAuMTAyLjAyMy4yMDMuMDYuMjk5LjAxNS4wMzguMDQuMDY4LjA2LjEwMy4wMjMuMDQzLjA0LjA5LjA3My4xMjhsNC4xNjYgNWMuMTY1LjE5OC40MDIuMy42NC4zLjE4OSAwIC4zNzgtLjA2My41MzQtLjE5My4zNTMtLjI5NC40MDEtLjgyLjEwNi0xLjE3M2wtMy4wMjctMy42MzRoOS44ODdjLjQ2IDAgLjgzNC0uMzczLjgzNC0uODMzIDAtLjQ2LS4zNzQtLjgzMy0uODM0LS44MzMiLz4KICAgIDwvZGVmcz4KICAgIDxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGc+CiAgICAgICAgICAgIDxnPgogICAgICAgICAgICAgICAgPGc+CiAgICAgICAgICAgICAgICAgICAgPGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTI2IC00MjYpIHRyYW5zbGF0ZSgwIDIwOCkgdHJhbnNsYXRlKDE2IDIwOCkgdHJhbnNsYXRlKDEwIDEwKSI+CiAgICAgICAgICAgICAgICAgICAgICAgIDx1c2UgZmlsbD0iI0ZGRiIgeGxpbms6aHJlZj0iIzRlaDZnODM2eWEiLz4KICAgICAgICAgICAgICAgICAgICA8L2c+CiAgICAgICAgICAgICAgICA8L2c+CiAgICAgICAgICAgIDwvZz4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPgo=')
$swiper-right-arrow-white: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCI+CiAgICA8ZGVmcz4KICAgICAgICA8cGF0aCBpZD0id3J5MDZ6NDJ6YSIgZD0iTTQuMTY3IDEwLjgzM2g5Ljg4N2wtMy4wMjcgMy42MzRjLS4yOTUuMzUzLS4yNDcuODc5LjEwNiAxLjE3My4zNTUuMjk1Ljg4LjI0NyAxLjE3NS0uMTA3bDQuMTY2LTVjLjAzMy0uMDM5LjA0OS0uMDg1LjA3My0uMTI4LjAyLS4wMzUuMDQ0LS4wNjUuMDU5LS4xMDMuMDM3LS4wOTYuMDYtLjE5Ny4wNi0uMjk5VjEwdi0uMDAzYzAtLjEwMi0uMDIzLS4yMDMtLjA2LS4yOTktLjAxNS0uMDM4LS4wNC0uMDY4LS4wNi0uMTAzLS4wMjMtLjA0My0uMDQtLjA5LS4wNzItLjEyOGwtNC4xNjctNWMtLjE2NS0uMTk4LS40MDItLjMtLjY0LS4zLS4xODkgMC0uMzc4LjA2My0uNTM0LjE5My0uMzUzLjI5NC0uNDAxLjgyLS4xMDYgMS4xNzNsMy4wMjcgMy42MzRINC4xNjdjLS40NiAwLS44MzQuMzczLS44MzQuODMzIDAgLjQ2LjM3NC44MzMuODM0LjgzMyIvPgogICAgPC9kZWZzPgogICAgPGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8Zz4KICAgICAgICAgICAgPGc+CiAgICAgICAgICAgICAgICA8ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMTI2NSAtMTMzNCkgdHJhbnNsYXRlKDEyNTUgMTMyNCkgdHJhbnNsYXRlKDEwIDEwKSI+CiAgICAgICAgICAgICAgICAgICAgPHVzZSBmaWxsPSIjRkZGIiB4bGluazpocmVmPSIjd3J5MDZ6NDJ6YSIvPgogICAgICAgICAgICAgICAgPC9nPgogICAgICAgICAgICA8L2c+CiAgICAgICAgPC9nPgogICAgPC9nPgo8L3N2Zz4K')
$swiper-right-arrow-black: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCI+CiAgICA8ZGVmcz4KICAgICAgICA8cGF0aCBpZD0id3J5MDZ6NDJ6YSIgZD0iTTQuMTY3IDEwLjgzM2g5Ljg4N2wtMy4wMjcgMy42MzRjLS4yOTUuMzUzLS4yNDcuODc5LjEwNiAxLjE3My4zNTUuMjk1Ljg4LjI0NyAxLjE3NS0uMTA3bDQuMTY2LTVjLjAzMy0uMDM5LjA0OS0uMDg1LjA3My0uMTI4LjAyLS4wMzUuMDQ0LS4wNjUuMDU5LS4xMDMuMDM3LS4wOTYuMDYtLjE5Ny4wNi0uMjk5VjEwdi0uMDAzYzAtLjEwMi0uMDIzLS4yMDMtLjA2LS4yOTktLjAxNS0uMDM4LS4wNC0uMDY4LS4wNi0uMTAzLS4wMjMtLjA0My0uMDQtLjA5LS4wNzItLjEyOGwtNC4xNjctNWMtLjE2NS0uMTk4LS40MDItLjMtLjY0LS4zLS4xODkgMC0uMzc4LjA2My0uNTM0LjE5My0uMzUzLjI5NC0uNDAxLjgyLS4xMDYgMS4xNzNsMy4wMjcgMy42MzRINC4xNjdjLS40NiAwLS44MzQuMzczLS44MzQuODMzIDAgLjQ2LjM3NC44MzMuODM0LjgzMyIvPgogICAgPC9kZWZzPgogICAgPGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8Zz4KICAgICAgICAgICAgPGc+CiAgICAgICAgICAgICAgICA8ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMTI2NSAtMTMzNCkgdHJhbnNsYXRlKDEyNTUgMTMyNCkgdHJhbnNsYXRlKDEwIDEwKSI+CiAgICAgICAgICAgICAgICAgICAgPHVzZSBmaWxsPSIjMTMxNTE4IiB4bGluazpocmVmPSIjd3J5MDZ6NDJ6YSIvPgogICAgICAgICAgICAgICAgPC9nPgogICAgICAgICAgICA8L2c+CiAgICAgICAgPC9nPgogICAgPC9nPgo8L3N2Zz4K')
$swiper-right-chevron-black: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMSIgaGVpZ2h0PSIyMCIgdmlld0JveD0iMCAwIDIxIDIwIj4KICAgIDxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGc+CiAgICAgICAgICAgIDxnPgogICAgICAgICAgICAgICAgPGc+CiAgICAgICAgICAgICAgICAgICAgPGc+CiAgICAgICAgICAgICAgICAgICAgICAgIDxwYXRoIGZpbGw9IiMxMzE1MTgiIGQ9Ik0xMS4zMjEgMTBMNy43NDQgNi40MjNjLS4zMjUtLjMyNi0uMzI1LS44NTMgMC0xLjE3OS4zMjYtLjMyNS44NTMtLjMyNSAxLjE3OSAwbDQuMTY2IDQuMTY3Yy4zMjYuMzI1LjMyNi44NTMgMCAxLjE3OGwtNC4xNjYgNC4xNjdjLS4zMjYuMzI1LS44NTMuMzI1LTEuMTc5IDAtLjMyNS0uMzI2LS4zMjUtLjg1MyAwLTEuMTc5TDExLjMyMSAxMHoiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0zMTUgLTY0NikgdHJhbnNsYXRlKDIyIDQwOCkgdHJhbnNsYXRlKDE1LjcwNiAyMzIpIHRyYW5zbGF0ZSgyNzggNikiLz4KICAgICAgICAgICAgICAgICAgICA8L2c+CiAgICAgICAgICAgICAgICA8L2c+CiAgICAgICAgICAgIDwvZz4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPgo=')
$swiper-left-chevron-black: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIyMCIgdmlld0JveD0iMCAwIDIwIDIwIj4KICAgIDxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGc+CiAgICAgICAgICAgIDxwYXRoIGZpbGw9IiMxMzE1MTgiIGQ9Ik04LjY3OSAxMGwzLjU3NyAzLjU3N2MuMzI1LjMyNi4zMjUuODUzIDAgMS4xNzktLjMyNi4zMjUtLjg1My4zMjUtMS4xNzkgMGwtNC4xNjYtNC4xNjdjLS4zMjYtLjMyNS0uMzI2LS44NTMgMC0xLjE3OGw0LjE2Ni00LjE2N2MuMzI2LS4zMjUuODUzLS4zMjUgMS4xNzkgMCAuMzI1LjMyNi4zMjUuODUzIDAgMS4xNzlMOC42NzkgMTB6Ii8+CiAgICAgICAgPC9nPgogICAgPC9nPgo8L3N2Zz4K')


// Altezze per gestione fixed
$toolbar-height: 40px
$banner-height-desktop: 40px
$banner-height-tablet: 80px
$banner-height-mobile: 120px
$header-full-top-height: 40px
$header-full-main-height: 80px
$header-full-menu-height: 48px
$header-full-height-desktop: 168px // Somma dei 3 precedenti - non posso metterlo in un calcolo perchè sminchia sass
$header-full-height-tablet: 80px
$header-full-height-mobile: 128px
$header-semplificato-height-desktop: 104px
$header-semplificato-height-mobile: 128px