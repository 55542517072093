
.cell__offerte
  font-family: 'Open sans',sans-serif
  background-color: $fill-neutrals-grey-0
  padding: 16px 8px
  display: grid
  font-size: 14px
  &__content
    display: flex
    justify-content: space-between
    align-items: center
    width: 100%
    @media screen and ( max-width: 835px )
      display: grid
      justify-content: normal
      grid-gap: 24px
    &__placeholder
      color: $grey-80
      text-transform: uppercase
      font-size: 12px
      line-height: 1.67
      letter-spacing: 0.51px
      font-weight: bold
    &__main
      width: 40%
      display: flex
      justify-content: space-between
      @media screen and ( max-width: 835px )
        width: 90%
      &__desc
        text-decoration: underline
        cursor: pointer
    &__actions
      width: 40%
      display: flex
      justify-content: space-around
      @media screen and ( max-width: 835px )
        width: 90%
        justify-content: space-between
        padding-bottom: 20px
.cell__offerte__wrapper
  display: grid
  grid-gap: 10px

.element__not__in__responsive
  display: block
  @media screen and ( max-width: 835px )
    display: none
.element__in__responsive
  display: none
  @media screen and ( max-width: 835px )
    display: block

.cell__offerte__wrapper__prezzi
  display: flex
  justify-content: space-around
  min-width: 30%
  align-items: flex-start
  @media screen and ( max-width: 835px )
    justify-content: flex-start
    column-gap: 50px
