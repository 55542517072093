
.pagination
  display: flex
  justify-content: center
  align-items: center
  padding: 2rem 0
  +typoSmallText
  &__item
    min-width: 24px
    text-align: center
    margin: 0 5px
    text-decoration: none
    +border-radius(.8rem)
    +res-sm-max
      margin: 0 1px
    &:hover, &:active, &:focus, &:visited, &:link
      color: $accent
      cursor: pointer
      text-decoration: none
    &--active, &--separator
      font-weight: bold
      cursor: default
    &--separator
      background: transparent
      &::before
        content: "..."
    &--nomobile
      +res-sm-max
        display: none

  &__nav
    display: flex
    align-items: center
    padding: 2px 5px
    font-weight: 700
    color: $accent
    cursor: pointer

    &--prev
      .icon
        +rotate(180deg)

    &--disabled
      opacity: .6
      cursor: default

  &__arrow
    width: 24px
    height: 24px
    +color-icon($accent)
    display: block
