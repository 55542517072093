
.altrecategoriemenu
  +typoRegularText
  &__content
    +wcontainer
    display: flex
    > *
      padding-top: 32px
      padding-bottom: 32px
  &__items
    position: relative
    background-color: $fill-neutrals-grey-0
    padding-left: calc(50vw - 632px) // 632 = (1280 (wcont-width) - 16*2 (padding laterale)) / 2
    margin-left: calc(632px - 50vw) // 632 = (1280 (wcont-width) - 16*2 (padding laterale)) / 2
    flex: 1
    @media screen and (max-width: 1279px)
      padding-left: 16px
      margin-left: -16px

  &__subitems
    width: 75%
    padding-left: 30px
    background-color: #fff

.acm__item
  display: flex
  justify-content: space-between
  align-items: center
  min-height: 35px
  cursor: pointer
  +typoRegularText
  color: $black
  > *
    margin-right: 12px
  &:not(:last-child)
    margin-bottom: 8px
  &:hover
    color: $accent
  &__title
    margin-right: 10px
    word-break: break-word
  &__icon
    svg
      display: block
      width: 24px
      height: 24px
      +color-icon(currentColor)
  &__subitem
    cursor: pointer
    padding: 16px 0
    +typoRegularText
    color: $black
    &__num-prod
      color: $grey-80
      margin-left: 4px
    &:hover
      color: $accent
      text-decoration: underline
      .acm__item__subitem__num-prod
        color: $accent
