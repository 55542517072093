
.attributo-selector
  position: relative
  cursor: pointer

  width: fit-content
  &__options
    background-color: $grey-10
    border-radius: 4px
    z-index: 10
    padding: 5px
    width: 100%
    p
      +typoCaption
      color: $black
      white-space: nowrap
      &:hover
        color: $accent
        cursor: pointer
    +res-sm-max
      +typoCaptionSmall

  &__label
    +typoCaption
    color: $grey-80
    display: flex
    white-space: nowrap
    +res-sm-max
      +typoCaptionSmall
    &-value
      margin-left: 5px
      color: $black
    &-arrow
      margin-left: 5px
      cursor: pointer
    svg
      display: block
      width: 20px
      height: 20px
      +color-icon($black)
