.checkout__pagamento
  &__opzioni
    display: flex
    flex-direction: column
    margin: 20px 0px

  &__codice-sconto
    &__alert
      margin: 8px 0px
      color: $error
    &__info
      display: inline-block
      vertical-align: text-top
      margin-left: 8px
      svg
        display: block
        width: 16px
        height: 16px
        +color-icon($black)
      &:hover
        svg
          +color-icon($accent)
      &--active
        svg
          +color-icon($grey-80)
