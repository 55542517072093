
.step
  flex: 1
  max-width: 180px
  &__nav
    position: relative
    &::before, &::after
      content: ""
      position: absolute
      top: calc(50% - 1px)
      height: 2px
      width: 50%
      z-index: -1
      background: $grey-20
    &::before
      right: 50%
    &::after
      left: 50%
  &__dot
    display: block
    margin: 0 auto
    +border-radius(50%)
    width: 24px
    height: 24px
    background: $black
    +typoSmallText
    font-weight: bold
    color: $white
    text-align: center
    line-height: 20px
    padding: 2px 0
    svg
      display: block
      margin: 0 auto
      width: 20px
      height: 20px
      +color-icon(currentColor)

  &__label
    display: block
    margin-top: 4px
    +typoSmallText
    text-align: center

  &:first-child
    .step__nav
      &::before
        display: none
  &:last-child
    .step__nav
      &::after
        display: none

  &--disabled
    .step__dot
      background: $grey-20

  &--completed
    + .step
      .step__nav
        &::before
          background: $black
    .step
      &__dot
        svg
          fill: $white
      &__nav
        &::before, &::after
          background: $black

.stepbar
  display: flex
  justify-content: center
