
.upload__loghi
  &__posizioni
    margin-bottom: 24px
    > *
      &:not(:last-child)
        margin-bottom: 16px

.posizione
  display: flex
  background-color: $fill-neutrals-grey-0
  align-items: center
  justify-content: flex-start
  border: 2px solid $grey-10
  border-radius: 8px
  padding: 20px 24px
  @media screen and ( max-width: 896px )
    flex-wrap: wrap
  &__header
    +res-sm-max
      margin-bottom: 16px
  &__title
    min-width: 150px
    +typoRegularText
    font-weight: 700
  &__actions
    .formfield
      max-width: none
      word-break: break-all
    &__top
      display: flex
      align-items: center
      svg
        display: block
        width: 20px
        height: 20px
        +color-icon($accent)
        margin-left: 8px
    &__label
      +typoCaption
      color: $grey-80
      text-transform: uppercase
      font-weight: 700
  &__image
    max-width: 60px
    max-height: 60%
    margin-left: 20px
    @media screen and ( max-width: 896px )
      margin-top: 10px
      margin-left: 0px
  &__avviso
    color: red
    font-size: 14px
    line-height: 1.5
    margin-right: 20px
    @media screen and ( max-width: 896px )
      max-width: none

  .image__wrapper
    max-width: 40%
    margin-left: auto
    padding-left: 16px
    display: flex
    align-items: center
    @media screen and ( max-width: 896px )
      max-width: none
      margin-top: 25px
      margin-left: 0
      padding-left: 0
