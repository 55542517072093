
.datatable-row-cart
  position: relative

  &__bspaced-cell // Spazio in basso per aggiungere note
    +res-md-min
      padding-bottom: 60px

  &__actions
    +res-md-min
      position: absolute
      right: 8px
      bottom: 12px
      display: flex
      max-width: 50%
      > *
        &:not(:last-child)
          margin-right: 32px

  &__note-button, &__save-button, &__delete-button
    max-width: none!important
    min-width: 0!important
    font-weight: normal!important
    .button__icon
      margin-right: 12px!important
  &__note-button
    &--show
      .button
        &__label
          text-decoration: underline
  &__delete-button
    .button
      &__icon
        +res-md-min
          margin-right: 0!important
      &__label
        +res-md-min
          display: none

.customize-button
  min-width: 0!important
  padding-left: 12px!important
  padding-right: 12px!important
  .button
    &__icon
      margin-right: 12px!important
      +res-md-min
        margin-right: 0

.customized-block
  display: flex
  align-items: center
  color: $fill-others-dark-orange-50
  &__icon
    margin-right: 12px
    +res-sm-max
      margin-right: 5px
    svg
      width: 24px
      height: 24px
      fill: currentColor
  &__label
    +typoMediumButton

.quantity-edit
  display: flex
  align-items: center
  &__value
    margin-right: 12px
  &__button
    min-width: 0!important
    .button
      &__icon
        +res-xs-max
          margin-right: 5px!important
      &__label
        +res-md-min
          display: none
