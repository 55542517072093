*,
*::after,
*::before
  margin: 0
  padding: 0
  box-sizing: inherit

ul, ul > li
  list-style: none

button
  background: none
  border: none
  outline: none
  font-family: inherit

img
  max-width: 100%

html
  font-size: 62.5%
  scroll-behavior: smooth

main
  display: block
.header--toolbar-active ~ main
  padding-top: 22.8rem
  +res-md-max
    padding-top: 10.2rem
  
video
  width: 100%
  display: block
  outline: none

table
  width: 100%

address
  font-style: normal

a
  outline: none
  text-decoration: none
  color: inherit
  &:hover,
  &:active
    text-decoration: none

body
  box-sizing: border-box
  position: relative
  font-family: $font-primary

  &.w-noscroll
    overflow: hidden

  &.navigation-open, &.dialog-open
    overflow: hidden

  .custom-content
    position: relative
    &.active
      height: 80vh
      margin-bottom: 3rem
      > .pagebuilder-placeholder
        position: absolute
        top: 0
        left: 0
        width: 100%
        height: 100%
  @media (min-width: $wcont-width + 400px)
    .w-container, 
    .w-container-inner
      .custom-content
        &.active
        > .pagebuilder-placeholder
          width: calc(100% + 210px)  // side width

.page-body
  
  //  padding-top: 168px // header sticky
  //  +res-md-max
  //    padding-top: 80px // header sticky tablet - solo main
  //  +res-sm-max
  //    padding-top: 118px // header sticky mobile - solo main con search sotto 

  //  &.page-body--header-semplificato // più piccolo 104 xl lg md - 128 sm xs
  //    padding-top: 104px 
  //    +res-sm-max
  //      padding-top: 118px 

  &.page-body--toolbar
    @media screen
      padding-top: 40px
      .sticky
        &--active
          +res-md-min
            top: 40px

  //    // Se c'è la toolbar aggiungo 40px a tutti i precedenti
  //    padding-top: 208px // header sticky + toolbar
  //    +res-md-max
  //      padding-top: 120px // header sticky tablet - solo main + toolbar
  //    +res-sm-max
  //      padding-top: 158px // header sticky mobile - solo main con search sotto + toolbar
  //    .header
  //      top: 40px
  //    &.page-body--header-semplificato // più piccolo 104 xl lg md - 128 sm xs
  //      padding-top: 144px 
  //      +res-sm-max
  //        padding-top: 158px 

.w-container
  margin-left: auto
  margin-right: auto
  padding-left: 16px
  padding-right: 16px
  max-width: $wcont-width

.w-container-inner
  margin-left: auto
  margin-right: auto
  padding-left: 16px
  padding-right: 16px
  max-width: $wcont-inner-width

.w-container-small  
  margin-left: auto
  margin-right: auto
  padding-left: 16px
  padding-right: 16px
  max-width: $wcont-small-width

.w-container-fluid
  max-width: 100%
  padding-left: 16px
  padding-right: 16px

h1, h2, h3, h4, h5, h6
  margin-bottom: 0

@page 
  size: auto
  margin: 0mm

@media print 
  body 
    color-adjust: exact
    -webkit-print-color-adjust: exact

.cms-page
  min-height: 50rem

.grecaptcha-badge 
  visibility: hidden
