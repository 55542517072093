=typoH2
  font-size: 38px
  line-height: 56px
  font-weight: bold

=typoH3
  font-size: 32px
  line-height: 40px
  font-weight: bold

=typoH4
  font-size: 24px
  line-height: 32px
  font-weight: bold

=typoH5
  font-size: 20px
  line-height: 24px
  font-weight: bold

=typoLargeText
  font-size: 22px
  line-height: 32px
  font-weight: normal

=typoRegularText
  font-size: 16px
  line-height: 24px
  font-weight: normal

=typoSmallText
  font-size: 14px
  line-height: 20px
  font-weight: normal

=typoTinyText
  font-size: 12px
  line-height: 16px
  font-weight: normal

=typoLargeButton
  font-size: 16px
  line-height: 24px
  font-weight: bold
  letter-spacing: 0.6px

=typoMediumButton
  font-size: 14px
  line-height: 20px
  font-weight: bold
  letter-spacing: 0.6px

=typoSmallButton
  font-size: 12px
  line-height: 16px
  font-weight: bold
  letter-spacing: 0.4px

=typoCaption
  font-size: 14px
  line-height: 20px
  font-weight: bold
  letter-spacing: 0.6px
  text-transform: uppercase

=typoCaptionSmall
  font-size: 12px
  line-height: 20px
  font-weight: bold
  letter-spacing: 0.51px
  text-transform: uppercase

=typoCaptionLarge
  font-size: 16px
  line-height: 20px
  font-weight: bold
  letter-spacing: 0.6px
  text-transform: uppercase

