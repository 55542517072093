
.categoria-card-home
  position: relative
  margin: 16px 8px
  display: block
  &__content
    width: 100%
    height: 320px
    padding: 24px 16px
    +res-sm-max
      height: 100%
  &__title
    +typoH5
    margin-bottom: 16px
  &__description
    +typoRegularText
    max-width: 65%
    &__mobile
      max-width: 100%
  &__image
    z-index: -1
  &__cta
    position: absolute
    top: 16px
    right: 12px
    min-width: 48px
    padding: 0
    .button__icon.button__icon--right
      margin-left: 0px