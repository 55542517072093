.news-main-container
  display: flex
  flex-direction: column
  align-items: flex-start
  box-size: border-box
  font-family: 'Open Sans', sans-serif
  font-size: 16px
  line-height: 1.5

  .news-card-container
    display: flex
    flex-direction: row
    flex-wrap: wrap
    margin: 20px 0

    .news
      flex-grow: 4
