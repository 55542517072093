@import '../../../styles/sass/commons'

.fileUpload
  background: $fill-neutrals-grey-0
  padding: 32px 24px
  &__title
    +typoH5
    margin-bottom: 24px
  &__description
    +typoRegularText
    &__link
      display: block
      margin-top: 16px
      text-decoration: underline
  &__uploaded_files
    margin-top: 40px
    margin-bottom: 16px
    &__title
      +typoCaptionSmall
      color: $grey-80
    &__list
      margin-top: 16px
      max-width: 500px
    

.fileUploadItem
  display: flex
  justify-content: flex-end
  margin-bottom: 8px
  &__name
    margin-right: auto
    +typoSmallText
    white-space: nowrap
    overflow-x: hidden
    text-overflow: ellipsis
  &__action
    margin-left: 16px
    cursor: pointer
    svg
      +style-icon(24px, $grey-80)