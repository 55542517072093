@import "../../../../styles/sass/commons"
  
.form__indirizzo_sg
  &__content
    .formfield
      margin-bottom: 16px

    +res-sm-min
      display: flex
      flex-wrap: wrap
      margin-left: -16px
      margin-right: -16px
      > *
        width: 50%
        padding-left: 16px
        padding-right: 16px
        &.form__indirizzo_sg__content--full-row
          margin-right: 50%

  &__legend
    +typoSmallText
    color: $grey-80
  
  &__buttons
    margin-top: 32px
    display: flex
    align-items: center
    justify-content: space-between
