
.categoria
  display: block
  width: 160px
  text-align: center
  &__content
    padding: 8px 4px 16px
    &__image-box
      height: 120px
      width: 120px
      margin: 0 auto 12px
    &__image
      max-height: 120px
      max-width: 120px
      margin: 0 auto
    &__titolo
      +typoRegularText
      color: $black
      cursor: pointer
      &:hover
        text-decoration: underline
