
.prenotata__modal
  display: flex
  flex-direction: column
  max-width: 60%
  justify-items: center
  align-content: center
  &__main
    h3
      padding-top: 40px
      margin-bottom: 20px
      font-size: 22px

    p
      margin-bottom: 20px
      text-align: center
  &__content
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    margin: 15px 0px 40px 0px
    label
      color: $grey-80
      font-weight: bold
  &__codice
    margin-bottom: 5px
    font-weight: bold
    margin-top: 10px
  &__actions
    min-width: 100%
    justify-content: center
    align-items: center
    display: flex
    padding-bottom: 40px
