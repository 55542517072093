
.ordine-veloce
  display: flex
  flex-direction: column
  font-family: Open Sans,sans-serif
  font-size: 16px

  &__container
    +res-lg-min
      display: flex
      > *
        &:not(:last-child)
          margin-right: 40px

  &__main
    +res-lg-min
      flex: 1

  &__upload
    +res-lg-min
      width: 400px
    +res-md-max
      margin-top: 40px

  &__list
    > *
      +res-sm-min
        &:not(:first-child)
          .formfield__label
            display: none

  &__cta-box
    margin-top: 32px
    +res-sm-min
      display: flex
      > *
        min-width: 200px
        &:not(:last-child)
          margin-right: 16px
    +res-xs-max
      > *
        margin: 0 auto 16px
