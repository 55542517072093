
.button
  min-height: 48px
  font-size: 14px
  line-height: 1.5
  letter-spacing: 0.2px
  text-align: center
  font-weight: bold
  padding: 10px 28px 10px 28px
  display: flex
  order: 1
  justify-content: center
  align-items: center
  &:hover:not(:disabled)
    cursor: pointer
  &__primary
    background-color: $accent
    color: white
    &:hover:not(:disabled)
      background-color: $fill-accent-20
  &__secondary
    background-color: none
    border: solid 2px $accent
    color: $accent
    &:hover:not(:disabled)
      background-color: $accent
      color: white
    &.button--black
      color: black
      border: solid 2px black
      background-color: none
      &:hover:not(:disabled)
        color: white
        background-color: black
  &__ghost
    padding: 0px
    border: none
    color: $accent
    &:hover:not(:disabled)
      text-decoration: underline
    &.button--black
      color: black
    &.button
      &__small, &__medium, &__large // Override senza important
        padding-left: 0
        padding-right: 0
        min-width: 0
        height: auto
  &__small
    +typoSmallButton
    max-width: fit-content
    min-width: 81px
    min-height: 32px
    padding: 8px 24px 8px 24px
    .button
      &__icon
        svg
          width: 16px
          height: 16px
  &__medium
    +typoMediumButton
    max-width: fit-content
    min-width: 115px
    min-height: 40px
    padding: 10px 28px 10px 28px
    .button
      &__icon
        svg
          width: 20px
          height: 20px
  &__large
    +typoLargeButton
    min-width: 110px
    min-height: 48px
    padding: 12px 32px 12px 32px
    .button
      &__icon
        svg
          width: 24px
          height: 24px
    &--responsive // lg => md in responsive
      +res-sm-max
        +typoMediumButton
        min-height: 40px
        padding: 10px 28px 10px 28px
        .button
          &__icon
            svg
              width: 20px
              height: 20px

  &__link
    padding: 0px
    text-decoration: underline
    font-size: 12px
    max-width: fit-content
    min-width: 81px
  &__content
    display: flex
    align-items: center
    justify-content: center
  &__icon
    display: flex
    &--left
      order: 0
      margin-right: 16px
    &--right
      order: 2
      margin-left: 16px
    svg
      fill: currentColor
      // Dimensioni di default - se possibile vengono sovrascritte dalla size
      width: 20px
      height: 20px

  &:disabled
    &.button
      &__primary
        background-color: $grey-20
      &__secondary
        border-color: $grey-20
        color: $grey-20
        &.button--black
          background-color: $grey-20
      &__ghost
        color: $grey-20
        &.button--black
          color: $grey-20

  &__no-pointer
    cursor: default !important
