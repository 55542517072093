
.cart__popup
  width: 100%
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  h3
    margin-top: 25px
    margin-bottom: 25px
  p
    max-width: 80%
  &__actions
    display: flex
    width: 100%
    justify-content: center
    align-items: center
    margin-top: 50px
  &__icon
    display: block
    margin: 0 auto 32px
    width: 40px
    height: 40px
    +color-icon($black)
