@import ../../../styles/sass/commons
    
.catalogo-personalizzato
  background: $fill-neutrals-grey-0
  padding: 64px 0

  &__cols
    +res-lg-min
      display: flex
      align-items: center
      +custom-row(60px)
      > *
        width: 50%

  &__content
    +typoRegularText
    p
      margin-bottom: 4px
    +res-lg-min
      order: -1

  &__title
    font-weight: bold
    margin-bottom: 24px

  &__cta
    margin-top: 40px

  &__image-content
    +res-lg-min
      text-align: right
    +res-md-max
      text-align: center
      margin-bottom: 40px

  &__image-wrap
    position: relative
    display: inline-block
    padding: 0 0 70px 30px

  &__image-caption
    position: absolute
    left: 0
    bottom: 0
    width: 80%
    background: $grey-20
    padding: 32px 40px
    +typoLargeText
    text-align: left
    +res-md-max
      +typoRegularText
      padding: 16px 24px