
.thank-you-page
  text-align: center
  padding: 100px 0
  &__content
    max-width: 520px
    margin-left: auto
    margin-right: auto
  &__title
    +typoH3
    margin-bottom: 32px
  &__text
    +typoRegularText
    p
      margin-bottom: 8px
  &__buttons
    margin-top: 32px
    display: flex
    align-items: center
    justify-content: center
    > *
      &:not(:last-child)
        margin-right: 24px
