
.login_template_service_gift
  background-color: $fill-neutrals-grey-0

  .login_container
    margin-left: auto
    margin-right: auto
    max-width: 870px

  .login
    +typoRegularText   
    padding-top: 40px
    padding-bottom: 100px
    +res-sm-min
      display: flex
      justify-content: center
      flex-wrap: wrap
      > *
        &:not(:last-child)
          border-right: none !important
    
    +res-xs-max
      > *
        &:not(:last-child)
          margin-bottom: 32px

    &__content
      display: flex
      flex-direction: column

      &:not(:last-child)
        margin-right: 42px

      +res-xs-max
        &:not(:last-child)
          margin-right: 0px
          border-bottom: none
      
      .login__content
        padding: 0

      ul
        margin-top: 16px
      .formfield
        margin-top: 8px
        margin-bottom: 8px

    &__sub-content
      display: flex
      flex-direction: column
      justify-content: flex-end
      background-color: $white
      max-width: 414px
      padding: 24px 32px

      &:not(:last-child)
        margin-bottom: 42px
        +res-xs-max
          margin-bottom: 32px

    &__description
      margin-bottom: auto
      ul
        +styled-list

    &__button
      width: 100%
      max-width: none
      text-align: center
      margin-top: 24px

    &__title
      margin-bottom: 24px
      +typoLargeText
      font-weight: bold

    &__social-error
      margin-top: 24px
      +typoRegularText
      color: red

    &__password-recovery
      display: inline-block
      text-decoration: underline
      margin-top: 16px

    &__social
      &__content
        max-width: 870px
        margin-top: 40px
        padding: 40px 0px
        border-top: solid 2px $grey-20
        border-bottom: solid 2px $grey-20
        text-align: center

      &__buttons
        display: flex
        justify-content: center
        margin-top: 15px
        +res-sm-max
          flex-direction: column
          align-items: center
        > *
          padding: 10px 16px
          width: 100%
          justify-content: flex-start
          &:not(:last-child)
            margin-right: 32px
          +res-sm-max
            &:not(:last-child)
              margin-bottom: 24px
              margin-right: 0px


.page--service_gift
  ~ .page__bottom
    margin-top: 0 !important

.page--service_gift
  padding-bottom: 64px
  +res-sm-max
    padding-bottom: 40px