
.login_template 
  .login
    padding-bottom: 100px
    padding-top: 50px
    +typoRegularText
    +res-sm-min
      display: flex
      > *
        flex: 1 0 0
        padding: 0 5%
        &:not(:last-child)
          border-right: solid 2px $grey-20

    +res-xs-max
      > *
        &:not(:last-child)
          padding-bottom: 32px
          margin-bottom: 32px
          border-bottom: solid 2px $grey-20

    &__content
      ul
        margin-top: 16px

      .formfield
        margin-bottom: 16px

    &__title
      margin-bottom: 24px
      +typoLargeText
      font-weight: bold

    &__description
      margin-bottom: 16px
      ul
        +styled-list

    &__password-recovery
      display: inline-block
      text-decoration: underline
      margin-top: 16px
      margin-bottom: 32px

    &__button
      display: inline-block
      margin-top: 32px
