// Struttura pagina generica
.page
  padding-top: 24px
  &__back
  &__title
    +typoH2
    margin-bottom: 24px
    +res-sm-max
      +typoH4
      margin-bottom: 16px

  &__bottom
    position: relative // Per ScrollTop
    margin-top: 64px
    +res-sm-max
      margin-top: 40px

.cataloghi-download-container
  display: flex
  flex-direction: row
  flex-wrap: wrap

.container_opzioni_immagini
  display: flex
  flex-direction: row
  button
    margin-right: 15px
  @media screen and ( max-width: 545px )
    flex-direction: column
    button
      margin-right: 0px
      margin-bottom: 15px

.immagini_select_container
  margin-top: 25px

.scarica_immagini
  display: flex
  flex-direction: row
  align-items: center
  margin: 25px 0px
  p
    font-size: 14px
    font-weight: bold
  a
    display: flex
    flex-direction: row
    align-items: center
    font-size: 14px
    margin-right: 15px
  @media screen and ( max-width: 545px )
    flex-direction: column
    a
      margin-right: 0px
      margin-bottom: 15px
  &__item
    cursor: pointer
    &--disabled
      cursor: auto

.promozioni-download-container
  display: flex
  flex-direction: row
  flex-wrap: wrap
  justify-content: flex-start

.ordine-veloce
  display: flex
  flex-direction: flex
  min-height: 50vh
  justify-content: space-between
  @media screen and ( max-width: 545px )
    flex-direction: column
    justify-items: center
    align-content: center
    div
      margin-right: 0px
      margin-bottom: 5px
  &__riga
    width: 80%
    display: flex
    flex-direction: row
    align-items: center
    margin: 15px 0px
    @media screen and ( max-width: 545px )
      width: 100%
      margin: 0px

.file-container
  width: 50%
  height: 350px
  background-color: #f8f9fa
  margin-top: 20px
  @media screen and ( max-width: 545px )
    display: none
  &__content
    padding: 20px
    display: flex
    flex-direction: column
    p
      padding-bottom: 20px
      font-size: 14px
    a
      margin-bottom: 40px
      font-size: 14px
      text-decoration: underline
    h1
      padding-bottom: 20px
    .error
      color: red
      font-size: 14px
      margin-top: 5px
      font-weight: bold

.upload-excel
  &__cta
    display: flex
    flex-direction: row
    align-items: center
    &__bin
      margin-left: 10px
