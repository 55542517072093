
.about-top-box
  margin-bottom: 50px
  +res-md-min
    display: flex
    align-items: flex-start
    > *
      flex: 1 0 0
      &:not(:last-child)
        margin-right: 30px
  &__content
    padding: 20px 0
    +typoRegularText
    ul
      +styled-list
    +res-md-min
      width: 40%

  &__cta
    display: inline-block
    margin-top: 8px

  &__image-box
    position: relative
    padding: 0 0 70px 90px
    +res-md-min
      width: 60%
    +res-sm-max
      margin-top: 32px
      padding: 0 0 0 20px

  &__image
    display: block
    margin-left: auto
    position: relative
    z-index: -1

  &__caption
    padding: 30px 35px
    background: $fill-neutrals-grey-0
    +res-md-min
      position: absolute
      left: 0
      bottom: 0
      max-width: 80%
      +typoRegularText
    +res-sm-max
      margin-top: -50px
      margin-left: -20px
      max-width: 100%
      +typoSmallText

.about-categories
  margin-bottom: 50px
  &__title
    +typoH5
    margin-bottom: 40px
    text-align: center
  &__cta-box
    text-align: center
    margin-top: 24px
  &__cta
    display: inline-block
  &__slider
    .swiper-container
      padding-top: 1px // Per box-shadow
      padding-left: 20px
      padding-right: 20px
    .swiper-slide
      +box-shadow(0 0 0 1px $grey-20)
      +border-radius(5px)
      height: auto
    .swiper-button-prev, .swiper-container-rtl .swiper-button-next
      left: 0
    .swiper-button-next, .swiper-container-rtl .swiper-button-prev
      right: 0

.about-categories-slide
  padding: 24px 16px
  &__image-box
    width: 60px
    height: 60px
    +border-radius(50%)
    overflow: hidden
    margin-bottom: 24px
  &__image
    display: block
    max-width: 100%
  &__title
    +typoRegularText
    font-weight: 700
    margin-bottom: 16px
  &__description
    +typoRegularText

.about-brands
  margin-bottom: 50px
  &__title
    +typoH5
    margin-bottom: 40px
    text-align: center
  &__list
    +res-md-min
      display: flex
      > *
        flex: 1 0 0
        &:not(:last-child)
          margin-right: 30px
    +res-sm-max
      > *
        max-width: 500px
        margin: 0 auto
        &:not(:last-child)
          margin-bottom: 30px

.about-importatori
  background: $fill-neutrals-grey-0
  padding: 50px 0 150px
  &__title
    +typoH5
    margin-bottom: 16px
    text-align: center
  &__description
    +typoRegularText
    text-align: center
    margin-bottom: 40px
  &__row
    +res-lg-min
      display: flex
      > *
        flex: 1 0 0

  &__content
    +res-md-min
      display: flex
      flex-wrap: wrap
      > *
        width: 50%
  &__card
    padding: 25px 30px 25px 0
    +res-sm-max
      padding-right: 0
  &__card-title
    +typoRegularText
    font-weight: 700
    margin-bottom: 16px
  &__card-description
    +typoRegularText

  &__images-box
    > *
      position: relative
      display: block
      margin-right: -140px
      @media screen and (max-width: 1528px) // container + 140*2 - per non sforare
        margin-right: 0
      &:first-child
        z-index: 1
        max-width: 75%
        margin-left: auto
      &:last-child
        z-index: 2
        margin-top: -80px
        max-width: 65%
    +res-md-max
      max-width: 600px
      margin: 24px auto 0

.about-partnership
  margin-top: -70px
  margin-bottom: 50px
  padding: 45px 55px
  background: $white
  +box-shadow(0px 8px 16px 0px rgba(24, 50, 115, 0.08))
  +res-sm-max
    padding: 32px 24px
  &__title
    +typoH5
    margin-bottom: 16px
  &__row
    +res-lg-min
      display: flex
  &__content
    +res-lg-min
      width: 40%
    +res-md-max
      margin-bottom: 24px
  &__description
    +typoRegularText
  &__cta-box
    text-align: center
    +res-lg-min
      display: none
  &__cta
    display: inline-block
    margin-top: 32px
    &--desktop
      +res-md-max
        display: none
    &--mobile
      +res-lg-min
        display: none
      +res-sm-max
        margin-top: 24px
  &__vantaggi
    +typoSmallText
    +res-lg-min
      width: 60%
      display: flex
      flex-wrap: wrap
      > *
        width: 50%
  &__vantaggi-item
    padding-left: 50px
    position: relative
    margin-bottom: 24px
    +res-sm-max
      padding-left: 24px
    &::before
      position: absolute
      left: 30px
      top: 8px
      content: ""
      width: 4px
      height: 4px
      background: $black
      +border-radius(50%)
      +res-sm-max
        left: 10px

.about-storia
  margin-bottom: 50px
  &__title
    +typoH5
    text-align: center
    margin-bottom: 40px

.about-storia-slide, .about-certificazioni-slide
  padding: 0 40px
  +res-lg-min
    display: flex
    > *
      flex: 1 0 0
      &:not(:last-child)
        margin-right: 90px
  &__content
    +typoRegularText
    padding: 16px 0 16px 100px
    +res-md-max
      padding-left: 0
    +res-sm-max
      +typoSmallText
  &__year
    font-weight: 700
    margin-bottom: 16px
  &__title
    font-weight: 700
    margin-bottom: 8px
  &__image
    display: block
    margin: 0 auto
    +res-md-max
      margin-top: 32px

.about-ruoli
  margin-bottom: 50px
  +res-lg-min
    padding-left: 90px
  &__container
    background: $fill-neutrals-grey-0
    +res-md-min
      display: flex
      align-items: center
      padding: 45px 100px 45px 0
      > *
        flex: 1 0 0
    +res-sm-max
      padding: 45px 16px
      margin-left: -16px
      margin-right: -16px
  &__image-box
    +res-md-min
      padding-right: 30px
  &__image
    display: block
    +res-lg-min
      margin-left: -90px
    +res-md-only
      margin-left: -16px
    +res-sm-max
      margin: 0 auto 32px
  &__title
    +typoH5
    margin-bottom: 16px
  &__description
    +typoRegularText
    ul
      +styled-list

.about-certificazioni
  margin-bottom: 50px
  &__title
    +typoH5
    margin-bottom: 40px
    text-align: center

/*
 h1
 font-size: 38px
 font-weight: bold
 margin: 30px 0

  &__header
    display: flex
    flex-direction: row
    font-size: 14px
    margin: 30px 0

    &__desc
      width: 50%
      justify-content: space-between

    &__img
      display: flex
      flex-direction: column
      align-items: center
      width: 50%

      img
        width: 430px
        height: 260px
        background-color: white

        p
          background: rgb(225,233,240)
          position: relative
          right: 10%
          padding: 26px
          bottom: 10%

    @media screen and ( max-width: 568px )
      width: 100%
      margin: 20px 30px

      p
        bottom: 45px
        right: 8%

    &__categories
      display: flex
      flex-direction: column
      align-items: center

    &__brands
      display: flex
      flex-direction: row
      justify-content: space-between
      width: 90%
      padding: 5% 0

      @media screen and ( max-width: 568px )
        flex-direction: column

        &__card
          display: flex
          flex-direction: column
          align-items: center
          justify-content: center
          line-height: 3
          width: 500px
          height: 300px
          background: rgb(225,233,240)

    &__importatori
      display: flex
      flex-direction: column
      align-items: center

      &__cards
        display: flex
        flex-direction: row
        margin-top: 5%

        @media screen and ( max-width: 568px )
          flex-direction: column
          align-items: center

          &__leftPanel
            display: flex
            flex-direction: row
            flex-wrap: wrap
            width: 50%

            @media screen and ( max-width: 568px )
              width: 100%
              align-items: center

              &__card
                flex-grow: 2
                width: 50%
                padding: 15px

                &__titolo
                  font-weight: bold

            &__rightPanel
              width: 50%

              @media screen and ( max-width: 568px )
                width: 100%

                &__firstImg
                  width: 500px
                  height: 300px
                  background-color: rgb(225,233,240)
                  position: relative
                  left: 20%

                &__secondImg
                  width: 400px
                  height: 200px
                  background-color: rgb(225,233,240)
                  position: relative
                  left: 5%
                  bottom: 85px

    &__storia
      display: flex
      flex-direction: column
      align-items: center

      &__content
        display: flex
        flex-direction: row
        justify-content: space-between
        padding: 5%

        @media screen and ( max-width: 568px )
          flex-direction: column

          &__description
            display: flex
            flex-direction: column
            width: 50%
            span
              font-weight: bold

              @media screen and ( max-width: 568px )
                width: 100%

            &__img
              width: 500px
              height: 300px
              background-color: rgb(225,233,240)

    &__ruoli
      display: flex
      flex-direction: row
      align-items: center
      background: rgb(225,233,240)

      @media screen and ( max-width: 568px )
        flex-direction: column
        width: 250px

        &__img
          width: 450px
          height: 300px
          background-color: $grey-20
          position: relative
          right: 5%

          @media screen and ( max-width: 568px )
            width: 270px

        &__description
          display: flex
          flex-direction: column
          padding: 7%

    &__certificazioni
      display: flex
      flex-direction: column
      align-items: center
      margin: 5% 0

      &__content
        display: flex
        flex-direction: row
        padding: 5%
        justify-content: space-between

        @media screen and ( max-width: 568px )
          flex-direction: column

          p
            width: 55%

            @media screen and ( max-width: 568px )
              width: 100%

            img
              max-width: 113px
              max-height: 88px
              position: relative
              right: 5%

              @media screen and ( max-width: 568px )
                left: 40px
                margin: 15px

                */
