
//ho inserito direttamente 310px in width in attesa dell'ui completa del componente
.accordion
  padding: 3px 0
  &__container
    display: flex
    justify-content: space-between
    align-items: center
    cursor: pointer
    padding: 8px 0

  &__placeholder
    +typoRegularText
    font-weight: 700
    colore: $black

  &__arrow
    svg
      display: block
      width: 20px
      height: 20px
      +color-icon($black)

  &__children
    // Nessuno stile, di default può esserci qualunque cosa qui 

  /* VARIANTI */

  // placeholderEllipses
  &--placeholder-ellipses
    .accordion
      &__container
        width: 100%
      &__placeholder
        white-space: nowrap
        text-overflow: ellipsis
        overflow: hidden

  // size
  &--extralarge // aumenta il padding esterno, non il container
    padding: 8px 0

  &--large // default

  &--medium
    .accordion
      &__container
        padding: 6px 0
      &__placeholder
        +typoSmallText
        font-weight: 700

  &--small
    padding: 0
    +res-sm-max
      padding: 4px 0
    .accordion
      &__container
        padding: 6px 0
      &__placeholder
        +typoTinyText
        +res-sm-max
          +typoSmallText

  // !withoutBorders
  &--with-border
    border-top: solid 1px $grey-20
    border-bottom: solid 1px $grey-20
