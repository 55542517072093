
.tableschedaprodotto
  &__buttons
    display: flex
    justify-content: flex-end
    align-items: center
    margin-top: 24px
    gap: 20px
    @media screen and ( max-width: 450px )
      min-width: 100%
      display: flex
      flex-direction: column
      justify-content: center

.quantitainsufficiente
  margin-top: 56px
  &__label
    +typoCaption
    color: $grey-80
  &__value
    font-weight: 700
