
.catalogo__banner
  display: flex
  flex-direction: column
  width: 100%
  padding: 16px
  background-color: white
  position: relative
  background-color: #5cabe4 // Default
  &__content
    display: flex
    flex-direction: column
    position: relative
    justify-content: center
    width: 100%
    padding: 16px
    border: solid 2px $grey-20
    strong
      font-size: 20px
      line-height: 1.2
      padding-top: 20px
    p
      max-width: 80%
      font-size: 16px
      line-height: 1.5
      margin-top: 16px
      margin-bottom: 40px
      word-break: break-word
    &__button
      padding-bottom: 20px
      z-index: 11
    &__img
      max-width: 45%
      position: absolute
      right: 0px
      z-index: 10
      @media screen and ( max-width: 568px )
        position: relative
        align-self: center
        padding-top: 20px
  &--color-1
    background-color: #5cabe4
  &--color-2
    background-color: #dfc944
