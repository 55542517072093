.brand-listing-header
  //margin-top: -90px // problema con titolo del brand troppo lungo
  &__carousel
    margin-bottom: 20px
    &__item
      display: block
      position: relative
      width: 100%
      height: 240px
      overflow: hidden
      text-align: center
      +res-sm-max
        height: 214px
      span
        height: 240px !important
        +res-sm-max
          height: 214px !important
      img
        object-fit: cover
  &__description
    +typoSmallText
    margin-bottom: 30px

