.totale-costi
  &__dati
    +typoSmallText
    padding: 6px 0
    margin-bottom: 8px
    &__value
      +typoSmallText
      padding: 6px 0
      display: flex
      justify-content: flex-end
  &__dati-detail, &__dati-detail__value
    +typoSmallText
    padding: 6px 0
    margin-bottom: 8px
    color: $grey-80
  &__dati-detail
    padding-left: 16px
    &__value
      text-align: right

  .lineSeparator
    margin-bottom: 40px
    margin-top: 40px
    border-bottom: solid 1px $grey-20
    &--sm
      margin-bottom: 20px
      margin-top: 20px