
.team_container
  font-family: 'Open sans',sans-serif
  font-size: 16px
  line-height: 2
  box-sizing: border-box
  align-self: flex-start
  &__card
    display: flex
    flex-direction: column
    @media screen and ( max-width: 568px )
      width: 270px
      &__image
        margin: 15px 0
      &__description
        margin: 15px 0
        p
          min-height: 70px
      &__contact
        display: flex
        flex-direction: column
        &__container
          display: flex
          flex-direction: row
          justify-content: space-between
          width: 71%
          @media screen and ( max-width: 568px )
            width: 100%
          &__titolo
            color: $grey-80
