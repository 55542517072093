
.accordion-bolla
  display: flex
  justify-content: space-between
  padding: 0 44px 24px 8px
  +typoTinyText
  color: $grey-80
  &__note
    width: 50%
    +res-sm-max
      order: 2
      width: 100%
  &__info
    width: 30%
    +res-sm-max
      width: 100%
    &__row
      display: flex
      justify-content: space-between
      margin-bottom: 4px
      +res-sm-max
        justify-content: space-between

.datatable-bolle
  &__icon
    cursor: pointer
    svg
      display: block
      width: 24px
      height: 24px
      +color-icon(currentColor)

  &__detail-trigger
    display: block
    width: 20px
    height: 20px
    +color-icon($black)
    cursor: pointer

.modal-bolla
  &__alerts
    margin-bottom: 24px
    > *
      max-width: 100% !important
  &__form
    margin-top: 24px
    display: flex
    justify-content: center
    align-items: center
    flex-direction: column
    &__field
      width: 50%
      +res-sm-max
        width: 100%
    &__submit
      margin-top: 40px
      display: flex
      justify-content: center
