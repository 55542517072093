
$color-bg: #FFFFFF
$font-bold: 700
$color-bg-current: #EBE1D3

.myaccount
  position: relative

  &__container
    margin-top: 24px
  &__back
    margin-bottom: 24px
    display: block
  &__titolo
    +typoH5
    margin-bottom: 24px
  &__sottotitolo
    +typoRegularText
    font-weight: 700
    margin-bottom: 16px
  &__side
    +res-sm-max
      padding-bottom: 32px
  &__block-cta-container
    display: flex
  &__filtri
    display: flex
    flex-wrap: wrap
    gap: 1.5rem
    padding-bottom: 2.2rem
  &__empty
    +typoRegularText
    padding: 30px 0
  &__alert
    margin-bottom: 24px

.dati
  &__label
    padding-top: 3.5rem
    font-size: 1.2rem
    opacity: .6
  &__value
    padding-top: 3.5rem
    font-size: 1.2rem

.container_cards
  display: flex
  flex-wrap: wrap
  padding-bottom: 1.5rem
  gap: 20px

.container-documenti-cards
  display: flex
  flex-wrap: wrap
  padding-bottom: 1.5rem
  gap: 20px
  >*
    width: calc( 33.33% - 13.33px )

.categorie-news
  &__container
    display: flex
    flex-wrap: wrap
    padding-bottom: 1.5rem
    gap: 20px
