
.download-database
  &__item
    padding: 14px
    border-radius: 6px
    background: #E0E8EF
    border: 1px solid #E0E8EF
    display: flex
    flex-direction: row
    justify-content: space-between
    min-width: 220px
    min-height: 80px
    align-items: center
    flex-wrap: wrap
    margin-top: 10px
    margin-right: 20px
    cursor: pointer
    +res-sm-max
      width: 100%
      margin-right: 0px
    &--disabled
      background-color: $fill-neutrals-grey-0 // $grey-20 
      cursor: auto
    p
      margin-right: 14px
    &__icon
      margin-right: 12px
      svg
        display: block
        width: 24px
        height: 24px

  &__wrapper
    margin: 20px 0 40px
    display: flex
    flex-direction: row
    align-items: center
    flex-wrap: wrap
    p
      font-size: 14px

  &__format
    width: 220px
    +res-sm-max
      width: 100%

  &__listini
    &-disclaimer
      margin: 8px 0px
      font-size: 16px
      line-height: 24px
      font-weight: 400
  
  &__alternative
    margin-top: 40px
    font-size: 16px
    line-height: 24px
    font-weight: 400
    a
      color: $accent
      text-decoration: underline