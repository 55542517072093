
.cart-note-modal
  &__content
    text-align: left
    max-width: 580px
    margin-left: auto
    margin-right: auto
    +res-md-min
      padding: 32px 0
    .formfield
      max-width: none
