
.overlay-side-cart
  z-index: 2111105
  position: fixed
  top: 0
  left: 0
  right: 0
  bottom: 0
  display: none
  background: rgba(#000, .3)
  &--open
    display: block
  +res-sm-max
    display: none

.side-cart
  z-index: 2111106
  position: fixed
  top: 0
  bottom: 0
  right: 0
  width: 412px
  +translateX(100%)
  +transition(transform .5s)
  display: flex
  flex-direction: column
  padding: 24px
  background: #fff
  overflow-y: auto
  +scrollbar-style
  +res-sm-max
    display: none
  &--open
    +translateX(0)
  &__head
    margin-bottom: 32px
  &__body
    padding-bottom: 16px
  &__footer
    margin-top: auto
    border-top: 1px solid $grey-20
    padding-top: 16px

  &__title
    +typoH5
  &__close
    position: absolute
    top: 16px
    right: 16px
    cursor: pointer
    svg
      width: 24px
      height: 24px

  &__items
    > *:not(:last-child)
      margin-bottom: 32px
  &__section
    margin-bottom: 32px
  &__section-title
    +typoRegularText
    font-weight: bold
    margin-bottom: 16px

  &__footer-row
    display: flex
    justify-content: space-between
    +typoTinyText
    color: $grey-80
    &:not(:last-child)
      margin-bottom: 8px
    &--totale
      +typoSmallText
      font-weight: bold
      color: $black
    &--buttons

      margin-top: 16px
  &__button
    width: calc(50% - 15px)
    max-width: none
