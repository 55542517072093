
.accountcard
  display: grid
  grid-template-columns: repeat(1,auto)
  grid-gap: 8px
  align-content: center
  justify-items: start
  background-color: white
  padding: 16px
  max-width: 255px
  max-height: fit-content
  min-height: 168px
  border: solid 2px $grey-10
  border-radius: 4px
  transition: 0.2s cubic-bezier(.17,.67,.83,.67)
  @media screen and ( max-width: 415px )
    max-width: 150px
    max-height: 176px
  &:hover
    box-shadow: 0 8px 16px 0 rgba(24, 50, 115, 0.08)
    cursor: pointer
  &__title
    font-size: 16px
    font-weight: bold
    line-height: 1.5
    @media screen and ( max-width: 415px )
      font-size: 14px
  &__subtitle
    font-size: 14px
    line-height: 1.43
    @media screen and ( max-width: 415px )
      font-size: 12px
  &__desc
    font-size: 14px
    line-height: 1.43
    color: $grey-80
    margin-top: 8px
    @media screen and ( max-width: 415px )
      font-size: 12px
  &__icon
    svg
      display: block
      width: 24px
      height: 24px
      +color-icon(currentColor)

.accountcards__wrapper
  display: grid
  grid-gap: 16px
  grid-template-columns: repeat(4,auto)
  @media screen and ( max-width: 768px )
    grid-template-columns: repeat(2,auto)
