
.info__quantita
  margin-bottom: 16px

  &__head
    margin-bottom: 20px
    +res-sm-max
      margin-bottom: 26px

  &__title
    +typoH5
    margin-bottom: 8px
    +res-sm-max
      margin-bottom: 14px

  &__label
    +typoSmallText
    color: $grey-80

  &__wrapper
    +typoSmallText
    margin-bottom: 28px
    +res-sm-max
      margin-bottom: 24px
    p
      &:not(:last-child)
        margin-bottom: 4px

  &__multipli-alert
    margin-bottom: 24px

  // In versione desktop le info sono in riga - Parte della modale di ModificaArticoli
  &__row
    +res-md-min
      display: flex
      > *
        &:not(:last-child)
          margin-right: 16px
  &__col
    +res-sm-max
      margin-bottom: 8px
    +res-md-min
      flex: 1
      text-align: right
      &--main
        text-align: left
        flex-grow: 4

.info__imballi
  +res-md-min
    display: flex
    flex-wrap: wrap
    align-items: center
    > *
      margin-bottom: 8px
      &:not(:last-child)
        padding-right: 24px
        border-right: 1px solid $grey-20
        margin-right: 20px

  &__quantita
    +res-sm-max
      margin-bottom: 8px
    > *
      vertical-align: middle
      > *
        vertical-align: middle
      +res-md-min
        display: inline
        +typoRegularText
        &:not(:first-child)
          &::before
            +typoRegularText
            content: " / "
            vertical-align: middle
          .info__imballi__quantita-icon
            display: none
      +res-sm-max
        display: block
        +typoSmallText
        margin-bottom: 4px

    &-icon
      display: inline-block
      width: 24px
      height: 24px
      +color-icon($black)
      margin-right: 12px
      +res-sm-max
        width: 20px
        height: 20px

  &__referenza
    +typoSmallText
    +res-md-min
      display: none

  &__attributes
    display: flex
    aling-items: center
    > *
      &:not(:last-child)
        margin-right: 32px
        +res-sm-max
          margin-right: 24px

  &__attribute
    +typoCaption
    color: $grey-80
    white-space: nowrap
    +res-sm-max
      +typoCaptionSmall
    &-value
      color: $black

.fasce
  padding-bottom: 24px
  margin-bottom: 24px
  border-bottom: 1px solid $grey-20

  &__cell
    +typoSmallText

  &__cell-title
    +typoCaptionSmall
    color: $grey-80

  &__multipli
    +typoTinyText
    color: $grey-80
    margin-top: 4px

  +res-md-min
    &__table
      display: flex
      +custom-row(16px)
      > *
        flex: 1
    &__cell
      &:not(:last-child)
        margin-bottom: 8px

  +res-sm-max
    &__col
      display: flex
      align-items: flex-start
      justify-content: space-between
      margin-bottom: 8px
      > *
        max-width: 50%
        &:last-child
          text-align: right
