.image__position
  font-family: 'Open Sans',sans-serif
  position: relative
  width: fit-content
  &:hover
    cursor: pointer
  &:hover .icon_container
    visibility: visible
    opacity: 1

.icon_container
  cursor: pointer
  text-align: center
  visibility: hidden
  width: fit-content
  padding: 4px 8px
  background-color: black
  color: white
  border-radius: 4px
  position: absolute
  bottom: -25px
  opacity: 0
  z-index: 1
  transition: 0.2s cubic-bezier(.17,.67,.83,.67)
  &__arrow_up
    border-right: 6px solid transparent
    border-left: 6px solid transparent
    border-bottom: 10px solid black
    position: absolute
    top: -5px
    left: 5px
    visibility: visible
    z-index: 99