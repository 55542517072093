
.service-gift-card
  max-width: 360px
  border: 1px solid $grey-20
  padding: 48px 24px
  +border-radius(5px)
  +typoRegularText
  &__title
    font-weight: bold
    margin-bottom: 16px
  &__cta
    margin-top: 32px
  +res-sm-max
    margin-bottom: 40px
