
.formfield
  &__label
    display: block
    color: $grey-80
  &__input-wrapper
    position: relative
  &__icon
    position: absolute
    display: none
    z-index: 1
  &__error-icon, &__success-icon
    position: absolute
    display: none
    z-index: 2
    svg
      display: block
  &__error-icon
    svg
      +color-icon($error)
  &__success-icon
    svg
      +color-icon($success)
  &__input
    font-family: $font-primary
    border: 2px solid $grey-50
    background: $white
    color: $black
    width: 100%
    outline: none
    &:focus
      border-color: $black
    &:disabled
      color: $grey-20
      border-color: $grey-20

  &__error
    +typoSmallText
    color: $error
    margin-top: 8px

  &__info
    +typoSmallText
    margin-top: 8px

  &--success
    .formfield
      &__success-icon
        display: block

  &--error
    .formfield
      &__input
        border-color: $error
      &__error-icon
        display: block

  &--error, &--success
    // nascondo l'icona a destra per rimpiazzarla con quella di errore/successo
    &.formfield--right-icon
      .formfield
        &__icon
          display: none

  &:not(.formfield--lg)
    .formfield
      &__label
        +typoCaptionSmall
        margin-bottom: 4px
      &__input
        +typoSmallText
        padding: 8px 10px
      &__icon
        top: 10px
      &__error-icon, &__success-icon
        top: 10px
        right: 12px
        svg
          width: 20px
          height: 20px
    &.formfield--error, &.formfield--success
      .formfield
        &__input
          padding-right: 38px

  &--lg
    .formfield
      &__label
        +typoCaption
        margin-bottom: 12px
      &__input
        +typoRegularText
        padding: 10px 14px
      &__icon
        top: 12px
      &__error-icon, &__success-icon
        top: 12px
        right: 16px
        svg
          width: 24px
          height: 24px
    &.formfield--error, &.formfield--success
      .formfield
        &__input
          padding-right: 50px

  &--left-icon, &--right-icon
    .formfield__icon
      display: block

  &--left-icon
    &:not(.formfield--lg) .formfield
      &__icon
        left: 12px
      &__input
        padding-left: 38px
    &.formfield--lg .formfield
      &__icon
        left: 16px
      &__input
        padding-left: 50px

  &--right-icon
    &:not(.formfield--lg) .formfield
      &__icon
        right: 12px
      &__input
        padding-right: 38px
    &.formfield--lg .formfield
      &__icon
        right: 16px
      &__input
        padding-right: 50px
