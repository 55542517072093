
.catalogo-brand-home
  position: relative
  padding: 16px
  display: block
  &__container
    position: relative
    height: 250px
  +res-sm-max
    padding: 8px
  &__content
    position: relative
    display: flex
    justify-content: space-between
    width: 100%
    margin-top: 14px
    +res-sm-max
      height: 100%
  &__image
    align-self: flex-start
  &__cta
    align-self: flex-end
    min-width: 48px
    height: 48px
    padding: 0
    margin-top: 0
    .button__icon.button__icon--right
      margin-left: 0px
