$height-desktop: 456px
$height-mobile: 214px

.carousel
  &__item
    display: block
    position: relative
    width: 100%
    height: $height-desktop
    overflow: hidden
    text-align: center
    +res-sm-max
      height: $height-mobile
    span
      height: $height-desktop !important
      +res-sm-max
        height: $height-mobile !important
    img
      object-fit: cover
