
.prodotto-template
  &__cols
    display: flex
    justify-content: space-between
    margin-top: 92px
    margin-bottom: 64px
    +res-sm-max
      margin-top: 40px
      margin-bottom: 40px
  &__main
    flex: 1
  &__sidebar
    position: relative
    width: 255px
    margin-left: 32px
    .riepilogo-card, .riepilogo-card-carrello
      +res-lg-min
        -webkit-position: sticky
        position: sticky
        // top gestito la layout.sass
    +res-md-max
      width: 0
      margin-left: 0
  &__block
    margin-bottom: 64px
    +res-sm-max
      margin-bottom: 40px
  &__paragraph
    +typoRegularText
    color: $error
    margin-bottom: 0px
    +res-sm-max
      +typoSmallText
      margin-bottom: 5px
