@import "../../../../../styles/sass/commons"

.posizione-stampa

  &__description
    +typoRegularText
    color: $grey-80
    margin-bottom: 24px
    +res-sm-max
      +typoSmallText
      margin-bottom: 16px

  &__options
    margin-bottom: 24px
    +res-md-min
      display: flex
      align-items: center
      > *
        width: 33.33%
    +res-md-only
      flex-wrap: wrap
      > *
        width: 50%
        &:not(:last-child)
          margin-bottom: 8px
    +res-sm-max
      margin-bottom: 16px
      > *
        &:not(:last-child)
          margin-bottom: 8px

  &__option
    border-radius: 4px
    padding: 20px 16px 20px 12px
    +transition(background-color .3s)
    &--active
      background-color: $grey-10

  &__dimensioni
    +typoSmallText
    margin-bottom: 8px
    > *
      margin-bottom: 12px
    +res-md-min
      display: flex
      align-items: center
      > *
        &:not(:last-child)
          margin-right: 96px

  &__contacts
    +typoRegularText
    margin-bottom: 32px
    +res-sm-max
      +typoSmallText
      margin-bottom: 24px
      
  &__link
    text-decoration: underline
    color: $accent
