
.prodotto-search-card
  padding: 8px 0
  &__content
    display: flex
    > *
      &:not(:last-child)
        margin-right: 16px

  &__image-box
    position: relative
    width: 96px
    height: 96px
    flex: 0 0 96px
  &__details
    flex: 1
  &__title
    +typoCaption
    color: $black
    margin-bottom: 4px
  &__nome
    +typoSmallText
    font-weight: bold
    color: $black
    margin-bottom: 4px
  &__description
    +typoRegularText
    color: $black
    +multiline-ellipsis(3, 24px)
    +res-sm-max
      +typoSmallText
      +multiline-ellipsis(3, 20px)
