
.datatable-prodotto
  &__richiedi
    display: flex
    align-items: center
    &-label
      padding: 0 12px
      cursor: pointer
    &-icon
      display: block
      width: 20px
      height: 20px
      +color-icon($black)
      &--info
        +color-icon($accent)
        margin-right: auto

  &__in-opzione
    margin-top: 12px
    &-label
      display: flex
      align-items: center
      > span
        padding-right: 12px
