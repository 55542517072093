.form__personalizzazione__submit
  padding-top: 32px
  display: flex
  justify-content: space-between
  align-items: center
  @media screen and ( max-width: 750px )
    display: grid
    justify-content: normal

.form__personalizzazione__header
  display: grid
  grid-gap: 24px
  padding-bottom: 48px
  h1
    font-size: 20px
    font-weight: bold
    line-height: 1.2
  &__content
    display: flex
    flex-direction: row
    flex-wrap: wrap

.fp-edit-colori, .fp-edit-size, .fp-upload-bozza, .fp-upload-loghi
  margin-bottom: 24px
  margin-top: 24px
  &__title
    font-weight: 700
  &__subtitle
    +typoSmallText
  &__alert
    margin-top: 8px
  &__fieldsets
    display: flex
    flex-wrap: wrap
    +custom-row(16px)
    margin-top: 16px
    > * 
      width: 240px
      max-width: 100%
      margin-bottom: 16px
  &__notes
    +typoSmallText
    margin-top: 16px
  
  .fp-upload-bozza, .fp-upload-loghi
    &__fieldsets
      display: block
      > *
        width: 100%
        &:first-child
          max-width: 300px
    
.fp-bozza-digitale, .fp-logo-caricato
  margin-top: 32px
  margin-bottom: 32px
  &__link
    margin-top: 16px
  &__image
    display: block
    max-height: 96px