
.formcheckbox
  position: relative
  display: block
  padding-left: 32px
  min-height: 24px
  &__input
    position: absolute
    left: -9999px

    &+ .formcheckbox__styledinput
      position: absolute
      top: 4px
      left: 4px
      width: 18px
      height: 18px
      border: 2px solid $black
      +border-radius(3px)
      svg
        display: block
        width: 100%
        height: 100%
        +color-icon(#fff)
    &:checked
      &+ .formcheckbox__styledinput
        background-color: $black

    &:disabled
      &+ .formcheckbox__styledinput
        border-color: $grey-20
      &:checked
        &+ .formcheckbox__styledinput
          background-color: $grey-20

  &__label
    +typoRegularText
    display: block
    color: $black

  &--sm
    .formcheckbox
      &__label
        +typoSmallText
        padding-top: 2px
        padding-bottom: 2px

  &:hover
    .formcheckbox___input:not(:disabled)
      &+ .formcheckbox__styledinput
        border-color: $grey-100
      &:checked
        &+ .formcheckbox__styledinput
          &::after
            background-color: $grey-100

  &:not(.formcheckbox--disabled)
    cursor: pointer

  &--disabled
    .formcheckbox
      &__label
        color: $grey-20

.formfield
  .formcheckbox
    ~ .formfield
      &__icon, &__error-icon, &__success-icon
        display: none!important // Non mostro le icone per i checkbox

  &--error
    .formcheckbox__input:not(:disabled)
      &+ .formcheckbox
        &__styledinput
          border-color: $error
      &:checked
        &+ .formcheckbox__styledinput
          background: $error

  &--sm
    .formcheckbox
      &__label
        +typoSmallText
        padding-top: 2px
        padding-bottom: 2px

  &:not(.formfield--sm)
    .formcheckbox
      &__label
        +typoRegularText
