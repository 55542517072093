
.documento-card
  display: flex
  flex-direction: row
  background-color: white
  border: 1px solid #E0E8EF
  padding: 15px
  border-radius: 5px
  align-items: center

  &__title
    flex: 1
    strong
      font-size: 1.3rem
      line-height: 1.5

  &__icon
    margin-right: 12px
    svg
      display: block
      width: 24px
      height: 24px
      +color-icon(currentColor)
