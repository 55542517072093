@import '../../../styles/sass/commons'

.toolbar
  position: fixed
  top: 0
  left: 0
  width: 100%
  display: flex
  align-items: center
  padding: 0 16px
  // height gestita in layout.sass
  background: #fff
  z-index: 10000
  box-shadow: 0px 8px 16px 0px rgba(24, 50, 115, 0.08)
  // &__user
  //   font-weight: $font-bold
  &__user
    +typoSmallText
  &__right
    display: flex
    align-items: center
    margin-left: auto
    &__caricamento
      cursor: none
  button
    height: 4rem
    line-height: 1rem
  .button
    padding: 1rem 2rem
    &:not(:last-child)
      margin-right: 1rem
  +res-sm-max
    .button
      &:not(:last-child)
        display: none

@media print
  .toolbar
    display: none
