
.registrazione__template__tipologia-discalaimer
  margin-bottom: 20px
  +typoRegularText

.registrazione__template__alert-autorizza-registrati
  margin: 20px 0

.step-registrazione
  &__fieldsets
    > *
      margin-bottom: 16px
    +res-sm-min
      &:not(.step-registrazione__fieldsets--full-rows)
        display: flex
        flex-wrap: wrap
        margin-left: -16px
        margin-right: -16px
        > *
          width: 50%
          padding-left: 16px
          padding-right: 16px
          &.step-registrazione__fieldset--full-row
            margin-right: 50%
  &__legend
    +typoSmallText
    color: $grey-80
    margin-bottom: 24px

  &__sub-placeholder
    +typoRegularText
    font-weight: bold
    colore: $black
    +res-sm-max
      padding: 2px 0
      +typoSmallText
      font-weight: bold

  &__checkbox
    margin-bottom: 16px

  &__inner-content
    margin-top: 16px

  &__tipologia-utente
    padding: 16px 0
    +res-sm-max
      padding: 12px 0
    &__options
      margin-bottom: 24px
      +res-md-min
        display: flex
        align-items: center
        > *
          flex-grow: 1
          max-width: 25%
      +res-md-only
        flex-wrap: wrap
        > *
          width: 50%
          &:not(:last-child)
            margin-bottom: 8px
      +res-sm-max
        margin-bottom: 16px
        > *
          &:not(:last-child)
            margin-bottom: 8px

    &__option
      border-radius: 4px
      padding: 20px 16px 20px 12px
      +transition(background-color .3s)
      &--active
        background-color: $grey-10