.flusso-personalizzazione
  +typoRegularText
  +wcontainer
  padding-top: 50px
  padding-bottom: 50px
  h1
    +typoH4
    margin-bottom: 50px
    span
      &:not(:last-child)
        display: block
        margin-bottom: 8px
  h3
    +typoH5
    margin-bottom: 24px
  h4
    +typoRegularText
    font-weight: 700
    margin-bottom: 16px
  .fp-section
    margin-bottom: 50px
  .fp-par
    &--sm
      +typoSmallText
    &--lg
      +typoLargeText
    &--highlight
      font-weight: 700
  .fp-head
    margin-bottom: 32px
    &__title
      +typoH4
      margin-bottom: 24px
    &__detail
      +typoRegularText
      font-weight: 700
      margin-bottom: 8px

  .fp-confirm
    margin-top: 40px

  .fp-error
    margin-top: 16px
    margin-bottom: 16px
