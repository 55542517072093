.social-button
  min-width: 233px
  
  &--facebook
    background-color: #3B4DB3
    color: $white

    &:hover:not(:disabled)
      background-color: #3B4DB3
      color: $white

  &--google
    color: $black
    border: 2px solid $grey-20
    background-color: $white

    &:hover:not(:disabled)
      color: $black
      border: 2px solid $grey-20
      background-color: $white
