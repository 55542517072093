
.tabs_container
  font-family: 'Open Sans',sans-serif
  font-size: 16px
  font-weight: bold

  .tab
    border-bottom: 3px solid transparent
    display: inline-block
    cursor: pointer
    text-align: center
    width: 130px
    height: 56px

    @media screen and ( max-width: 415px )
      width: 50px

  .activeTab
    display: inline-block
    cursor: pointer
    text-align: center
    width: 130px
    height: 56px
    color: #04acef
    border-bottom: 3px solid #04acef

    @media screen and ( max-width: 415px )
      width: 50px
