
.ordine-totale
  +typoSmallText
  &__title
    +typoH5
    margin-bottom: 16px
  &__row
    margin-bottom: 10px
    display: flex
    justify-content: space-between
    align-items: flex-end
    max-width: 680px
    > *
      &:last-child
        min-width: 100px
        text-align: right
      &:not(:last-child)
        margin-right: 16px
    &--big
      text-transform: uppercase
      font-weight: 700
      margin-top: 16px
