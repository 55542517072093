
.homepage_template

  &__section
    margin-bottom: 64px
    +res-sm-max
      margin-bottom: 40px

  &__section-title
    +typoH2
    text-align: center
    margin-bottom: 32px
    +res-sm-max
      +typoH4

  &__scrolldown
    display: block
    width: 40px
    height: 40px
    padding: 8px
    margin: 24px auto 16px
    cursor: pointer
    svg
      +style-icon(24px, $black)
    +res-sm-max
      display: none

  &__categorie
    display: flex
    justify-content: center
    flex-wrap: wrap
    +custom-row(14px)

    .categoria
      margin: 0 auto

    +res-md-min
      &__item
        width: 50%

      /* If there are an odd number of children (3, 5, 7, etc.), set each child's width to 33.33% */
      &__item:first-child:nth-last-child(odd):not(:nth-last-child(1)),
      &__item:first-child:nth-last-child(odd):not(:nth-last-child(1)) ~ &__item
        width: 33.33%

      /* If there are an even number of children (2, 4, 6, etc.), set each child's width to 50% */
      &__item:first-child:nth-last-child(even),
      &__item:first-child:nth-last-child(even) ~ &__item
        width: 50%

    +res-md-max
      > *
        width: 33.33%

    +res-sm-max
      +custom-row(10px)
      > *
        width: 50%

    +res-xs-max
      > *
        width: 100%

  &__bestSeller
    +res-md-min
      display: flex
      flex-wrap: wrap
      +custom-row(30px)
      > *
        width: 50%
        margin-bottom: 24px
    +res-lg-min
      > *
        width: 25%
    &__cta
      margin-left: auto

  &__brands
    +res-md-min
      display: flex
      +custom-row(30px)
      > *
        width: 50%
    &__item
      +res-sm-max
        &:not(:last-child)
          margin-bottom: 32px

  &__news
    &__list
      +res-md-min
        display: flex
        flex-wrap: wrap
        +custom-row(30px)
      +res-md-min
        > *
          width: 50%
      +res-lg-min
        > *
          width: 25%
    &__cta
      margin: 24px auto 0
