/* Make clicks pass-through

#nprogress
  pointer-events: none

  .bar
    background: $color-primary
    position: fixed
    z-index: 10031
    top: 0
    left: 0
    width: 100%
    height: 2px

  .peg
    display: block
    position: absolute
    right: 0px
    width: 100px
    height: 100%
    box-shadow: 0 0 10px $color-primary, 0 0 5px $color-primary
    opacity: 1.0
    -webkit-transform: rotate(3deg) translate(0px, -4px)
    -ms-transform: rotate(3deg) translate(0px, -4px)
    transform: rotate(3deg) translate(0px, -4px)

  .spinner
    display: block
    position: fixed
    z-index: 1031
    top: 15px
    right: 15px

  .spinner-icon
    width: 18px
    height: 18px
    box-sizing: border-box
    border: solid 2px transparent
    border-top-color: $color-primary
    border-left-color: $color-primary
    border-radius: 50%
    -webkit-animation: nprogress-spinner 400ms linear infinite
    animation: nprogress-spinner 400ms linear infinite

/* Fancy blur effect

/* Remove these to get rid of the spinner

.nprogress-custom-parent
  overflow: hidden
  position: relative

  #nprogress
    .spinner, .bar
      position: absolute

@-webkit-keyframes nprogress-spinner
  0%
    -webkit-transform: rotate(0deg)

  100%
    -webkit-transform: rotate(360deg)

@keyframes nprogress-spinner
  0%
    transform: rotate(0deg)

  100%
    transform: rotate(360deg)