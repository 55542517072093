
.tecniche-stampa
  &__header
    margin-bottom: 40px
  &__list
    > *
      &:not(:last-child)
        margin-bottom: 40px
        border-bottom: 1px solid $grey-20
        padding-bottom: 48px

.tecniche-stampa-header
  +res-md-min
    display: flex
    align-items: center
  &__content
    background: $grey-20
    padding: 48px 24px
    +res-md-min
      width: 25%
      @media screen and (min-width: $wcont-width)
        width: #{$wcont-width * 0.25} // fisso, non fluido

  &__list
    background: $fill-neutrals-grey-0
    display: flex
    flex-wrap: wrap
    padding: 24px 16px
    > *
      width: 25%
    +res-xs-max
      > *
        width: 50%
    +res-md-min
      order: -1
      width: 75%
      @media screen and (min-width: $wcont-width)
        width: calc(50vw + #{$wcont-width * 0.25}) // 1280 * (75% - 50%)
        padding-left: calc(50vw - #{$wcont-width / 2 - 16px})

  &__item
    padding: 16px 8px

  &__item-title
    margin-top: 16px
    +typoRegularText
    text-align: center
  &__item-icon-box
    position: relative
    width: 60px
    height: 60px
    margin: 0 auto
    +border-radius(50%)
    overflow: hidden
  &__title
    +typoRegularText
    font-weight: 700
    margin-bottom: 16px
  &__description
    +typoRegularText

.tecniche-stampa-box
  &__head
    display: flex
    align-items: center
    margin-bottom: 24px
  &__title
    flex: 1
    +typoH5
  &__icon-box
    position: relative
    width: 60px
    height: 60px
    +border-radius(50%)
    overflow: hidden
    margin-right: 24px
  &__main
    +res-md-min
      display: flex
      align-items: center
  &__image-box
    position: relative
    img
      display: block
    +res-md-min
      margin-right: 24px
      width: 40%
    +res-sm-max
      margin-bottom: 24px
      img
        margin-left: auto
        margin-right: auto
  &__content
    +typoRegularText
    +res-md-min
      flex: 1
