
.registrazione__template
  &__disclaimer
    +typoRegularText
    margin: 24px 0

  &__content
    margin-top: 20px
    padding-bottom: 50px
    +res-md-min
      display: flex
      justify-content: space-between
    &__form
      +res-md-min
        width: 75%
        order: -1
    &__sidebar
      margin-bottom: 24px
      +res-md-min
        width: 25%
        padding-left: 32px
        order: 0
        margin-bottom: 0

    &__vantaggi
      &__bottom
        a
          text-decoration: underline

  &__submit
    margin-top: 24px
