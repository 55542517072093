
.datatablenonresponsive

  &__head
    display: flex
    align-items: center
    > *
      flex: 1

.datatablenonresponsive-headcell
  padding: 10px 8px

.datatablenonresponsive-heading
  display: flex
  justify-content: space-between
  align-items: center
  height: 20px
  &__label
    flex: 1
    +typoCaptionSmall
    color: $grey-80
    overflow: hidden
    white-space: nowrap
    text-overflow: ellipsis
  &__info
    text-align: right
    svg
      width: 20px
      height: 20px
      +color-icon($accent)

.datatablenonresponsive-error
  +typoTinyText
  color: $error

.datatablenonresponsive-row
  page-break-inside: avoid
  border-bottom: 1px solid $grey-20
  &--odd
    background: $fill-neutrals-grey-0
  &__cells
    display: flex
  &__error
    padding: 4px 8px

.datatablenonresponsive-cell
  padding: 12px 8px
  flex: 1
  word-break: break-all
  &__heading
    margin-bottom: 2px
    +res-md-min
      display: none
  &__content
    display: flex
    flex-direction: column
    justify-content: center
    min-height: 48px
    +typoSmallText
    color: $black
  &__error
    padding: 4px 0
