
//1112px
.main__prodotto
  +res-lg-min
    display: flex
    align-items: flex-start
    justify-content: center
    > *
      &:not(:last-child)
        margin-right: 30px
  +res-md-max
    > *
      margin-bottom: 16px

  .newmodal-overlay
    background: unset
  
  &__zoom
    margin: 0 !important
    background-color: rgba(255,255,255,0.95) !important
    max-width: 100% !important
    max-height: 100% !important
    height: 100% !important
    +res-lg-min
      width: 100% !important
      left: unset !important
      right: unset !important
    +res-lg-min
      .newmodal__custom-content
        display: flex
        max-width: 960px
        margin: auto
      .image__carousel
        align-self: center
        margin-right: 100px
      .newmodal__close
        display: none
    +res-md-max
      .newmodal__custom-content
        padding-top: 50%
    .main__image__unselected
      cursor: zoom-out
    .image__carousel__image-box
      cursor: pointer

.image__carousel
  position: relative
  &--nav
    +res-lg-min
      padding: 40px 0
    +res-md-max
      padding: 0 40px

  +res-lg-min
    height: 430px
    width: 105px
    order: -1

  &__slider
    .swiper-container
      +res-lg-min
        height: 350px
    .swiper-slide
      +res-md-max
        width: 56px!important

  &__nav
    position: absolute
    +res-lg-min
      left: 0
      right: 0
    margin: 0 auto
    width: 36px
    height: 36px
    padding: 10px
    cursor: pointer
    &:after
      width: 16px
      height: 16px

    &--disabled
      opacity: 0

    &--prev
      +res-lg-min
        top: 0
      +res-md-max
        left: 0
        margin-top: 12px
      &:after
        content: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M6.101 359.293L25.9 379.092c4.686 4.686 12.284 4.686 16.971 0L224 198.393l181.13 180.698c4.686 4.686 12.284 4.686 16.971 0l19.799-19.799c4.686-4.686 4.686-12.284 0-16.971L232.485 132.908c-4.686-4.686-12.284-4.686-16.971 0L6.101 342.322c-4.687 4.687-4.687 12.285 0 16.971z"></path></svg>')
        +res-md-max
          content: $swiper-left-chevron-black

    &--next
      +res-lg-min
        bottom: 0
      +res-md-max
        right: 0
        margin-top: 12px
      &:after
        content: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M441.9 167.3l-19.8-19.8c-4.7-4.7-12.3-4.7-17 0L224 328.2 42.9 147.5c-4.7-4.7-12.3-4.7-17 0L6.1 167.3c-4.7 4.7-4.7 12.3 0 17l209.4 209.4c4.7 4.7 12.3 4.7 17 0l209.4-209.4c4.7-4.7 4.7-12.3 0-17z"></path></svg>')
        +res-md-max
          content: $swiper-right-chevron-black

  &__image-box
    position: relative
    width: 96px
    height: 96px
    border: 2px solid transparent
    &--active
      border-color: $black
    +res-md-max
      width: 56px
      height: 56px

.main__image
  position: relative
  &__selected
    cursor: zoom-in
    width: 100%
    height: 100%
    +res-md-max
      min-width: 100%
  &__tag
    position: absolute
    bottom: 0px
    left: 0px
  &__wishlist
    position: absolute
    top: 10px
    right: 5px
    cursor: pointer
  &__download
    position: absolute
    top: 10px
    right: 34px
    cursor: pointer
    svg
      +style-icon(24px, $accent)
  &__wrapper
    +res-lg-min
      flex: 1 // gli altri hanno larghezze fisse, questo riempie l'avanzo
    +res-md-max
      margin-left: auto
      margin-right: auto
      max-width: 416px
.prodotto
  padding: 18px 0 24px
  +res-lg-min
    width: 50%

  &__catalogo
    +typoSmallText
    padding: 6px 0
    margin-bottom: 16px
    display: flex
    align-items: center
    svg
      +style-icon(20px, $black)
      margin-right: 12px

  &__header
    margin-bottom: 32px
    &__title
      +typoCaptionLarge
    &__nome
      +typoRegularText
      font-weight: bold
      color: $black
      margin-top: 8px
    &__descrizione
      +typoRegularText
      margin-top: 18px

  &__dettagli
    +res-sm-min
      display: flex
      align-items: flex-start
      margin-bottom: 28px
      > *
        &:not(:last-child)
          margin-right: 52px
          padding-right: 40px
          border-right: 1px solid $grey-20
    +res-xs-max
      > *
        margin-bottom: 24px
    &__title
      +typoCaption
      color: $grey-80
      margin-bottom: 12px
      &-value
        color: $black
    &__wrapper
      display: flex
      flex-wrap: wrap
      &--colors
        > *
          &:not(:last-child)
            +res-md-min
              margin-right: 8px
            +res-sm-max
              margin-right: 12px
      &--taglie
        +typoRegularText
        color: $black
        > *
          &:not(:last-child)
            +res-md-min
              margin-right: 16px
            +res-sm-max
              margin-right: 24px

  &__buttons__wrapper
    +res-sm-min
      display: flex
      > *
        &:not(:last-child)
          margin-right: 24px
    +res-xs-max
      > *
        width: 100%
        max-width: 300px
        margin-right: auto
        margin-left: auto
        &:not(:last-child)
          margin-bottom: 12px
  &__prezzo__wrapper
    +typoH5
    margin-bottom: 24px
    > *
      display: inline-block
      &:not(:last-child)
        margin-right: 24px

  &__alternativo
    margin-top: 20px
    &__title
      +typoCaptionLarge
      font-size: 15px
      a
        color: $accent
        text-decoration: underline

.separator
  height: 60px
  align-self: center
  background-color: $grey-20
  width: 1px
  +res-md-max
    display: none

.main-prodotto__bottom
  +res-md-min
    display: flex
    +custom-row(30px)
    > *
      width: 50%
    .link-utili
      order: -1

.consegna-prevista
  +typoSmallText
  &__block
    position: relative
    padding: 10px 0 0 32px
    margin-bottom: 8px
  &__icon
    position: absolute
    top: 10px
    left: 0
    svg
      +style-icon(20px, $black)
  &__content
    ul
      margin-top: 10px
      > li
        padding-left: 20px
        position: relative
        &::before
          content: ""
          position: absolute
          top: 8px
          left: 0
          background: currentColor
          width: 4px
          height: 4px
          +border-radius(50%)
  &__info
    display: inline-block
    margin-left: 8px
    vertical-align: middle
    margin-top: -3px
    svg
      +style-icon(20px, $accent)

.link-utili
  +typoSmallText
  margin-top: 32px
  +res-sm-max
    margin-top: 24px
    border-top: 1px solid $grey-20
    border-bottom: 1px solid $grey-20
    padding-top: 4px
    padding-bottom: 4px
  &__title
    position: relative
    +typoCaption
    color: $grey-80
    +res-sm-max
      padding-top: 6px
      padding-bottom: 6px
  &__mobile-arrow
    position: absolute
    right: 0
    top: 6px
    svg
      +style-icon(20px, $black)
    +res-md-min
      display: none
  &__content
    padding: 8px 0
    +res-sm-max
      display: none
  &__item
    display: block
    text-align: left
    position: relative
    padding: 6px 0 6px 32px
    cursor: pointer
  &__icon
    position: absolute
    top: 6px
    left: 0
    width: 20px
    height: 20px
    svg
      +style-icon(20px, $black)

  &--mobile-open
    .link-utili
      &__mobile-arrow
        +rotate(180deg)
      &__content
        display: block

.download-menu
  padding: 6px 0
  display: none
  
  &--catalogo
    .download-menu
      &__list
        > .download-menu__item
          +res-sm-max
            > .download-menu__inner-item
              display: block
  &--open
    display: block
  +res-md-min
    position: absolute
    top: calc(100% + 10px)
    left: 0
    width: 300px
    background: $white
    +box-shadow(0 -8px 16px 0 rgba(24, 50, 115, 0.08))
    z-index: 3
    +border-radius(4px)
    &::before
      content: ""
      position: absolute
      top: -20px
      left: 32px
      width: 0
      height: 0
      border: 10px solid transparent
      border-bottom-color: $white
  &__item
    position: relative
    +res-md-min
      padding: 0 8px
      &:hover
        >
          .download-menu__inner-item // Per il color, ma l'hover va sul parent se no non funziona
            background: $fill-neutrals-grey-0

  &__list
    >
      .download-menu__item
        +res-md-min
          > .download-menu__submenu
            display: none
          > .download-menu__inner-item
            position: relative
            padding-right: 40px
          &:hover
            > .download-menu__submenu
              display: block
        +res-sm-max
          &:not(:last-child)
            .download-menu__submenu
              border-bottom: 1px solid $grey-20
              padding-bottom: 8px
              margin-bottom: 8px
          // Nel mobile visualizzo solo le foglie
          > .download-menu__inner-item
            display: none

  &__inner-item
    +res-md-min
      padding: 6px 8px
    +res-sm-max
      padding: 6px 0

  &__arrow
    position: absolute
    right: 0
    top: 6px
    svg
      +style-icon(20px, $black)

  &__submenu
    +res-md-min
      display: none
      position: absolute
      left: 100%
      top: -6px
      z-index: -1
      width: 300px
      background: $white
      +box-shadow(0 8px 16px 0 rgba(24, 50, 115, 0.08))
      padding: 6px 0
