@import "../../../../styles/sass/commons"
  
.form__indirizzo
  .formfield
    margin-bottom: 16px
  &__legend
    +typoSmallText
    color: $grey-80
  &__content
    &__buttons
      margin-top: 32px
      display: flex
      align-items: center
      justify-content: space-between
