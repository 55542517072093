.modal
  z-index: 50
  font-family: 'Open sans',sans-serif
  font-size: 16px
  color: black
  display: flex
  background-color: white
  border-radius: 8px
  box-shadow: 0 8px 16px 0 rgba(24, 50, 115, 0.08)
  min-height: 368px
  display: flex
  justify-content: center
  align-items: center
  min-width: fit-content

.modal__container__fixed
  left: 0px
  z-index: 20
  right: 0px
  height: 100%
  background-color: rgba(0,0,0,0.65)
  position: fixed
  top: 0px
  display: flex
  justify-content: center
  align-items: center