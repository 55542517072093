
.brand-pdf
  display: flex
  justify-content: flex-end

.wrapper__table__and__preventivo
  display: flex
  justify-content: space-between
  margin-top: 50px
  margin-bottom: 50px
  &__table
    flex: 1
  &__sidebar
    width: 255px
    margin-left: 32px
    +res-sm-max
      display: none

.dettagli-prodotto-pdf
  &__header
    margin-bottom: 24px
  &__title
    +typoH5
    margin-bottom: 8px
  &__subtitle
    +typoRegularText
    color: $grey-80
  &__recap
    margin-bottom: 20px
    +res-sm-min
      display: flex
      > *
        &:not(:last-child)
          margin-right: 64px
    +res-xs-max
      > *
        &:not(:last-child)
          margin-bottom: 8px
    &__item
      display: flex
      +typoCaption
      > *
        &:not(:last-child)
          margin-right: 8px
    &__label
      color: $grey-80
    &__arrow
      cursor: pointer
      svg
        display: block
        width: 20px
        height: 20px
        +color-icon($black)
  &__data-list
  &__data
    +typoRegularText
    line-height: 20px
    padding: 3px 0
    +res-xs-max
      &__label
        margin-bottom: 4px
    +res-sm-min
      display: flex
      > *
        flex: 1
        &:first-child
          flex: 0 0 25%
        &:not(:last-child)
          margin-right: 16px
    &__label
      color: $grey-80

.main__image__tag
  width: 80%
