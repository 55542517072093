
.modal
  position: relative
  z-index: 50
  padding: 56px
  text-align: center
  max-width: 618px
  background-color: white
  +border-radius(8px)
  box-shadow: 0 8px 16px 0 rgba(24, 50, 115, 0.08)
  +typoRegularText
  color: $black
  @media screen and ( max-width: 545px )
    position: fixed
    top: 50px
    left: 0px
    min-width: 100%
    height: 80%
    padding: 0px
  &__close
    position: absolute
    top: 16px
    right: 16px
    svg
      display: block
      width: 24px
      height: 24px
      +color-icon($black)
  &__icon
    margin-bottom: 32px
    svg
      display: block
      margin: 0 auto
      width: 40px
      height: 40px
      +color-icon($black)

  &__content
    &__header
      &__title
        +typoH5
        margin: 24px 0
      &__subtitle
        +typoRegularText
        min-height: 96px
    &__actions
      margin-top: 40px
      display: flex
      gap: 24px
      justify-content: center
      align-items: center
      +res-sm-max
        flex-direction: column

.modal__container__fixed
  left: 0px
  z-index: 20
  right: 0px
  height: 100%
  background-color: rgba(0,0,0,0.65)
  position: fixed
  top: 0px
  display: flex
  justify-content: center
  align-items: center
