
.datatable-fatture
  &__pagamento
    display: flex
    color: $success
    &--error
      color: $error
    &__oval
      margin-right: 6px
      width: 8px
      height: 8px
      margin: 6px 10px 6px 0
      border-radius: 24px
      background-color: $success
      &--error
        background-color: $error
  &__icon
    svg
      display: block
      width: 24px
      height: 24px
      +color-icon(currentColor)
