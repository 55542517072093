
.cell__cart
  font-family: 'Open sans',sans-serif
  background-color: $fill-neutrals-grey-0
  padding: 16px 8px
  display: grid
  &__content
    display: flex
    justify-content: space-between
    align-items: flex-start
    @media screen and ( max-width: 545px )
      display: grid
      grid-gap: 26px
      justify-content: normal
    &__main
      width: 100%
      justify-content: flex-end
      display: flex
      align-items: center
      gap: 24px
      @media screen and ( max-width: 545px )
        flex-wrap: wrap
        justify-content: flex-start
        align-items: flex-start
        margin-left: 0px

.cell__prodotto__info

  display: flex
  position: relative
  &__image
    width: 86px
    height: 86px
  &__etichetta
    position: absolute
    left: -15px
    bottom: 10px
    font-size: 12px
    color: $accent
    padding: 5px 8px
    background-color: $fill-accent-10
  &__main
    font-size: 14px
    display: grid
    margin-left: 16px
    grid-gap: 8px
    &__descrizione
      max-width: 180px
      white-space: nowrap
      overflow: hidden
      text-overflow: ellipsis
    &__taglia__colore
      color: $grey-80
      text-transform: uppercase
      font-size: 12px
      line-height: 1.67
      letter-spacing: 0.51px
      font-weight: bold

.cell__prodotto__actions
  display: flex
  gap: 16px
  justify-content: flex-end
  @media screen and ( max-width: 545px )
    justify-content: flex-start
    flex-wrap: wrap
    margin-top: 28px
  &__button
    display: flex
    justify-content: center
    align-items: center
    gap: 12px
    &:hover
      text-decoration: underline
      cursor: pointer
    &__custom
      color: $fill-others-dark-orange-50
      font-weight: bold
      line-height: 1.43
      letter-spacing: 0.2px
      font-size: 14px

.element__not__in__responsive
  display: block
  @media screen and ( max-width: 545px )
    display: none
.element__in__responsive
  display: none
  @media screen and ( max-width: 545px )
    display: block

.cell__cart__wrapper
  display: grid
  grid-gap: 10px
