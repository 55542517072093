
.cell__fatture
  font-family: 'Open sans',sans-serif
  background-color: $fill-neutrals-grey-0
  padding: 16px 8px
  display: grid
  font-size: 14px
  width: 100%
  &__info
    &__wrapper
      display: flex
      justify-content: flex-start
      &__desc
        margin-left: 24px
        display: grid
        strong
          margin-bottom: 4px
  &__placeholder
    color: $grey-80
    text-transform: uppercase
    font-size: 12px
    line-height: 1.67
    letter-spacing: 0.51px
    font-weight: bold
  &__content
    display: flex
    justify-content: space-between
    align-items: center
    @media screen and ( max-width: 545px )
      display: grid
      grid-gap: 26px
      grid-template-columns: repeat(2,auto)
      justify-content: normal
    &__pagamento
      display: flex
      color: $success
      &--error
        color: $error
      &__oval
        margin-right: 6px
        width: 8px
        height: 8px
        margin: 6px 10px 6px 0
        border-radius: 24px
        background-color: $success
        &--error
          background-color: $error

.cell__fatture__wrapper
  display: flex
  flex-direction: column
  justify-items: flex-start
  align-content: flex-start
  text-align: center
  width: 100%
  @media screen and ( max-width: 545px )
    text-align: start
  a
    text-decoration: underline
  svg
    display: block
    margin-left: auto
    margin-right: auto
    @media screen and ( max-width: 545px )
      margin-left: 0
      margin-right: 0

.element__not__in__responsive
  display: block
  @media screen and ( max-width: 545px )
    display: none
.element__in__responsive
  display: none
  @media screen and ( max-width: 545px )
    display: block
