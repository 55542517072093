@import "../../../../styles/sass/commons"
  
.scelta_indirizzo_sg

  &__section
    &:not(:first-child)
      margin-top: 40px

  &__subtitle
    +typoRegularText
    font-weight: 700
    margin-bottom: 16px

  &__others
    &__results
      display: flex
      flex-wrap: wrap
      margin: 0 -16px
      +res-sm-max
        margin: 0 -8px
      > *
        width: calc(50% - 16px)
        margin: 0 8px
        margin-bottom: 16px
        +res-sm-max
          width: calc(50% - 12px)
          margin: 0 6px
          margin-bottom: 8px
    &__empty
      +typoRegularText
      margin-bottom: 16px
      span
        font-weight: 700

  &__search
    position: relative
    margin-bottom: 24px