
.formradio
  position: relative
  display: block
  cursor: pointer
  padding-left: 32px
  min-height: 24px

  &__radio
    position: absolute
    left: -9999px

    &+ .formradio__styledradio
      position: absolute
      top: 2px
      left: 2px
      width: 20px
      height: 20px
      border: 2px solid $black
      +border-radius(50%)
      &::after
        content: ""
        position: absolute
        top: calc(50% - 6px)
        left: 0
        right: 0
        margin: 0 auto
        width: 12px
        height: 12px
        +border-radius(50%)

    &:checked
      &+ .formradio__styledradio
        &::after
          background-color: $black

    &:disabled
      &+ .formradio__styledradio
        border-color: $grey-20
      &:checked
        &+ .formradio__styledradio
          &::after
            background-color: $grey-20

  &__label
    display: block
    color: $black

  &:not(.formradio--sm)
    .formradio
      &__label
        +typoRegularText

  &--sm
    .formradio
      &__label
        +typoSmallText
        padding-top: 2px
        padding-bottom: 2px

  &--with-image
    padding-left: 0
    display: flex
    align-items: center
    > *
      &:not(:last-child)
        margin-right: 12px
    .formradio
      &__styledradio
        position: relative
        flex: 0 0 20px

      &__image
        width: 80px
        height: 80px
        flex: 0 0 80px
        +res-md-max
          flex: 0 0 64px
          width: 64px
          height: 64px
        svg
          display: block
          width: 100%
          height: 100%

      &__label
        flex: 1

  &:hover
    .formradio___radio:not(:disabled)
      &+ .formradio__styledradio
        border-color: red
      &:checked
        &+ .formradio__styledradio
          &::after
            background-color: $grey-100

.formfield
  .formradio
    ~ .formfield
      &__icon, &__error-icon, &__success-icon
        display: none!important // Non mostro le icone per i checkbox

  &--error
    .formradio__input:not(:disabled)
      &+ .formradio
        &__styledinput
          border-color: $error
      &:checked
        &+ .formradio__styledinput
          background: $error

  &--sm
    .formradio
      &__label
        +typoSmallText
        padding-top: 2px
        padding-bottom: 2px

  &:not(.formfield--sm)
    .formradio
      &__label
        +typoRegularText
