
.cart__container
  font-family: 'Open sans',sans-serif
  display: grid
  grid-template-columns: repeat(1,auto)
  grid-gap: 64px
  &__main
    display: flex
    justify-content: space-between
    +res-md-max
      flex-direction: column

.cart__header
  font-size: 38px
  font-weight: bold
  margin-bottom: 24px

.articoli__carrello
  +res-lg-min
    width: calc(100% - 285px)
  &__empty
    +res-sm-max
      text-align: center
      .button
        margin: 0 auto
    p
      +typoRegularText
      margin-top: 40px
      margin-bottom: 32px

  &__head
    +res-md-min
      display: flex
      justify-content: space-between
      flex-wrap: wrap
      > *
        &:not(:last-child)
          margin-right: 16px
  &__title
    font-size: 20px
    font-weight: bold
    margin-bottom: 24px
    +res-md-min
      order: -1
  &__top-buttons
    +res-md-min
      display: flex
      flex-direction: row-reverse
      justify-content: flex-end
      margin-top: -20px // compensa padding e line-height dei bottoni
      margin-left: auto
      > *
        position: relative
        &:not(:first-child) // sono invertiti (row-reverse)
          margin-right: 49px
          &::after
            content: ""
            position: absolute
            right: -25px
            top: calc(50% - 12.5px)
            height: 25px
            width: 1px
            background-color: $grey-10
    +res-sm-max
      margin-bottom: 32px

  &__button-svuota
  &__button-import

  &__subtitle
    font-size: 16px
    font-weight: bold
    padding: 8px 0
  &__block
    margin-bottom: 40px
  &__block-head
    margin-bottom: 8px
  &__alerts
    margin-bottom: 40px

.card__preventivo__responsive
  +res-lg-min
    width: 255px
    position: relative
    .riepilogo-card, .riepilogo-card-carrello
      -webkit-position: sticky
      position: sticky
      // top gestito la layout.sass
