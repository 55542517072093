.checkbox
  font-family: 'Open sans',sans-serif
  &__wrapper
    display: flex
    gap: 10px
    font-size: 14px
    line-height: 1.43
    align-items: flex-start
    padding: 5px
    &__item__number
      color: #8891a1