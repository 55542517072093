.personalizzazione__template
  padding: 100px 20px
  @media screen and ( max-width: 768px )
    padding: 50px 0px
  &__content
    display: flex
    justify-content: space-between
    align-items: center
    @media screen and ( max-width: 768px )
      flex-direction: column
  &__form
    width: 75%
    @media screen and ( max-width: 768px )
      padding: 10px
      width: 100%
      margin-bottom: 50px