
.newmodal-overlay
  position: fixed
  top: 0
  left: 0
  right: 0
  bottom: 0
  width: 100% // Per eventuali override
  z-index: 999998
  background: rgba(#000, .3)

.newmodal
  position: fixed
  top: 50%
  +translateY(-50%)
  left: 20px
  right: 20px
  z-index: 999999
  max-width: 750px
  max-height: 100vh
  overflow-y: auto
  +scrollbar-style
  margin: 0 auto
  padding: 56px
  background: #fff
  +border-radius(8px)
  box-shadow: 0 8px 16px 0 rgba(24, 50, 115, 0.08)
  +typoRegularText
  text-align: center
  color: $black
  +res-sm-max
    max-width: none
    left: 0
    right: 0
    padding: 60px 20px 20px

  &--checkout
    text-align: start
    overflow-y: hidden
    max-width: 865px
    padding-bottom: 96px
    +res-sm-max
      padding-bottom: 124px

    .newmodal        
      &__content
        overflow: hidden auto
        +scrollbar-style
        max-height: calc(100vh - 112px - 72px - 96px) // 56*2 padding, 72 title, 96 actions
        +res-sm-max
          max-height: calc(100vh - 80px - 72px - 124px) // 40*2 padding, 72 title, 124 actions

      &__actions
        display: flex
        justify-content: space-between
        align-items: center

        position: absolute
        bottom: 0
        left: 0
        width: 100%
        margin: 0
        padding: 20px 56px
        height: 96px
        box-shadow: 0px -4px 8px -4px rgba(26, 26, 26, 0.06)
        background: #FFF
        +res-sm-max
          padding: 10px 20px
          height: 124px

        > *
          &:first-child:last-child
            margin-left: auto
          &:not(:last-child)
            margin-right: 20px

  &__close
    position: absolute
    top: 16px
    right: 16px
    cursor: pointer
    svg
      display: block
      width: 24px
      height: 24px
      +color-icon($black)

  &__alert
    margin-bottom: 32px
    svg
      display: block
      margin: 0 auto
      width: 40px
      height: 40px
      +color-icon($black)

  &__title
    +typoH5
    margin: 24px 0
    text-align: center

  &__text
    +typoRegularText
    p
      margin-bottom: 16px

  &__custom-content // children

  &__content // text + children
    min-height: 96px

  &__actions
    margin-top: 40px
    display: flex
    gap: 24px
    justify-content: center
    align-items: center
    +res-sm-max
      flex-direction: column
