.markup-prezzo
  &__totale
    display: flex
    flex-direction: row
    justify-content: space-between
    align-items: center
    margin: 28px 0px
    &__wrapper
      display: flex
      flex-direction: column
      justify-items: flex-end
    &__label
      display: flex
      flex-direction: row  
      font-size: 16px
      font-weight: bold
      margin-bottom: 12px
      justify-content: space-between
      p
        margin-right: 15px
      &--with-icon
        display: flex
        align-items: center
        > div
          margin-left: 5px
          margin-right: 15px
        svg
          display: block
        
  &__wrapper
    display: flex
    flex-direction: row
    align-items: center
    justify-content: center
    &__p
      margin: 0px 16px
      padding: 0px 8px
      font-size: 14px
      font-weight: bold
      
  &__input
    display: flex
    flex-direction: row
    min-width: 100px
    width: 100%
    max-width: 280px
    align-items: center
    
      
    &__text
      width: 50%
      margin-right: 10px
    &__select
      width: 40%
  &__altri-costi
    padding-top: 18px
    margin-bottom: 14px
    &__heading
      font-weight: bold
      font-size: 14px
      padding: 14px 0px
    &__item
      background-color: #f1f2f4
      padding: 12px
      border-radius: 4px
      display: flex
      flex-direction: row
      margin-bottom: 16px
      justify-content: space-between
      align-items: center
    &__label
          font-size: 12px
          font-weight: bold
  &__radio
    &__item
      display: flex
      flex-direction: row
      margin-bottom: 12px
      justify-content: space-between
      align-items: center
    &__container
      display: flex
      flex-direction: column
      margin: 24px 0px
