.contatti_container
    display: flex
    flex-direction: column
    align-content: center
    justify-content: center
    padding: 5% 10%
    width: 100%
    font-family: Open Sans,sans-serif
    font-size: 16px
    line-height: 2
    

    .contatti_container_form
        display: flex
        flex-direction: column
        justify-content: center
        align-items: center

        .field-content 
            display: flex
            flex-direction: row
            justify-content: space-between
            align-content: center
            width: 75%
            margin-bottom: 16px

            @media screen and ( max-width: 568px )
                width: 100%
                flex-direction: column
                line-height: 3

            .formfield 
                 max-width: 350px

            .formfield__input-wrapper
                width: 350px


    label 
        color: #899eb0
        text-transform: uppercase
        font-weight: 700
        font-size: 12px