@import "../../../../../styles/sass/commons"

.checkout__indirizzo-spedizione-fatturazione

  &--servicegift
    margin-top: 16px
  
  &__error
    +typoSmallText
    color: $error
