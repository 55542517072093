@import '../../../styles/sass/commons.sass'

.footer
    z-index: 140
    width: 100%
    background: $grey-10
    padding: 40px 0
    &__content
        display: flex
        +res-md-min
            > *
                &:not(:last-child)
                    margin-right: 20px
        +res-sm-max
            flex-wrap: wrap
    &__logo
        +res-md-min
            width: 25%
        +res-sm-max
            width: 100%
        &__image-box
            position: relative
            max-width: 182px
            width: 100%
            height: 48px
            margin-bottom: 24px
            +res-sm-max
                margin-bottom: 13px
            img
                object-fit: contain
        &__image
            &--sipec
                svg
                    display: block
                    max-width: 100%
                    width: 182px
                    height: 48px
                    +res-sm-max
                        width: 102px
                        height: 27px
        &__title
            +typoSmallText
            color: $grey-80
            max-width: 150px
            +res-sm-max
                +typoTinyText
                max-width: 205px
                margin-bottom: 32px
    &__list
        +res-md-min
            flex: 1 0 0

        +res-sm-max
            display: none
        &__items
            > *
                &:not(:last-child)
                    margin-bottom: 8px
        &__item
            display: block
            color: black
            +typoRegularText
            +res-sm-max
                +typoTinyText
            &:hover
                cursor: pointer
                text-decoration: underline
        &__title
            +typoRegularText
            font-weight: bold
            color: black
            margin-bottom: 16px
            +res-sm-max
                +typoSmallText
                font-weight: bold
                margin-bottom: 2px

        &__image img
            max-width: 113px
            max-height: 88px
            display: block
            +res-sm-max
                max-width: 53px
                max-height: 41px

        &--show-mobile
            +res-sm-max
                margin-left: auto
                display: block
                margin-top: 32px
                width: calc(50% - 10px)
            .footer__list
                &__items
                    display: flex
                    flex-wrap: wrap
                    > *
                        &:not(:last-child)
                            margin-right: 10px
                            margin-bottom: 10px

    &__bottom
        margin-top: 40px
        text-align: center
        font-size: 16px
        @media screen and ( max-width: 834px )
            font-size: 12px
        strong
            display: block
            margin-bottom: 6px
            +res-sm-max
                font-size: 14px
    &__semplificato
        &__item
            &:hover
                text-decoration: underline
                cursor: pointer
            @media screen and ( max-width: 720px )
                max-width: 100px
        &__content
            display: flex
            justify-content: center
            align-items: center
            text-align: center
            font-size: 14px
            line-height: 1.43
            gap: 24px

.footer_items_separator
    background-color: $grey-50
    width: 1px
    height: 24px

.prefooter
    box-shadow: 0 -2px 4px 0 rgba(24, 50, 115, 0.08)
    background: $white
    &__content
        padding-bottom: 4px
        justify-content: center
        align-items: center
        display: flex
        +custom-row(32px)
        > *
            width: 25%
            margin-top: 16px
            margin-bottom: 16px
        +res-sm-max
            flex-wrap: wrap
            > *
                width: 50%
        &__item
            text-align: center
            &__icon
                display: block
                height: 32px
                width: 32px
                margin-left: auto
                margin-right: auto
            &__title
                +typoSmallText
                margin-top: 8px

.footer__mobile
    display: none
    +res-sm-max
        display: block
        width: 100%
    &__accordions
        .accordion__placeholder
            +typoSmallText
            font-weight: bold
    &__accordion__items
        > *
            padding: 7.5px 0
    &__bottom
        margin-top: 32px
        display: grid
        grid-template-columns: repeat(2,auto)
        justify-items: space-between
        align-items: flex-end

        &__item__wrapper
            display: grid
            grid-template-columns: repeat(1,auto)
            grid-gap: 8px
        &__item
            font-size: 12px
            line-height: 1.33
            &:hover
                text-decoration: underline
                cursor: pointer
        &__image
            max-height: 45px
