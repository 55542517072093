
// TODO Check se viene utilizzato, altrimenti elimina
.card
  position: relative
  max-width: 400px
  margin-left: auto
  margin-right: auto
  padding: 40px 16px 16px
  z-index: 1
  background-color: $white
  transition: 0.1s cubic-bezier(.17,.67,.83,.67)
  &:hover
    box-shadow: 0 8px 16px 0 rgba(24, 50, 115, 0.08)

  &__wishlist
    position: absolute
    top: 16px
    right: 16px
    cursor: pointer
    z-index: 2
    svg
      +style-icon(24px, $accent)

  &__brand-image
    position: absolute
    top: 16px
    left: 16px
    max-width: 50%
    max-height: 24px
    z-index: 2

  &__image__wrapper
    position: relative
    width: 184px
    height: 184px
    margin: 0 auto 16px

  &__image__tag
    position: absolute
    left: -16px
    bottom: 8px

  &__colors
    margin-bottom: 18px

    .swiper-button-next, .swiper-button-prev
      background: $white
      &::after
        font-size: 12px!important
        color: $black
      &.swiper-button-disabled
        opacity: 0

    .swiper-button-prev, .swiper-container-rtl .swiper-button-next
      left: 0
    .swiper-button-next, .swiper-container-rtl .swiper-button-prev
      right: 0

  &__codice
    +typoCaption
    font-weight: bold
    margin-bottom: 4px

  &__titolo
    margin-bottom: 12px
    +typoSmallText
    +multiline-ellipsis(2, 20px)
    cursor: pointer
    &:hover
      text-decoration: underline

  &__footer
    min-height: 48px // I prezzi si caricano dopo - evita il resize
    margin-top: 12px

  &__pezzi__wrapper
    display: flex
    +typoTinyText
    > *
      &:not(:last-child)
        margin-right: 8px
  &__pezzi__icon
    +style-icon(16px, $black)

  &__prezzo
    margin-top: 8px
    display: flex
    justify-content: space-between
    align-items: flex-start
    &__label
      +typoSmallText
      color: $grey-80
      min-width: 80px
    &__value
      text-align: right
      +typoRegularText
      &__final-price
        font-weight: bold
        padding-left: 8px
        white-space: nowrap
      &__full-price
        padding-left: 8px
        +typoSmallText
        color: $grey-80
        text-decoration: line-through
        white-space: nowrap

  &--vista-2
    .card__image__wrapper
      width: 264px
      height: 264px

.wrapper_sconto
  display: flex
  flex-direction: row
  gap: 9px

.card__ricerca
  &__container
    font-family: 'Open Sans', sans-serif
    height: 112px
    width: 386px
    background-color: $white
  &__content
    padding: 8px 16px 8px 16px
    display: flex
    align-items: center
    justify-content: center
    gap: 16px
    &__image
      width: 96px
      height: 96px
    &__info
      display: flex
      flex-direction: column
      &__codice
        letter-spacing: 0.6px
        line-height: 1.43
        font-weight: bold
        font-size: 14px
        color: $black
      &__titolo
        color: $black
        height: 72px
        width: 258px
        line-height: 1.5
        letter-spacing: normal
        font-size: 16px
        text-overflow: ellipsis
        overflow: hidden
        white-space: nowrap
        &:hover
          cursor: pointer
          text-decoration: underline
