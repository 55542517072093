@import "./variables"

@keyframes pulse
  0%
    transform: scale(0)
  70%
    transform: scale(1.1)
  100%
    transform: scale(1)

@keyframes to_right
  0%
    transform: translateX(-15rem)
  100%
    transform: translateX(0)
	
@keyframes delay-display
  0%
    opacity: 0
    overflow: hidden
    max-height: 0
  100%
    opacity: 1
    overflow: auto
    max-height: calc(100vh - 168px)
    
@keyframes delay-display-toolbar
  0%
    opacity: 0
    overflow: hidden
    max-height: 0
  100%
    opacity: 1
    overflow: auto
    max-height: calc(100vh - #{168px + $toolbar-height})
    
