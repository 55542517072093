
.tecniche_container
  display: flex
  flex-direction: column

.tecniche__card
  display: flex
  max-width: 264px
  flex-direction: row
  background-color: white
  &__image
    width: 60px
    height: 60px
    border-radius: 50%
    background-color: $grey-80
  &__description
    padding: 12px 16px
    max-width: 223px
    word-break: break-word
    strong
      font-size: 16px
      line-height: 1.5

    p
      margin-top: 8px
      line-height: 1.43
      font-size: 14px
      color: $grey-80

@media only screen and (min-width: 320px)
  .tecniche_container
    display: flex
    flex-direction: column

@media only screen and (min-width: 830px)
  .tecniche_container
    display: flex
    flex-direction: row
