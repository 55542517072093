
.nuova-password
  padding-top: 24px
  padding-bottom: 100px

  &__back
    margin-bottom: 40px

  &__title
    +typoH5
    margin-bottom: 40px

  &__description
    font-size: 12px
    margin-bottom: 20px

  &__form
    max-width: 500px

  &__fields
    +res-md-min
      display: flex
      flex-wrap: wrap
      +custom-row(32px)

  &__field
    margin-bottom: 16px
    +res-md-min
      width: 50%
      &--full
        width: 100%
