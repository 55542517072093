@import '../../../styles/sass/commons'

#navigation
  position: relative
  z-index: 1
  height: 48px
  +res-md-max
    display: none
  .nav
    display: flex
    align-items: flex-start
    justify-content: space-between
    margin: 0 -8px
    &__item
      a
        display: block
        +typoRegularText
        text-align: center
        padding: 12px 8px
        text-decoration: none
        cursor: pointer
        +res-lg-max
          +typoSmallText
      a:hover, .active
        color: $accent
        background: #fff
  .submenu
    z-index: 9999
    background: #fff
    position: absolute
    top: 100%
    left: 0
    right: 0
    box-shadow: 0 8px 16px 0 rgba(24, 50, 115, 0.08)
    max-height: 0
    opacity: 0
    overflow: hidden
    animation: delay-display .3s forwards 1s
    &.abbigliamento__menu
      +scrollbar-style
      overflow-y: auto
    &.altrecategoriemenu
      .altrecategoriemenu
        &__items, &__subitems
          max-height: calc(100vh - 168px)
          +scrollbar-style
          overflow-y: auto
	
    &__viewall
      display: inline-block
      margin-top: 28px


.nav-mobile-overlay
  display: none
  position: fixed
  top: 0
  left: 0
  right: 0
  bottom: 0
  background: rgba($black, .3)
  z-index: 99998
  &--open
    +res-md-max
      display: block

.nav-mobile, .subnav-mobile
  position: fixed
  right: 0
  top: 0
  bottom: 0
  width: 100%
  max-width: 400px
  overflow-y: auto
  +scrollbar-style
  +translateX(100%)
  +transition(transform .5s)
  z-index: 99999
  padding: 56px 24px
  background: $white
  +res-lg-min
    display: none
  &--open
    +translateX(0)
  &__close
    position: absolute
    top: 16px
    right: 24px
    svg
      width: 24px
      height: 24px
  &__nav
    .nav__item
      &:not(:last-child)
        margin-bottom: 24px
      a, span
        display: block
        +typoRegularText
        text-decoration: none
        cursor: pointer

.nav-mobile
  &__nav-bottom
    border-top: 1px solid $grey-20
    padding-top: 32px
    margin-top: 32px
    .nav__item
      &:not(:last-child)
        margin-bottom: 20px
      a
        display: block
        +typoSmallText
        text-decoration: none
        cursor: pointer
    

.subnav-mobile
  &__head
    position: relative
    padding-left: 24px
    padding-right: 24px
    margin-bottom: 32px
  &__back
    position: absolute
    top: 0
    left: 0
    padding: 0
    svg
      +style-icon(24px, $accent)
  &__title
    +typoRegularText
    text-align: center

  &__viewall
    .button__label
      font-weight: bold

  .nav__item
    &--with-image
      .nav__item__link
        display: flex
        align-items: center
      .nav__item
        &__image-box
          width: 48px
          height: 48px
          margin-right: 16px
        &__num-prod
          display: inline
          color: $grey-80
          margin-left: 4px

.toolbar
  ~ .header
    #navigation
      .submenu
        &.altrecategoriemenu
          .altrecategoriemenu
            &__items, &__subitems
              max-height: calc(100vh - #{168px + $toolbar-height})
              