@mixin pagebuilder-content
  +typoRegularText
  .custom_text
    a
      text-decoration: underline
    .text--large
      +typoLargeText
    .text--small
      +typoSmallText
    .text--tiny
      +typoTinyText
    .text--caption
      +typoCaption
    .text--caption-small
      +typoCaptionSmall
  .row
    margin-top: 40px
    margin-bottom: 40px
    +res-sm-max
      margin-top: 24px
      margin-bottom: 24px
  .col-12
    +res-sm-max
      &:not(:last-child)
        margin-bottom: 24px
  .video
    position: relative
    padding-bottom: 56.25%
    padding-top: 30px
    height: 0
    overflow: hidden
    margin-top: 40px
    margin-bottom: 40px
    +res-sm-max
      margin-top: 24px
      margin-bottom: 24px
    iframe
      position: absolute
      top: 0
      left: 0
      width: 100%
      height: 100%