.pdf
  max-width: 670px
  margin: 0 auto
  padding: 50px
  // padding-bottom: 50px
  padding-bottom: 0px
  +typoRegularText
  h1 
    +typoH4
    margin-bottom: 32px
    span
      display: block
      margin-top: 8px
  h3
    +typoH5
    margin-bottom: 24px
  h4
    +typoRegularText
    font-weight: 700
    margin-bottom: 16px
  .par
    &--sm
      +typoSmallText
  &__logo-sipec
    max-width: 670px
    margin: 0 auto
    padding: 50px 50px 8px 0px
    svg
      display: block
      width: 182px
      height: 48px
  &__section
    margin-bottom: 50px
    &:last-child
      margin-bottom: 0px
  &__subsection
    margin-bottom: 24px
  &__highlight
    +typoLargeText
    a
      text-decoration: underline
  &__small
    +typoSmallText
  .button.centered
    margin-left: auto
    margin-right: auto

  &__data-table
    > .pdf__data-row
      &:first-child
        padding-top: 6px
        border-top: 1px solid $grey-20
      &--big 
        &:first-child
          padding-top: 12px
  &__data-row
    +typoSmallText
    padding-bottom: 6px
    margin-bottom: 6px
    display: flex
    align-items: flex-end
    border-bottom: 1px solid $grey-20
    > *
      flex: 1 0 0
      &:not(:last-child)
        margin-right: 16px
    &--big
      +typoRegularText
      font-weight: 700
      padding-bottom: 12px
      margin-bottom: 12px
  &__data-myaccount
    display: flex
    margin-bottom: 4px
    > *
      &:first-child
        width: 150px
        color: $grey-80

  &__bozza
    width: calc(100vw - 20px)
    margin-left: calc(-50vw + 50% + 10px)
    padding: 10px 10px
    page-break-after: always
    img
      //max-height: 300px
      width: 100%
      max-height: calc(100vh - 30px)
      object-fit: contain

  .personalizzazione__item
    page-break-inside: avoid
  .nobreak
    page-break-inside: avoid

  &__empty-header
    height: 100px
  &__header
    position: fixed
    height: 100px
    top: 0
    width: 100%
  &--with-logo
    padding-top: 0px
    .pdf__bozza
      padding: 0px
      img
        max-height: calc(100vh - 130px)
    .pdf__subsection
      margin-bottom: 0px


.pdf-totali
  display: flex
  &__title
    flex: 1 0 0 
    +typoH5
  &__content
    width: 400px

  &__row
    +typoSmallText
    padding: 6px 0
    margin-bottom: 8px
    display: flex
    justify-content: space-between
    &--small
      +typoSmallText
      padding-left: 16px
      color: $grey-80
    &--final
      font-weight: 700
      margin-top: 20px
      border-top: 1px solid $grey-20
      padding-top: 20px
      text-transform: uppercase