
.importo_container
  display: flex
  flex-direction: row

.importo__card
  display: flex
  max-width: 145px
  flex-direction: column
  background-color: white
  border: 1px solid #E0E8EF
  padding: 15px
  border-radius: 5px
  margin-right: 15px
  &__title
    max-width: 145px
    min-height: 40px
    word-break: break-word
    color: $grey-80
    font-size: 13px
    line-height: 1.5
    text-transform: uppercase
  &__cifra
    max-width: 145px
    word-break: break-word
    margin-top: 8px
    line-height: 1.43
    font-size: 18px

@media only screen and (min-width: 320px)
  .importo_container
    display: flex
    flex-direction: column

@media only screen and (min-width: 830px)
  .importo_container
    display: flex
    flex-direction: row
