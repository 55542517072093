
.search-nav
  +res-md-max
    position: relative
    background-color: $fill-neutrals-grey-0
    margin: 0 -16px 16px
  &__content
    padding: 8px 20px
    display: flex
    justify-content: space-between
    +res-lg-min
      display: none
  &__trigger
    position: relative
    display: inline-block
    +typoSmallText
    font-weight: bold
    padding: 10px 32px 10px 0
  &__arrow-icon
    position: absolute
    right: 0
    top: 10px
    +style-icon(20px, $black)
  &__children
    +res-md-max
      display: none
      position: absolute
      top: 100%
      left: 0
      right: 0
      overflow-y: auto
      max-height: 50vh
      +scrollbar-style
      z-index: 10
      background: $white
      padding: 16px 24px
      +box-shadow(0px 8px 16px 0px rgba(24, 50, 115, 0.08))
      &--open
        display: block
