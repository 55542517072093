.ordine
  &__title
    padding-bottom: 1.5rem
  &__dati
    +typoSmallText
    line-height: 1.5
    &__title
      +typoRegularText
      font-weight: 700
      margin-bottom: 8px
    &__label
      font-size: 1.2rem
      line-height: 1.5
      opacity: .6
    &__docs-bolle
      display: flex
      flex-wrap: wrap
      gap: 20px
  &__container
    margin-bottom: 40px
    &--lined
      padding-top: 20px
      padding-bottom: 20px
      border-top: solid 1px $grey-20
      border-bottom: solid 1px $grey-20
    &__title
      +typoH5
      margin-bottom: 8px

.lineSeparator
  margin-top: 40px
  border-bottom: solid 1px $grey-20
  margin-bottom: 40px
  &--sm
    margin-top: 20px
    margin-bottom: 20px