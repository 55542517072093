
.quantity-handler
  display: flex
  align-items: center
  &__input
    border: solid 2px $grey-50
    max-width: 52px
    height: 28px
    text-align: center
    margin: 0 8px
    &::-webkit-inner-spin-button
      -webkit-appearance: none
      -moz-appearance: textfield
    &:focus
      outline: none
  &__button
    display: flex
    font-size: 14px
    font-weight: bold
    align-items: center
    justify-content: center
    height: 28px
    width: 28px
    color: white
    border-radius: 50%
    background-color: $accent
    cursor: pointer
    svg
      display: block
      width: 19px
      height: 19px
      +color-icon(#fff)
