
.inspire__card
  background: $grey-10
  padding: 64px 0 40px
  +res-md-max
    padding: 40px 0 32px
  &__container
    +res-lg-min
      display: flex
    &__leftSide
      +res-lg-min
        width: 25%
        padding-right: 40px

      &__title
        +typoH3
        margin-bottom: 32px
        +res-lg-max
          +typoH4
        +res-md-max
          text-align: center
          margin-bottom: 24px

      &__categorie
        +res-lg-min
          > *
            &:not(:last-child)
              margin-bottom: 16px
          &--mobile
            display: none

        +res-md-max
          margin: 0 -16px 24px
          &--desktop
            display: none
          .swiper-slide
            width: auto!important
            padding: 0 16px

      &__categoria
        +typoRegularText
        font-weight: bold
        cursor: pointer
        +transition(all .3s)
        &--active
          color: $accent
        +res-lg-min
          border-left: 3px solid transparent
          margin-left: -3px
          &--active
            padding-left: 11px
            border-left-color: $accent
        +res-md-max
          border-bottom: 2px solid transparent
          white-space: nowrap
          &--active
            border-bottom-color: $accent

    &__rightSide
      +res-lg-min
        width: 75%
      +res-md-min
        display: flex
        justify-content: space-between
        > *
          width: calc(33.33% - 20px)
          &:not(:last-child)
            margin-right: 30px
  &__button
    margin-top: 32px
    text-align: right
    +res-sm-max
      margin-top: 24px
    > *
      display: inline-block
