
.dropdown
  +typoSmallText
  color: $black
  &__control
    border: none!important
    +border-radius(0!important)
    cursor: pointer!important
    background: none!important
    &--is-focused
      border: none!important
      box-shadow: none!important
    &--menu-is-open
      .dropdown__dropdown-indicator
        svg
          +rotate(180deg)
  &__indicator-separator
    display: none!important
  &__value-container
    padding: 10px 0 10px 0!important
  &__dropdown-indicator
    padding: 8px 0 8px 12px!important
    svg
      display: block
      width: 20px
      height: 20px
      +color-icon($black)
  &__placeholder
    color: $black!important
  &__placeholder, &__single-value
    position: relative!important
    margin: 0!important
    +transform(translateY(0)!important)
    top: 0!important
    text-overflow: unset!important
    max-width: none!important
  &__menu
    margin: 4px 0!important
    +border-radius(4px!important)
    box-shadow: 0 16px 32px 0 rgba(24, 50, 115, 0.08)!important
  &__menu-list
    padding: 8px 0!important
  &__option
    padding: 10px 16px!important
    cursor: pointer!important
    &--is-selected
      color: $black!important
      background: $fill-accent-10!important
    &--is-focused
      background: $fill-accent-0!important
