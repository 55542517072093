.counter-main-container

    .controls
        display: flex
        flex-direction: row !important
        justify-content: space-between
        border: 2px solid #bdc2cb
        border-radius: 5%
        width: 150px
        height: 40px

        button 
            font-size: 20px
            padding: 0 15px

        p 
            font-size: 12px
            padding: 10px