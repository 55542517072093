.prodotto_card
    &__container
        width: 100%
    &__img
        margin-left: auto
        margin-right: auto
    &__title
        width: 100%
    &__colors
        display: flex
        flex-direction: row
        flex-wrap : wrap
        &__price
    &__price
        display: flex
        flex-direction: row
        justify-content: space-between
    &__top_elements
        display: flex
        flex-direct: row
        justify-content: space-between
        align-items: center
        &__brand
            margin-right: auto
        &__wishlist
            margin-left: auto