
.myaccount-data-row
  +typoRegularText
  display: flex
  +res-sm-max
    +typoSmallText
  > *
    &:not(:last-child)
      margin-right: 16px
  &__label
    color: $grey-80
    width: 40%
    max-width: 150px
    +res-sm-max
      max-width: 100px
  &__value
    color: $black
  &__info
    +typoSmallText
    margin-top: 12px
    max-width: 600px

  &--checkmark
    max-width: 510px
    padding: 16px 0
    justify-content: space-between
    &:not(:last-child)
      border-bottom: 1px solid $grey-20
    .myaccount-data-row
      &__label
        width: auto
        max-width: none
      &__value
        svg
          +style-icon(20px, currentColor)
