@import "../../../../../styles/sass/commons"

.checkout__spedizione
  .checkout
    &__option
      &--indirizzo
      @media (min-width: $res-sm-min) and (max-width: $res-sm-max), (min-width: $res-lg-min)
        display: flex
        justify-content: space-between
        > *:not(:last-child)
          margin-right: 16px
      &.checkout__option--selected
        background: $fill-neutrals-grey-0
      .formradio
        @media (min-width: $res-sm-min) and (max-width: $res-sm-max), (min-width: $res-lg-min)
          flex: 1

  &__altrocorriere
    margin-top: 8px
    +res-md-min
      padding-left: 56px
      display: flex
      align-items: flex-end
      > *:not(:last-child)
        margin-right: 16px
      .formfield        
        width: 360px
    +res-sm-max
      padding-left: 48px
      > *:not(:last-child)
        margin-bottom: 8px
      .formfield        
        max-width: 360px
    +res-xs-max
      padding-left: 0
    

.form__indirizzo__wrapper
  display: flex
  flex-direction: column
  text-align: left
  padding: 20px
  h1
    font-size: 14px
