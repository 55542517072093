
.prodotto-info
  display: flex
  justify-content: space-between
  position: relative
  &__image
    width: 86px
    height: 86px
  &__etichetta
    position: absolute
    left: -15px
    bottom: 10px
    font-size: 12px
    color: $accent
    padding: 5px 8px
    background-color: $fill-accent-10
    +res-sm-max
      display: none
  &__main
    +typoSmallText
    width: calc(100% - 102px) // 102px = 86px + 16px di margine
  &__descrizione
    white-space: nowrap
    overflow: hidden
    text-overflow: ellipsis
    max-width: 159px // Va impostata per forza a causa dei flex innestati - 159 è calcolato sul min viewport di 320px
  &__data
    +typoCaptionSmall
    word-break: normal
  &__data-label
    color: $grey-80
  &__data-value
    color: $black
