// Shortcuts 

@mixin clearfix
    &::after
        content: ""
        display: block
        clear: both

@mixin vertical-align()
    position: absolute
    top: 50%
    @include transform(translateY(-50%))

// Grid 

@mixin flexcol-part($value)
    width: 100
    min-height: 1px
    padding-right: 15px
    padding-left: 15px
    -webkit-box-flex: 0
    -ms-flex: 0 0 $value
    flex: 0 0 $value
    max-width: $value
    position: relative

@mixin flexcol($total)
    @for $i from 1 to $total - 1
        .col-#{$i}-#{$total}
            +flexcol-part($i / $total * 100%)
        +res-sm-min
            .col-sm-#{$i}-#{$total}
                +flexcol-part($i / $total * 100%)
        +res-md-min
            .col-md-#{$i}-#{$total}
                +flexcol-part($i / $total * 100%)
        +res-lg-min
            .col-lg-#{$i}-#{$total}
                +flexcol-part($i / $total * 100%)
        +res-xl-min
            .col-xl-#{$i}-#{$total}
                +flexcol-part($i / $total * 100%)


// Utilities 

@mixin wcontainer
    max-width: $wcont-width
    margin: 0 auto
    padding-left: 16px
    padding-right: 16px


@mixin color-icon($value)
    fill: $value
    &.icon
        &--strokes
            stroke: $value

@mixin style-icon($size, $color)
    display: block
    width: $size
    height: $size
    +color-icon($color)

@mixin custom-row($gap)
    margin-left: -($gap/2)
    margin-right: -($gap/2)
    > *
        padding-right: $gap/2
        padding-left: $gap/2

@mixin multiline-ellipsis($lines, $line-height)
    height: $lines * $line-height // fallback per IE
    overflow: hidden
    display: -webkit-box
    -webkit-line-clamp: $lines
    -webkit-box-orient: vertical

@mixin styled-list
    margin: 16px 0  
    > li
        padding-left: 32px
        margin-bottom: 5px
        position: relative
        &::before
            content: ""
            position: absolute
            top: 8px
            left: 16px
            background: currentColor
            width: 4px
            height: 4px
            +border-radius(50%)


@mixin placeholder-style
    ::-webkit-input-placeholder, ::-moz-placeholder, :-ms-input-placeholder, :-moz-placeholder
        @content


@mixin scrollbar-style
  &::-webkit-scrollbar
    background: $grey-10
    width: 8px
    padding: 5px

  &::-webkit-scrollbar-thumb
    background: #c9ced6
    border-color: $grey-10
    border-style: solid
    border-width: 5px 2px
    +border-radius(4px)


// Vendors Support 

@mixin vendors-support($prop, $value)
    -webkit-#{$prop}: $value
    -moz-#{$prop}: $value
    -ms-#{$prop}: $value
    -o-#{$prop}: $value
    #{$prop}: $value

@mixin columns($value)
    @include vendors-support(columns, $value)

@mixin box-shadow($value)
    @include vendors-support(box-shadow, $value)

@mixin border-radius($value)
    @include vendors-support(border-radius, $value)

@mixin border-top-left-radius($value)
    @include vendors-support(border-top-left-radius, $value)

@mixin border-top-right-radius($value)
    @include vendors-support(border-top-right-radius, $value)

@mixin border-bottom-left-radius($value)
    @include vendors-support(border-bottom-left-radius, $value)

@mixin border-bottom-right-radius($value)
    @include vendors-support(border-bottom-right-radius, $value)

@mixin transform($value)
    @include vendors-support(transform, $value)

@mixin transition($value)
    @include vendors-support(transition, $value)

@mixin translateX($value)
    @include vendors-support(transform, translateX($value))

@mixin translateY($value)
    @include vendors-support(transform, translateY($value))

@mixin rotate($value)
    @include vendors-support(transform, rotate($value))

@mixin filter($value)
    @include vendors-support(filter, $value)

    

// Media Queries 

@mixin res-xs-max
    @media (max-width: $res-xs-max)
        @content

@mixin res-sm-min
    @media (min-width: $res-sm-min)
        @content

@mixin res-sm-max
    @media (max-width: $res-sm-max)
        @content

@mixin res-sm-only
    @media (min-width: $res-sm-min) and (max-width: $res-sm-max)
        @content

@mixin res-md-min
    @media (min-width: $res-md-min)
        @content

@mixin res-md-max
    @media (max-width: $res-md-max)
        @content
        
@mixin res-md-only
    @media (min-width: $res-md-min) and (max-width: $res-md-max)
        @content

@mixin res-lg-min
    @media (min-width: $res-lg-min)
        @content

@mixin res-lg-max
    @media (max-width: $res-lg-max)
        @content

@mixin res-lg-only
    @media (min-width: $res-lg-min) and (max-width: $res-lg-max)
        @content

@mixin res-xl-min
    @media (min-width: $res-xl-min)
        @content

    
@mixin res-desktop
    @media (min-width: $res-sm-min)
        @content

@mixin res-mobile
    @media (max-width: $res-xs-max)
        @content