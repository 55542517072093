
.indirizzo-card
  padding: 24px
  border-radius: 4px
  background-color: $grey-10
  +res-sm-max
    padding: 16px

  &--no-bg
    background-color: inherit
    border-radius: 4px
    border: 1px solid $grey-80

  &--checkout
    .indirizzo-card
      &__button-icon
        svg
          display: block
          width: 20px
          height: 20px
          +color-icon(currentColor)
      &__button-label
        +typoMediumButton
        margin-left: 16px
        +res-sm-max
          display: none

      &__option
        display: flex
        +typoRegularText

  &__header
    display: flex
    justify-content: space-between
    align-items: center
    margin-bottom: 8px
  &__title
    +typoRegularText
    font-weight: 700
  &__buttons
    display: flex
    align-items: center
    gap: 24px
  &__button
    display: flex
    align-items: center
    color: $accent
    cursor: pointer
  &__button-icon
    svg
      display: block
      width: 24px
      height: 24px
      +color-icon(currentColor)
  &__button-label
    +typoLargeButton
    margin-left: 16px
    +res-sm-max
      display: none
  &__description
    display: flex
    flex-direction: column
    margin-bottom: 8px
    +typoRegularText
    +res-sm-max
      +typoSmallText
      margin-bottom: 16px

  &__footer
    display: flex
    flex-wrap: wrap
    > *
      margin-top: 8px
      &:not(:last-child)
        margin-right: 55px
