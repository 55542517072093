.uploadError
  z-index: 50
  font-family: 'Open sans',sans-serif
  font-size: 16px
  color: black
  display: flex
  padding: 10px
  align-items: center
  justify-content: center
  text-align: center
  min-width: 618px
  min-height: 368px
  background-color: white
  border-radius: 8px
  box-shadow: 0 8px 16px 0 rgba(24, 50, 115, 0.08)
  @media screen and ( max-width: 545px )
    position: fixed
    top: 50px
    left: 0px
    min-width: 100%
    height: 80%
    padding: 0px
  &__content
    z-index: 50
    display: flex
    gap: 60px
    width: 100%
    flex-direction: column
    align-items: center
    &__header
      padding-top: 24px
      max-width: 80%
      &__icon
        display: block
        margin: 0 auto 32px
        width: 40px
        height: 40px
      &__title
        font-weight: bold
        margin-bottom: 24px
      &__link
        align-self: center
        position: fixed
        left: 43%
        text-decoration: underline
        @media screen and ( max-width: 320px )
          left: 17%
        @media screen and ( max-width: 570px )
          left: 31%
    &__actions
      display: flex
      gap: 24px
      align-items: center
      padding-bottom: 24px
      @media screen and ( max-width: 545px )
        flex-direction: column

  &__container__fixed
    left: 0px
    z-index: 20
    right: 0px
    height: 100%
    background-color: rgba(0,0,0,0.65)
    position: fixed
    top: 0px
    display: flex
    justify-content: center
    align-items: center