
.modal-login
  padding: 40px 0 24px
  text-align: left
  .login_template
    .login
      padding: 0
    +res-sm-max
      padding: 40px 16px 24px
      +border-radius(0)
