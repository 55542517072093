
.materiale-promozionale
  margin-top: 56px
  +res-sm-max
    margin-top: 40px

  &__title
    +typoH5
    margin-bottom: 40px
    +res-sm-max
      margin-bottom: 24px

  &__list
    display: flex
    flex-wrap: wrap
    +custom-row(30px)
    > *
      width: 25%
      margin-bottom: 32px
      +res-md-max
        width: 50%
      +res-sm-max
        width: 100%

.materiale-promozionale-card
  max-width: 300px
  margin-left: auto
  margin-right: auto

  &__image-box
    margin-bottom: 16px
    img
      object-fit: cover

  &__title
    +typoRegularText
    font-weight: bold
    margin-bottom: 8px

  &__description
    +typoRegularText
