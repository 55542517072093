
.step-tailor
  &__fieldsets
    > *
      margin-bottom: 16px
    +res-sm-min
      &:not(.step-registrazione__fieldsets--full-rows)
        display: flex
        flex-wrap: wrap
        align-items: center
        margin-left: -16px
        margin-right: -16px
        > *
          width: 50%
          padding-left: 16px
          padding-right: 16px
          &.step-registrazione__fieldset--full-row
            margin-right: 50%
  &__legend
    +typoSmallText
    color: $grey-80
    margin-bottom: 24px
