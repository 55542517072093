
//ho inserito direttamente 310px in width in attesa dell'ui completa del componente
.personalizzazione__item
  border-bottom: solid 1px $grey-20
  padding: 16px 0
  +res-sm-max
    padding: 12px 0
  &:first-child
    border-top: solid 1px $grey-20

  &__wrapper
    display: flex
    align-items: center
    justify-content: space-between
  &__edit
    margin-left: auto
  &__step
    border-radius: 50%
    height: 24px
    width: 24px
    padding: 2px 0
    background-color: $black
    +typoSmallText
    font-weight: bold
    color: $white
    text-align: center
    margin-right: 16px
    &__icon
      +style-icon(20px, $white)
      margin-left: auto
      margin-right: auto
  &__container
    margin-bottom: 8px
    width: 100%
    display: flex
    justify-content: space-between
    align-items: center
    +res-xs-max
      flex-wrap: wrap
      margin-bottom: 4px
  &__placeholder
    +typoRegularText
    font-weight: bold
    colore: $black
    +res-sm-max
      padding: 2px 0
      +typoSmallText
      font-weight: bold
  &__children
    padding-left: 40px
    padding-bottom: 8px
    +res-md-min
      padding-bottom: 12px

  &__recap
    padding-left: 40px
    font-size: 16px
    line-height: 1.5
    color: $grey-80

.stepitem--disabled
  color: $grey-20

.stepitem--bg--disabled
  background-color: $grey-20
