
.service-gift-header
  background: $fill-neutrals-grey-0
  margin-bottom: 64px
  +res-md-min
    display: flex
    align-items: center

  &__content
    +typoRegularText
    +res-md-min
      order: -1
      width: 60%
      padding: 40px 40px 40px 16px
      margin: 24px 0 24px calc(50vw - 640px)
    @media screen and (max-width: $wcont-width)
      margin-left: 0
    +res-sm-max
      padding: 40px 16px
  &__title
    font-weight: bold
    margin-bottom: 24px

  &__image-box
    +res-md-min
      width: 40%
      margin-top: -24px
      margin-bottom: -24px
    img
      object-fit: cover

.service-gift-attivazione
  &__title
    +typoRegularText
    font-weight: bold
    text-align: center
    margin-bottom: 40px

  &__cards
    +res-md-min
      display: flex
      justify-content: center
      > *
        flex: 1
        &:not(:last-child)
          margin-right: 30px
    +res-sm-max
      > *
        margin-left: auto
        margin-right: auto
