
.ordine-indirizzo
  +typoSmallText
  &__title
    +typoH5
    margin-bottom: 16px
  &__row
    margin-bottom: 6px
    display: flex
    align-items: flex-end
    > *
      &:not(:last-child)
        margin-right: 16px
        width: 180px
