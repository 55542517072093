
.account-menu-item
  +typoSmallText
  +res-sm-max
    display: none
  &__title
    display: flex
    align-items: center
    padding: 16px
    border-left: 4px solid transparent
    &:hover
      cursor: pointer
  &__label
    flex: 1
  &__icon
    margin-right: 12px
    svg
      +style-icon(20px, currentColor)
  &__arrow
    margin-left: 12px
    +style-icon(20px, currentColor)
  &__subitems
    display: none
    padding: 2px 0
  &__subitem
    display: block
    padding: 6px 0 6px 48px
    &:hover
      cursor: pointer
    &--active
      font-weight: bold
  &--active
    +res-sm-max
      display: block
    .account-menu-item
      &__title
        border-left-color: $accent
        background-color: $fill-accent-0
  &--open
    +res-md-min
      .account-menu-item
        &__arrow
          +rotate(180deg)
        &__subitems
          display: block
  &--mobile-open
    +res-sm-max
      .account-menu-item
        &__arrow
          +rotate(180deg)
        &__subitems
          display: block
