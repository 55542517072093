
.tag
  display: inline-flex
  justify-content: center
  align-items: center
  padding: 4px 12px 4px 12px
  border-radius: 24px
  +typoTinyText
  font-weight: 700
  color: white
  &__icon
    padding-right: 8px
    svg
      display: block
      width: 16px
      height: 16px
      +color-icon(currentColor)
  &--with-icon
    padding-left: 8px

// OLD
.tag--best-seller
  background-color: $fill-others-blue
.tag--occasioni
  background-color: $fill-others-dark-orange-50
.tag--novita
  background-color: $fill-others-blu-green
.tag--sustainable-living
  background-color: $fill-others-green

// NEW
.tag--blue
  background-color: $fill-others-blue
.tag--orange
  background-color: $fill-others-dark-orange-50
.tag--blue-green
  background-color: $fill-others-blu-green
.tag--green
  background-color: $fill-others-green

  // Preso da vecchia gestione ma non ha senso
  // TODO: capire dove va messo e con che stile - per ora non è usato
  &__brand
    width: 100%
    height: 100%
    background-color: red

  &--brand
    width: 35px
    height: 9px
