@import ../../../styles/sass/commons
  
.categorie-listing
  &__title
    +typoCaption
    color: $grey-80
    margin-bottom: 12px
    +res-md-max
      display: none
  &__content
    +typoSmallText
    
  &__item
    padding-left: 16px
    cursor: pointer
    span
      margin-left: 5px
      color: $grey-80
    +res-lg-min
      margin-bottom: 12px
    +res-md-max
      padding: 14px 20px 14px 46px
    &--selected
      font-weight: bold
      .formcheckbox
        &__label
          font-weight: bold
    &--selected-leaf
      +res-md-max
        background: $fill-accent-10

    &__child // Checkbox
      padding-left: 36px
      cursor: pointer
      +res-lg-min
        margin-bottom: 12px
      +res-md-max
        padding: 14px 20px 14px 70px
        .formcheckbox
          padding-left: 0
          &__styledinput
            display: none
      &__label
        span
          margin-left: 5px
          color: $grey-80
      &--selected
        font-weight: bold
  &__more
    +res-md-max
      display: none
    .button__label
      font-weight: 400
      text-decoration: underline