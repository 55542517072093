
.modal-message
  +typoSmallText
  p
    margin-bottom: 16px

.modal-loading
  padding: 40px 0

.modal-prossimi-arrivi
  &__details
    margin-top: 24px
  &__article
    margin-bottom: 16px
    &-title
      +typoCaptionSmall
      color: $grey-80
      margin-bottom: 8px
    &-code
      color: $black
  &__arrival
    +typoSmallText
    color: $success
    margin-bottom: 4px

.modal-actions
  margin-top: 40px
  display: flex
  gap: 24px
  justify-content: center
  align-items: center
  +res-sm-max
    flex-direction: column
