
.recupera-password
  padding-top: 24px
  padding-bottom: 100px
  +res-lg-min
    display: flex
    justify-content: space-between

  &__main
    +res-lg-min
      flex: 1

  &__sidebar
    margin-top: 40px
    +res-lg-min
      width: 300px
      padding-left: 32px
      margin-top: 0

  &__back
    margin-bottom: 40px

  &__title
    +typoH5
    margin-bottom: 40px

  &__options
    display: flex
    flex-direction: column
    margin: 20px 0

  &__option
    padding: 24px 40px
    +res-sm-max
      padding: 16px 24px
    &--selected
      background: $fill-neutrals-grey-0

  &__option-form
    margin-top: 16px
    +res-md-min
      padding-left: 32px
    +res-sm-min
      display: flex
      align-items: flex-start
      > *
        &:not(:last-child)
          margin-right: 16px
        &:first-child
          width: 380px
          max-width: calc(100% - 166px)
        &:last-child
          width: 150px
    +res-xs-max
      > *
        &:not(:last-child)
          margin-bottom: 16px

  &__option-label
    display: flex
    align-items: center
    > *
      &:not(:last-child)
        margin-right: 16px
    svg
      cursor: pointer
      +style-icon(24px, $black)
      &:hover
        +color-icon($accent)

  &__vantaggi
    &__bottom
      text-align: center
      .button
        margin-left: auto
        margin-right: auto

.contatti-assistenza
  margin-top: 100px
  +typoSmallText
  +res-md-max
    margin-top: 40px
  &__title
    +typoRegularText
    font-weight: bold
    margin-bottom: 8px
  &__item
    margin-bottom: 4px
