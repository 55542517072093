
.myaccount-form
  &__fieldsets
    +res-md-min
      display: flex
      flex-wrap: wrap
      margin: 0 -15px
      > *
        width: calc(50% - 30px)
        margin: 0 15px 24px
        &.formfield--layout
          &-block
            margin-right: calc(50% + 15px)
          &-full
            width: 100%
    +res-sm-max
      > *
        margin-bottom: 24px
  &__cta-box
    margin-top: 10px
    display: flex
    > *
      &:not(:last-child)
        margin-right: 24px
