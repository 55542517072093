
//Aggiungere overflow nel caso il titolo fosse più lungo di 3 righe
.articolo
  max-width: 150px
  min-height: 312px
  font-family: 'Open Sans'
  position: relative
  background-color: $white
  @media only screen and (min-width: 768px)
    max-width: 255px
    min-height: 328px
  &__image
    background-size: cover
    background-repeat: no-repeat
  &__content
    padding: 24px 16px 24px 16px
    display: grid
    grid-template-columns: repeat(1,auto)
    grid-gap: 12px
    &__data
      color: $grey-80
      font-size: 14px
      font-weight: bold
      line-height: 1.43
      letter-spacing: 0.6px
    &__titolo
      color: $black
      font-size: 16px
      line-height: 1.5
    &__link
      color: $accent
      line-height: 1.5
      font-size: 16px
      cursor: pointer
      text-decoration: underline
