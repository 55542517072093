.login__servicegift
  display: flex
  width: 100%
  height: 100vh
  background-color: rgba(0,0,0,0.65)
  justify-content: center
  align-items: center
  &__content
    +typoRegularText
    background-color: white
    padding: 20px
    border-radius: 4px
    display: flex
    flex-direction: column
    min-width: 260px
    .formfield
      margin-bottom: 8px
    &__error
      color: $error
    button
      margin-top: 15px
      margin-left: auto
      margin-right: auto
    label
      margin-top: 10px
    &__image
      max-width: 150px
      margin-left: auto
      margin-right: auto