
.catalogo-brand
  position: relative
  background-color: white
  padding: 16px
  +res-sm-max
    padding: 8px
  &__content
    position: relative
    display: flex
    flex-direction: column
    justify-content: center
    width: 100%
    height: 320px
    padding: 16px 40% 16px 16px
    border: solid 2px rgba(#fff, .25)
    z-index: 11
    +res-sm-max
      padding: 8px 20% 8px 16px
      height: 256px
  &__title
    +typoH5
    margin-bottom: 16px
    +res-sm-max
      +typoRegularText
      font-weight: bold
  &__description
    +typoRegularText
    margin-bottom: 40px
  &__img
    position: absolute
    bottom: 0
    right: 0
    max-width: 40%
    max-height: 80%
    z-index: 10
  &__cta
    align-self: flex-start
