
.salvati-per-dopo
  &__title
    +typoH5
    margin-bottom: 24px

  &__subtitle
    +typoRegularText
    font-weight: bold

  &__block-head
    margin-bottom: 8px
    +res-md-min
      display: flex
      justify-content: space-between
      flex-wrap: wrap
      > *
        &:not(:last-child)
          margin-right: 16px

  &__block
    margin-bottom: 40px
