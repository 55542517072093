
.abbigliamento__menu
  padding: 32px 0
  &__content
    margin-bottom: 40px
    margin: 0 auto
    display: flex
    justify-content: space-between
    > *
      &:not(:last-child)
        margin-right: 30px

    @media screen and ( max-width: 834px )
      display: grid
    &__categorie
      position: relative
      width: 100%
      display: grid
      grid-template-columns: repeat(3, calc(33.33% - 20px))
      grid-gap: 16px 30px
      @media screen and ( max-width: 834px )
        grid-template-columns: repeat(1,auto)

    &__trend
      min-width: 25%
      &__title
        +typoRegularText
        font-weight: bold
        margin-bottom: 8px
      &__wrapper
        display: flex
        flex-wrap: wrap
        > *
          margin-top: 16px
          &:not(:last-child)
            margin-right: 16px
  &__categoria
    max-width: 255px
    display: flex
    gap: 16px
    align-items: center
    font-size: 16px
    line-height: 1.5
    &__image-box
      width: 48px
      height: 48px
    &__title
      max-width: 168px
      word-break: break-word
      &:hover
        cursor: pointer
        text-decoration: underline
        color: $accent
        .abbigliamento__menu__categoria__num-prod
          color: $accent
    &__num-prod
      color: $grey-80
      margin-left: 4px
