
.homepage-service

  &__card-container
    +res-md-min
      display: flex
      +custom-row(30px)
      > *
        width: 33.33%
    +res-sm-max
      > *
        &:not(:last-child)
          margin-bottom: 40px

  &__card
    text-align: center

    &__icon
      display: block
      width: 80px
      height: 80px
      margin: 0 auto 41px
      +res-sm-max
        margin-bottom: 32px

    &__title
      +typoH5
      margin-bottom: 16px
      +res-sm-max
        +typoRegularText
        font-weight: bold

    &__description
      +typoRegularText
      color: $grey-80
      +res-sm-max
        +typoSmallText

  &__cta
    margin: 32px auto 0
