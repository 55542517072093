
.riepilogo-card-overlay
  position: fixed
  z-index: 9999
  top: 0
  left: 0
  right: 0
  bottom: 0
  width: 100% // Per eventuali override
  background: rgba($black, .3)
  +res-lg-min
    display: none

.riepilogo-card
  position: relative
  background-color: $white
  padding: 64px 16px 32px
  margin-top: 16px
  box-shadow: 0 8px 16px 0 rgba(24, 50, 115, 0.08)
  +res-lg-min
    max-width: 255px
  +res-md-max
    position: fixed
    bottom: 0
    left: 0
    right: 0
    margin-top: 0
    padding-top: 14px
    padding-bottom: 14px
    box-shadow: 0 -4px 8px 0 rgba(24, 50, 115, 0.08)
    z-index: 2000
    &:not(.riepilogo-card--expanded)
      .riepilogo-card
        &__rows-container, &__totale, &__buttons, &__promo-code, &__consegna-prevista
          display: none
    &--expanded
      z-index: 9999
  &__loading
    padding: 50px 0
    +res-md-max
      padding: 10px 0
  &__icon-container
    position: absolute
    top: -16px
    left: 16px
    height: 64px
    width: 64px
    display: flex
    justify-content: center
    align-items: center
    background-color: $fill-others-orange
    svg
      display: block
      width: 24px
      height: 24px
      +color-icon(#fff)
    +res-md-max
      right: 22px
      left: auto
  &__head
    +res-md-max
      padding-right: 90px
  &__title
    +typoH5
    color: $black
  &__subtitle
    margin-top: 8px
    +typoSmallText
    font-weight: 700
    color: $grey-80
  &__disclaimer
    margin-top: 8px
    +typoTinyText
    color: $grey-80
  &__rows-container

    +res-md-max
      padding-top: 16px

      // Per scrollbar
      margin-right: -8px
      padding-right: 8px
      +scrollbar-style
      max-height: calc(100vh - 80px) // 80px = somma delle altezze degli altri elementi
      overflow-y: auto
    &--child
      padding-bottom: 8px
      
      +res-lg-min
        padding-top: 20px

        // Per scrollbar
        margin-right: -8px
        padding-right: 8px
        +scrollbar-style
        max-height: calc(100vh - 375px) // 375px = somma delle altezze degli altri elementi
        overflow-y: auto

  &__row
    display: flex
    justify-content: space-between
    align-items: flex-start
    padding: 6px 0
    +typoTinyText
    +res-md-max
      +typoSmallText
    > *
      &:first-child
        width: calc(55% - 9px)
      &:last-child
        width: calc(40% - 9px)
        text-align: right
    &--totale
      +typoSmallText
      font-weight: bold
      +res-md-max
        +typoRegularText
        font-weight: bold
  &__totale, &__promo-code,  &__consegna-prevista
    border-top: solid 2px $grey-10
    padding: 16px 0
  &__consegna-prevista
    +typoTinyText
    +res-md-max
      +typoSmallText
    &__label
      color: $grey-80
    &__value
      display: inline-block
      margin-right: 5px
      margin-top: 4px
    &__merce_personalizzata
      display: inline-block
      svg
        display: inline-block
        width: 16px
        height: 16px
        +color-icon($black)
        vertical-align: top
      &:hover
        svg
          +color-icon($accent)
      &--active
        svg
          +color-icon($grey-80)

  &__accordion
    .accordion__container
      padding: 0
    .accordion__children
      width: calc(181.81% + 16px) // Hack - il contenuto dell'accordion sfora, se il parent è al 55%, questo risulta 100 * 100 / 55 = 180 circa + 9px ricalcolati a 16px
      padding-bottom: 0
    &-row
      display: flex
      justify-content: space-between
      +typoTinyText
      color: $grey-80
      padding: 6px 0
      > *
        &:not(:last-child)
          margin-right: 8px
      +res-md-max
        +typoSmallText
    &-label
      width: 60%
    &-code
      width: 30%
    &-qty
      width: 30%
    &-price
      width: 40%
      text-align: right
      &-sconto
        white-space: nowrap
        span
          color: $success
    &-sconto-disclaimer
      color: $success
    &-actions
      width: 40px
      display: flex
      > *
        width: 16px
        cursor: pointer
        &:not(:last-child)
          margin-right: 8px
      svg
        +style-icon(16px, $grey-80)
        &:hover
          +color-icon($black)
  &__price
    text-align: right
  &__button
    width: 100%
    max-width: none
    text-align: center
    &:not(:last-child)
      margin-bottom: 8px
    &--addcart
      +res-md-max
        &:not(:last-child)
          margin-bottom: 16px
  &__spedizione-info
    display: inline-block
    vertical-align: text-top
    svg
      display: block
      width: 16px
      height: 16px
      +color-icon($black)
    &:hover
      svg
        +color-icon($accent)
    &--active
      svg
        +color-icon($grey-80)

  &:not(.riepilogo-card--prodotto)
    &.riepilogo-card--expanded
      .riepilogo-card
        &__subtitle
          display: none
        &__disclaimer
          display: none

.toolbar
  ~ .page-body
    .riepilogo-card
      &__rows-container
        +res-md-max
          max-height: calc(100vh - #{$toolbar-height + 80px})
