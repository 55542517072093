
.formfile
  position: relative
  display: block
  cursor: pointer

  &__input
    position: absolute
    left: -9999px

  &__content
    display: inline-block
    padding: 0
    min-width: 200px
    +typoRegularText
    color: $accent
    > *
      vertical-align: middle
      &:not(:last-child)
        margin-right: 8px

  &__icon
    display: inline-block
    +color-icon(currentColor)

  &__remove
    margin-left: 16px

  &__input
    &:not(:disabled)
      cursor: pointer
      &+ .formfile__content
        .formfile__label
          text-decoration: underline
        .formfile__label, .formfile__filename
          cursor: pointer

    &:disabled + .formfile__content
      color: $grey-80

.formfield
  .formfile
    ~ .formfield
      &__icon, &__error-icon, &__success-icon
        display: none!important // Non mostro le icone per i file

  &--error
    .formfile__input:not(:disabled)
      &+ .formfile
        &__content
          color: $error
