
.cell
  font-family: 'Open sans',sans-serif
  padding: 20px 10px
  &__header
    display: block
    padding: 0px 10px
    display: flex
    color: $grey-80
    font-size: 12px
    line-height: 1.67
    font-weight: bold
    text-transform: uppercase
    justify-content: space-between
    gap: 45px
    @media screen and ( max-width: 545px )
      display: none
    &__info__product
      display: flex
      justify-content: flex-start
      align-items: center
      gap: 30px
    &__quantità
      display: flex
      gap: 65px
      justify-content: space-between
  &__container
    display: flex
    align-items: center
    justify-content: space-between
    background-color: $fill-neutrals-grey-0
    font-size: 14px
    border-bottom: solid 2px $grey-20
    position: relative
    @media screen and ( max-width: 545px )
      flex-wrap: wrap
      gap: 45px
  &__info__product
    display: flex
    justify-content: center
    align-items: center
    gap: 45px
    @media screen and ( max-width: 545px )
      align-items: flex-start
      justify-content: flex-start
      flex-wrap: wrap
      max-width: 300px
  a
    cursor: pointer
    text-decoration: underline
  &__quantità__wrapper
    display: flex
    align-items: center
    justify-content: flex-end
    gap: 16px
    @media screen and ( max-width: 545px )
      align-items: flex-start
      justify-content: flex-start
      flex-wrap: wrap
      gap: 40px
    &__button
      display: flex
      gap: 8px
      input
        border: solid 2px $grey-50
        max-width: 52px
        height: 28px
        text-align: center
        &::-webkit-inner-spin-button
          -webkit-appearance: none
          -moz-appearance: textfield
        &:focus
          outline: none
      button
        display: flex
        font-size: 14px
        font-weight: bold
        align-items: center
        justify-content: center
        height: 28px
        width: 28px
        color: white
        border-radius: 50%
        background-color: $accent

.field__name
  color: $grey-80
  font-size: 12px
  line-height: 1.67
  font-weight: bold
  text-transform: uppercase
  margin-bottom: 20px
  margin-top: 20px
  display: none
  @media screen and ( max-width: 545px )
    display: block

.first__element__placeholder
  color: $grey-80
  font-size: 12px
  line-height: 1.67
  font-weight: bold
  text-transform: uppercase
  top: -60px
  padding: 0px
  margin: 0px
  text-align: center
  display: flex
  justify-content: center
  align-items: flex-start
  position: absolute
  @media screen and ( max-width: 545px )
    display: none

.cell__error
  color: red
  font-weight: bold
  margin: 20px 0px 0px 0px
  display: flex
  align-items: center
  word-break: break-word
  width: 20%
  justify-content: center
.cell__element__wrapper
  position: relative
  margin: 0px
  padding: 0px
