
.notifica
  z-index: 102
  +typoRegularText
  padding: 16px 24px
  color: white
  background-color: black
  border-radius: 4px
  min-height: 128px
  max-width: 468px
  @media screen and ( max-width: 600px )
    display: flex
    justify-content: center
    align-items: center
  &__container
    display: flex
    justify-content: space-between
    align-items: center
  &__close
    cursor: pointer
    margin-left: auto
    padding-left: 16px
    svg
      display: block
      width: 24px
      height: 24px
      +color-icon(currentColor)

.notifica__error
  background-color: $error

.notifica__success
  background-color: $accent
