
.scrolltop
  position: absolute
  right: 0
  top: -150px
  width: 100px
  display: block
  +border-top-left-radius(35px)
  +border-bottom-left-radius(35px)
  background: $fill-neutrals-grey-0
  padding: 8px 16px 12px 30px
  text-align: center
  cursor: pointer
  +res-lg-max
    display: none
  &__icon
    svg
      +style-icon(32px, $black)
      margin: 0 auto
  &__label
    display: block
    +typoTinyText
