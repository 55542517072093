
.sticky
  &--active
    +res-lg-min
      position: fixed
      // top gestito in layout.sass
      left: 0px
      width: 100%
      z-index: 99
      background-color: white
      box-shadow: 0px 8px 16px 0px rgba(24, 50, 115, 0.08)
      transition: 0.2s cubic-bezier(.17,.67,.83,.67)
