
//Aggiungere overflow nel caso il titolo fosse più lungo di 3 righe
.news
  max-width: 320px
  margin: 0 auto
  background-color: $white
  &__imgContainer
    position: relative
    overflow: hidden
    height: 136px
    &__label
      display: inline-block
      background-color: $grey-20
      position: absolute
      bottom: 0
      left: 0
      padding: 4px 24px 4px 16px
      +typoTinyText
      font-weight: bold
      +border-top-right-radius(10px)
      +border-bottom-right-radius(10px)
  &__image
    cursor: pointer
    position: absolute
    top: 50%
    left: 0
    width: 100%
    +translateY(-50%)
  &__content
    padding: 24px 16px
    &__data
      +typoCaption
      color: $grey-80
      margin-bottom: 12px
    &__titolo
      cursor: pointer
      +typoRegularText
      color: $black
      +multiline-ellipsis(3, 24px)
      margin-bottom: 16px
    &__link
      color: $accent
      line-height: 1.5
      font-size: 16px
      cursor: pointer
      text-decoration: underline
