
.header
  position: fixed
  // top + transition gestiti da layout.sass
  left: 0
  right: 0
  z-index: 9999
  background: $white
  box-shadow: 0px 8px 16px 0px rgba(24, 50, 115, 0.08)
  &--semplificato
    .header
      &__link
        +typoH5
        padding: 24px 0
    +res-md-min
      border-bottom: solid 1px $grey-20
      height: $header-semplificato-height-desktop
      .header
        &__content
          height: 104px
          position: relative
          display: flex
          align-items: center
          justify-content: center
        &__logo
          position: absolute
          left: 0
          top: 50%
          +translateY(-50%)
          height: 48px
          &--sipec
            padding: 2px
            display: block
            svg
              display: block
              height: 44px
              width: 178px
    +res-sm-max
      padding: 19px 16px 0
      height: $header-semplificato-height-mobile
      .header
        &__logo
          height: 27px
          margin-bottom: 2px
          &--sipec
            padding: 2px
            display: block
            svg
              display: block
              height: 27px
              width: 102px
        &__link
          display: block
          text-align: center

  &--full
    // height gestita da layout.sass

    .header
      &__main
        position: relative
        z-index: 2 // per tendina searchbar che deve essere sopra le altre cose
        max-width: $wcont-width
        margin: 0 auto
        padding: 0 16px
        display: flex
        justify-content: space-between
        align-items: center
        height: $header-full-main-height
        background-color: white

        +res-md-min
          > *
            &:not(:last-child)
              margin-right: 12px
            &:not(:first-child)
              margin-left: 12px

        +res-sm-max
          height: auto
          flex-wrap: wrap
          padding: 20px 16px

        &__logo
          max-width: 182px
          max-height: 48px
          +res-md-max
            max-width: 102px
            height: 27px
          &--sipec
            display: block
            svg
              display: block
              width: 182px
              height: 48px
              +res-md-max
                width: 102px
                height: 27px

        &__input__container
          width: 60%
          position: relative
          z-index: 0
          +res-sm-max
            margin-top: 12px
            order: 5
            width: 100%

        &__search__bar
          width: 100%
          z-index: 0
          height: 40px
          border-radius: 24px
          border: none
          padding: 8px 16px
          background-color: $grey-10
          &:focus
            outline: none
          +res-sm-max
            &::placeholder
              text-overflow: ellipsis
              overflow: hidden
              opacity: 0.4
              text-white: nowrap
          &__icon
            cursor: pointer
            position: absolute
            z-index: 99
            top: 8px
            right: 15px
        &__icon__wrapper
          display: flex
          position: relative
          +res-md-max
            align-items: center
          > *
            &:not(:last-child)
              margin-right: 11px

        &__icon
          +style-icon(24px, $black)

        &__icon-box
          cursor: pointer
          &--wishlist
            +res-md-max
              display: none
          &--menu-mobile
            +res-lg-min
              display: none

        &__cart-wrapper
          position: relative
          display: inline-block

        &__cart-overlay
          position: absolute
          right: -8px
          top: -8px
          width: 16px
          height: 16px
          border: 1px solid $white
          &--notification
            +border-radius(50%)
            background: $black
            +typoTinyText
            font-weight: bold
            color: $white
            text-align: center

      &__top
        background-color: $grey-10
        height: 40px
        // +transition(height .1s)
        +res-md-max
          display: none
        &__content
          max-width: $wcont-width
          height: 40px
          margin: 0 auto
          padding: 0 16px
          display: flex
          align-items: center
          justify-content: space-between
        &__lingua
          font-size: 14px
        &__link
          padding: 2px 24px
          +typoSmallText
          color: $black
          cursor: pointer
          &:not(:last-child)
            border-right: 1px solid $grey-50
    /*
     &--sticky-down
     +res-lg-min
     height: 128px
     .header__top
     height: 0
     */

.dropdown__language
  position: relative
  padding: 10px 32px 10px 0
  &--header
    display: none
    +res-md-max
      display: block
  &__trigger
    cursor: pointer
    display: flex
    align-items: center
    &__label
      +typoSmallText
      text-transform: uppercase
      margin-left: 14px
    &__flag
      svg
        display: block
        width: 18px
        height: 14px
  &__arrow
    position: absolute
    top: calc(50% - 10px)
    right: 0
    width: 20px
    height: 20px
    fill: $black
  &__options
    position: absolute
    top: calc(100% + 5px)
    left: -10px
    right: 0
    z-index: 99
    +border-radius(4px)
    background-color: $grey-10
    padding: 5px 0
  &__option
    display: flex
    align-items: center
    padding: 5px 10px
    +typoSmallText
    &--selected
      background: $grey-50
    &:not(.dropdown__language__option--selected)
      cursor: pointer
      &:hover
        background: $grey-20

    &__flag
      margin-right: 14px
      svg
        display: block
        width: 18px
        height: 14px

  &--open
    .dropdown__language
      &__arrow
        +rotate(180deg)

// Va qui per un problema di z-index
.search-results-overlay
  position: fixed
  top: 0
  left: 0
  right: 0
  bottom: 0
  background-color: rgba($black, .4)
  z-index: 9997
