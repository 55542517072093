

.checkout
    .codice-sconto
        margin-bottom: 10px
        &__error
            color: $error
        &__warning
            color: $warning
    
    &__conferma-button
        margin-top: 24px