.u-color--success
  color: $success!important
.u-color--error
  color: $error!important

.u-fill-accent
  fill: $accent

.u-hidden
  display: none!important
.u-hidden-desktop
  +res-md-min
    display: none!important
.u-hidden-mobile
  +res-sm-max
    display: none!important

.u-align-center
  text-align: center
.u-align-center-desktop
  +res-md-min
    text-align: center
.u-align-center-mobile
  +res-sm-max
    text-align: center
  
.u-align-left
  text-align: left
.u-align-left-desktop
  +res-md-min
    text-align: left
.u-align-left-mobile
  +res-sm-max
    text-align: left

.u-align-right
  text-align: right
.u-align-right-desktop
  +res-md-min
    text-align: right
.u-align-right-mobile
  +res-sm-max
    text-align: right

.u-scrollable
  overflow-y: auto
  +scrollbar-style

.u-mb-1-mobile
  +res-sm-max
    margin-bottom: 4px

.u-mb-2-mobile
  +res-sm-max
    margin-bottom: 8px

.u-mb-3-mobile
  +res-sm-max
    margin-bottom: 16px

.u-mb-4-mobile
  +res-sm-max
    margin-bottom: 32px